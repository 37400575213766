import React, { useState } from "react";
import "./Table.css";
import {
  TABLE_TEXT_COLOR,
  LIGHT_BLUE,
  DARK_ORANGE,
  GREEN,
} from "./../constants/colors.jsx";

type TableHead = {
  name: string;
  highlightPosNeg?: boolean;
  isPercentage?: boolean;
  bar?: string;
  image?: boolean;
  largeImage?: boolean;
  hide?: boolean;
  linkPrefix?: string;
  formatDate?: boolean;
  appendText?: string;
  wrapText?: boolean;
  format?: string;
  numberOfDecimals?: number;
  convert?: boolean;
};

interface ITableProps {
  body: Array<Array<number | string>>;
  head: Array<TableHead>;
  title: String | undefined;
}

const Table = ({ body, head, title }: ITableProps) => {
  const [isColumnWithBigNumber, setIsColumnWithBigNumber] = useState(false);

  const calculateColumnMaxValue = (columnIndex: number): number => {
    let maxValue = 0;
    body[columnIndex].map((cellValue: string | number) => {
      const cellValueAsNumber = Number(cellValue);
      if (cellValue > maxValue) {
        maxValue = cellValueAsNumber;
      }
      return maxValue;
    });
    return maxValue;
  };

  const wrapTextCssClass = "table-column-text-wrap";

  const displayCellValue = (
    value: any,
    currentColumnIndex: number,
    currentCellIndex: number,
    head: TableHead
  ) => {
    if (!value) {
      return (
        <td key={`${value}-${currentColumnIndex}-${currentCellIndex}`}>
          <span className="table-data-value"></span>
        </td>
      );
    }

    const isValueNumber = !isNaN(Number(value ? value : null));
    let buildedValue = value;
    let buildedValueStyle;
    const {
      image,
      linkPrefix,
      formatDate,
      appendText,
      wrapText,
      format,
      numberOfDecimals,
    } = head;

    const formatNumber = (value: any) =>
      Number(value).toLocaleString("it-IT", {
        minimumFractionDigits: numberOfDecimals ? numberOfDecimals : 0,
      });

    if (isValueNumber) {
      //const isNumberDecimal = Number(value) % 1 !== 0 ? true : false;
      const valueAsNumber = Number(Number(value).toFixed(2));

      const { highlightPosNeg, isPercentage, convert, bar } = head;
      if (highlightPosNeg) {
        const numberSign = Math.sign(valueAsNumber);

        switch (numberSign) {
          case -1:
          case -0:
            buildedValue = valueAsNumber;
            buildedValueStyle = { color: DARK_ORANGE };
            break;
          case 1:
            buildedValue = "+" + valueAsNumber;
            buildedValueStyle = { color: LIGHT_BLUE };
            break;
          default:
            buildedValue = valueAsNumber;
            break;
        }
      } else {
        buildedValue = valueAsNumber;
      }

      // Add dots and/or commas to number
      if (format !== "year") {
        buildedValue = formatNumber(buildedValue);
      }

      if (isPercentage) {
        if (!convert) {
          buildedValue = !highlightPosNeg ? value + "%" : buildedValue + "%";
        }
        else {
          buildedValue = !highlightPosNeg ? `${value * 100}%` : `${buildedValue * 100}%`;
        }
      }

      if (appendText) {
        buildedValue += " " + appendText;
      }

      if (bar) {
        // Different numeric div width dependent on number size
        if (Number(value) / 1000 > 1.4 && !isColumnWithBigNumber) {
          setIsColumnWithBigNumber(true);
        }
        return (
          <td key={currentCellIndex} className="cell-with-bar">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className={
                  "bar-numeric text-right " +
                  (isColumnWithBigNumber ? "bar-numeric-big-number" : "")
                }
                style={buildedValueStyle}
              >
                {buildedValue ? buildedValue : value}
              </div>
              <div className="bar-container">
                <div
                  className="bar-value"
                  style={{
                    width:
                      (valueAsNumber /
                        calculateColumnMaxValue(currentColumnIndex)) *
                      100,
                    backgroundColor: bar,
                  }}
                ></div>
              </div>
            </div>
          </td>
        );
      } else {
        return (
          <td
            key={currentCellIndex}
            className={`cell-with-number-value ${
              isValueNumber ? "text-align-right" : ""
            }`}
            //style={buildedValueStyle}
          >
            <span
              className={"table-data-value " + wrapText ? wrapTextCssClass : ""}
            >
              {buildedValue}
            </span>
          </td>
        );
      }
    } else if (image) {
      if (linkPrefix) {
        return (
          <td
            key={currentCellIndex}
            className={wrapText ? wrapTextCssClass : ""}
          >
            <img src={String(linkPrefix) + String(value)} alt="tbl" />
          </td>
        );
      } else {
        return (
          <td
            key={currentCellIndex}
            className={wrapText ? wrapTextCssClass : ""}
          >
            <img src={String(value)} alt="tbl" />
          </td>
        );
      }
    } else if (linkPrefix) {
      return (
        <td key={currentCellIndex} className={wrapText ? wrapTextCssClass : ""}>
          <a
            href={String(linkPrefix) + String(value)}
            style={{
              textDecoration: "none",
              color: GREEN,
              fontWeight: "bold",
              fontSize: "13px",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            View
          </a>
        </td>
      );
    }
    // If value is date, format it to format: dd.MM.yyyy
    let valueAsDate = new Date(value);
    // Is value date
    if (!isNaN(valueAsDate.getTime()) && formatDate) {
      return (
        <td
          key={currentCellIndex}
          style={{ fontSize: "12px" }}
          className={wrapText ? wrapTextCssClass : ""}
        >
          <span className="table-data-value">{`${
            valueAsDate.getDate() < 10
              ? "0" + valueAsDate.getDate()
              : valueAsDate.getDate()
          }.${
            valueAsDate.getMonth() + 1 < 10
              ? "0" + (valueAsDate.getMonth() + 1)
              : valueAsDate.getMonth() + 1
          }.${valueAsDate.getFullYear()}`}</span>
        </td>
      );
    }
    return (
      <td
        key={currentCellIndex}
        style={{ fontSize: "12px" }}
        className={wrapText ? wrapTextCssClass : ""}
      >
        <span className="table-data-value">{value}</span>
      </td>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid rgba(35, 36, 77, 0.1)",
        }}
      >
        {title && <h3 className="chart-title table">{title}</h3>}
        {/* <LoopIcon style={{ width: "initial", marginRight: "17px" }} /> */}
      </div>
      <div className="table-container">
        <table style={{ color: TABLE_TEXT_COLOR }}>
          <thead>
            <tr>
              {head.map((column: TableHead, i: number) => {
                const isCurrentColumnValuesNumeric = !isNaN(Number(body[i][0]));
                return (
                  <th
                    key={i}
                    style={
                      isCurrentColumnValuesNumeric
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {!column.hide ? column.name : ""}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {body.map((column: Array<string | number>, columnIndex: number) => {
              const currentHead = head[columnIndex];
              return (
                <tr key={columnIndex}>
                  {column.map((value: string | number, cellIndex: number) => {
                    return displayCellValue(
                      value,
                      columnIndex,
                      cellIndex,
                      currentHead
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
