import * as types from "./types";
import * as interfaces from "../interfaces";

export const printToPdf = (
  params: interfaces.IPDFParams
): interfaces.SystemActionTypes => {
  return {
    type: types.system.PRINT_TO_PDF,
    payload: params,
  };
};

export const printToPdfSucceeded = (): interfaces.SystemActionTypes => {
  return {
    type: types.system.PRINT_TO_PDF_SUCCEEDED,
    payload: null,
  };
};

export const printToPdfFailed = (
  params: interfaces.IError
): interfaces.SystemActionTypes => {
  return {
    type: types.system.PRINT_TO_PDF_FAILED,
    payload: params,
  };
};

export const setDate = (
  params: interfaces.IDateParams
): interfaces.SystemActionTypes => {
  return {
    type: types.system.SET_DATE,
    payload: params,
  };
};

export const setDateRange = (
  params: interfaces.IDateParams
): interfaces.SystemActionTypes => {
  return {
    type: types.system.SET_DATE_RANGE,
    payload: params,
  };
};

