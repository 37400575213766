import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import * as d3 from "d3";

/**
 * Wheel chart component is used to show one graph.
 * 
 *  @param {Object} data - Data object based on the number of array elements we will know how many sections to divide the wheel.
 *  @param {number} data.value - Percentage value (eg . 13).
 *  @param {string} data.bgColor - RGB value (eg. #ffffff).
 *  @param {string} data.fgColor? - RGB value (eg. #ffffff).
 *  @param {Object} options? .
 *  @param {string} options.title? - The title of the component.
 *  @param {number} options.fgThreshold - Percentage below which data.value is not displayed.
 *  @param {boolean} options.legend - Show/hide legend.
 *  @param {number} options.holeDiameter - 0 - No hole, 1 - hole is 100% so we have just an outer circle, everything in between - the percentage of the diameter.
 *  @param {boolean} options.showValuesInside - Show/hide values inside the pie slices.
 *  @param {boolean} options.toolTip - Show/hide values inside the tooltips. The tooltips need to follow the mouse movement.
 *  @param {number} options.width - Width of the parent container.
 *  @param {number} options.height - Height of the parent container.
 *  @param {string} options.image? - Url or path to the picture.
 *   
 *  @return Potential title, svg (chart ), potential legend.
 */
const WheelChart = props => {
  const { data, options, height, width, id, vertical } = props;

  const cache = useRef(data);

  const [svgDimensions, setSvgDimensions] = useState({ width: width, height: height });
  const { width: svgWidth, height: svgHeight } = svgDimensions;
  /**
   * Check within prop.options.holeDiameter(prop.options.holeDiameter can be a number from 0 till 1)
   * whether graph should be a pie or wheel chart.
   * 
   * @return Multiply prop.options.holeDiameter and length of inner Radius.
   */
  const checkPieOrWheelChart = useCallback(() => {
    return options.holeDiameter * svgHeight / 4;
  }, [options.holeDiameter, svgHeight]);
  //let dataForPicture = [...dataPicture];
  const ref = useRef(null);
  const container = useRef(null);
  const pie = useRef(null);
  const legend = useRef(null);
  let total = 0;
  data.forEach(element => {
    total = total + element.value;
  });

  const checkDimensions = useCallback((pie, container, legend, value = true) => {
    let scaleChanged = value;
    if (pie && container && legend != null) {
      let components = pie.clientWidth + legend.clientWidth;
      if (components - (components * 0.06) > container.clientWidth) {
        scaleChanged = false;
      }
    }
    return scaleChanged;
  }, []);

  const check = checkDimensions(pie.current, container.current, legend.current, vertical);

  /*
  const adjustColor = (color, percent) => {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    var RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
  };
  */

  /** 
   * Creating a pie chart,define inner and outer radius, format numbers inside and setting data inside of chart.
   */
  const colors = useMemo(() => props.data.map((item) => item.bgColor), [props.data]);
  //const colors = d3.scaleOrdinal(d3.schemeCategory10);
  // const colorsExample = props.data.map((item) => item.bgColor);
  //const colors = d3.scaleOrdinal().domain(props.data.map((item) => item.value)).range(props.data.map((item) => item.bgColor));
  //const format = d3.format(".2f");

  const formatTooltipValue = (value) => Number(value).toLocaleString('it-IT');

  /**
   * Using useEffect to re-render every time props.data and props.options change. Also within useEffect 
   * we create pie/wheel chart add: all attributes, title, legend, hovering, picture, make animation,
   * make chart responsive, add/remove text in each arc.
   * 
   * @param {Object} - Arrow function that will trigger every time props.data and props.options change.
   * @param {Array[]} props.data i props.options.
   */
  useEffect(
    () => {
      setSvgDimensions({ width: width, height: height });
      const createPie = d3.pie()
        .value(d => d.value)
        .sort(null);

      // Here you will need to put filters. 
      const createArc = d3.arc()
        .innerRadius(checkPieOrWheelChart())
        .outerRadius(svgHeight / 2);

      /**
       * Connecting data to the pie/wheel chart.
       */
      const dataConst = createPie(data);
      const prevData = createPie(cache.current);
      const group = d3.select(ref.current);
      const groupWithData = group.selectAll("g.arc").data(dataConst);
      groupWithData.exit().remove();
      const groupWithUpdate = groupWithData
        .enter()
        .append("g")
        .attr("class", "arc");

      const arcTween = (d, i) => {
        const interpolator = d3.interpolate(prevData[i], d);
        return t => createArc(interpolator(t));
      };

      /**
       * Add path by which the arc-s would be drawn and animation.
       */
      const path = groupWithUpdate
        .append("path")
        .merge(groupWithData.select("path.arc"));
      path
        .attr("class", "arc")
        .transition()
        .duration(300)
        .attrTween("d", arcTween)
        .attr("fill", (d, i) => colors[i])
        .attr("stroke", (d, i) => colors[i])
        .attr("stroke-width", 2);

      //TODO remove it to some other component later
      var root = document.getElementsByTagName('html')[0];
      root.setAttribute('class', 'light');
      var isTooltipDrawn = d3.selectAll('#pieTooltip')["_groups"][0]
        .length;
      /**
       * Add tooltip and making it hidden by default.
       */
      if (isTooltipDrawn === 0) {
        d3.select("body")
          .append("div")
          .attr("id", "pieTooltip")
          .style("position", "absolute")
          .style("z-index", "10")
          .style("visibility", "hidden");
      }

      /**
       * Adding,styling and positioning text inside arcs.
       */
      const text = groupWithUpdate
        .append("text")
        .merge(groupWithData.select("text"))
        .style("fill", "transparent")
        .style("font-size", `${svgHeight / 25}px`)
        .transition()
        .attr("transform", d => `translate(${createArc.centroid(d)})`);
      // .tween("text", (d, i, nodes) => {
      //   const interpolator = d3.interpolate(prevData[i], d);

      //   return t => d3.select(nodes[i]).text(format(interpolator(t).value));
      // });
      text.each(function (d, i) {
        if (options.showValuesInside) {
          text
            .attr("text-anchor", "middle")
            .attr("baseline", "middle")
            .transition()
            .attr("transform", d => `translate(${createArc.centroid(d)})`)
            // .tween("text", (d, i, nodes) => {
            //   const interpolator = d3.interpolate(prevData[i], d);

            //   return t => d3.select(nodes[i]).text(format(interpolator(t).value));
            // })
            .style("fill", d => d.fgColor)
            .style("font-weight", "500")
            .style("font-size", `${svgHeight / 15}px`)
            .style('fill-opacity', 1)
            .text(d => (d.value / total * 100).toFixed(2) < options.fgThreshold ? null : Math.round(d.value / total * 100) + '%');
        }
        else if (!options.showValuesInside) {
          text.style('fill-opacity', 0).style('font-size', "0px");
        }
      });

      /**
       * Changing color when hovering, if props.options.showValuesInside is true then the hover will not work 
       * and the color of arcs will not change. If props.options.showValuesInside is false on mouseover function 
       * change opacity of arc and make tooltip visible, when moves away from arc change to previous look.
       */
      path.each(function () {
        if (options.toolTip === true) {
          path.on('mouseover', function (i, d) {
            d3.select(this).transition('changeSliceFill')
              .duration('50')
              .attr('opacity', '.75');
            if ((d.value / total * 100).toFixed(2) < options.fgThreshold) {
              return d3.select('#pieTooltip').style("visibility", "visible").text(d.data.name + ": " + (d.value / total * 100).toFixed(2) + '%');
            }
          })
            .on("mousemove", function (event) {
              return d3.select('#pieTooltip').style("top", (event.pageY - 15) + "px").style("left", (event.pageX - 17) + "px");
            });
          path.on('mouseout', function () {
            d3.select(this).transition('changeSliceFill')
              .duration('50')
              .attr('opacity', '1');
            return d3.select('#pieTooltip').style("visibility", "hidden");
          });
        }
        else if (options.showValuesInside === true && options.toolTip === false) {
          path.on('mouseover', function (i, d) {
            d3.select(this).transition('changeSliceFill')
              .duration('50')
              .attr('opacity', '.75');
            if ((d.value / total * 100).toFixed(2) < options.fgThreshold) {
              return d3.select('#pieTooltip').style("visibility", "visible").text(formatTooltipValue((d.value / total * 100).toFixed(2)) + '%');
            }
          }).on("mousemove", function (event, d) {
            if ((d.value / total * 100).toFixed(2) < options.fgThreshold) {
              return d3.select('#pieTooltip').style("top", (event.pageY - 15) + "px").style("left", (event.pageX - 17) + "px");
            }
          }).on('mouseout', function (i, d) {
            d3.select(this).transition('changeSliceFill')
              .duration('50')
              .attr('opacity', '1');
            return d3.select('#pieTooltip').style("visibility", "hidden");
          });
        } else {
          path.on('mouseover', function () {
            d3.select(this).transition('changeSliceFill')
              .duration('50')
              .attr('opacity', '.75');
          }).on('mouseout', function () {
            d3.select(this).transition('changeSliceFill')
              .duration('50')
              .attr('opacity', '1');
          });
        }
      });

      /**
       * Adding picture in middle of chart.
       */
      if (svgHeight !== 0) {
        if ((options.image !== null && options.holeDiameter !== 0) ||
          (options.image !== undefined && options.holeDiameter !== 0) ||
          (options.image !== "" && options.holeDiameter !== 0)
        ) {
          d3.selectAll('g.arc').append('image')
            .attr("dy", "0em")
            .attr("x", `${-svgHeight / 8}`)
            .attr("y", `${-svgHeight / 8}`)
            // If pictures is send or not.
            .attr('width', svgHeight / 4)
            .attr('height', svgHeight / 4)
            .attr("xlink:href", options.image)
            .attr("class", 'pieImage');
        }
      }

      /**
       * Making graph responsive and positioning it.
       */
      d3.select(`svg.${id}`)
        .attr("width", "100%")
        .attr("height", "100%")
        .style('padding', '2px')
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `-1 -1 ${svgHeight + 4} ${svgWidth / 4 + 4}`);
      d3.select(`g.${id}-group`).attr("transform", `translate(${svgHeight / 2}, ${svgHeight / 2})`);

      /**
       * Adding legend.
       */
      d3.select(`div.${id}-containerLegend .legend`)
        .style("display", "flex")
        .selectAll("*")
        .remove();
      let legend = d3.select(`div.${id}-containerLegend .legend`);
      // .append('div')
      // .attr('class', 'legend')
      // .style("display", "flex")
      // .style("flex-direction", "row")
      // .style("flex-wrap", "wrap")
      // .style("justify-content", "center")
      // .style("height", check ? `${svgDimensions.height / 4}px` : null)
      // .style("width", !check ? `${svgDimensions.height / 4}px` : null)
      // .style("writing-mode", !check ? null : "vertical-lr")
      legend.selectAll('div').data(data)
        .enter()
        .append('div')
        .each(function (d, i) {
          if (options.legend) {
            var g = d3.select(this);

            g.style("display", "flex")
              .style("align-items", "center")
              .style("justify-content", `${check ? 'flex-start' : 'center'}`)
              .style("width", `${!check ? svgWidth / 8 : svgWidth / 5}px`)
              .style("writing-mode", "horizontal-tb");

            g.append("div")
              .style("min-width", `${svgWidth / 120}px`)
              .style("height", `${svgWidth / 120}px`)
              .style("margin-right", "6.52px")
              .style("border-radius", "50%")
              .style("background-color", data[i].bgColor);

            g.append("p")
              .attr("height", 30)
              .attr("width", 45)
              .style("text-align", "left")
              .style("margin", `${svgHeight / 100}px 0px 6.52px 0px`)
              .style("font-size", `10px`)
              .text(data[i].name);
          } else {
            d3.select(`svg.${id}-containerLegend`).remove();
          }
        });
      cache.current = data;
    }, [data, check, colors, height, id, options.fgThreshold, options.holeDiameter, options.image, options.legend, options.showValuesInside, options.toolTip, svgHeight, svgWidth, width, total, checkPieOrWheelChart]
  );

  return (
    <div className="grid-item">
      {/* {typeof (props.options.title) === "string"
        ? (<h1>{props.options.title}</h1>)
        : ""} */}
      <div className="graph-container" ref={ref => {
        if (ref != null) {
          container.current = ref;
        }
      }}
        style={{ display: 'flex', flexDirection: check ? 'row' : 'column', alignItems: !check ? 'center' : null }}
      >
        <div className="donut-container" style={{ height: svgHeight, width: svgWidth / 4 }} ref={ref => {
          if (ref != null) {
            pie.current = ref;
          }
        }}>
          {data.length > 0 && <svg className={id}>
            <g className={`${id}-group`} ref={ref} />
          </svg>}
        </div>
        <div className="legend-wrapper" style={{ height: svgHeight, width: check ? svgWidth / 5 : '100%', marginLeft: check ? `5%` : null, alignSelf: 'center' }}>
          <div className={`${id}-containerLegend`} style={{ width: check ? svgWidth / 5 : '100%', height: check ? svgHeight : '100%' }}>
            {data.length > 0 && <div className="legend"
              style={{
                height: check ? `${svgHeight}px` : null,
                width: "100%",
                writingMode: !check ? null : "vertical-lr",
                justifyContent: !check ? null : 'center',
              }}
              ref={ref => {
                if (ref != null) {
                  legend.current = ref;
                }
              }}>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WheelChart;
