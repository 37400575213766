import React, { useEffect, useState, useCallback } from 'react';
import Table from './Table';


const TableBlock = (props) => {
    const { title, page, data, date, index, dataToPlot, dataType, sortBy } = props;

    const [formattedTableBody, setFormattedTableBody] = useState([]);

    const localizeColumns = useCallback(() => {
        let localizedColumns = {};
        props.headings.forEach(item => {
            if (item.key) {
                localizedColumns[item.key] = item.name;
            }
        });
        return localizedColumns;
    }, [props.headings]);

    useEffect(() => {
        let columnsWithKeys = [];
        let columnsData = [];

        const localizedColumns = localizeColumns();

        const formatTableDataIntoColumnsWithKeys = ((item) => {
            for (let key in localizedColumns) {
                if (localizedColumns[key]) {
                    if (!columnsWithKeys[key]) {
                        columnsWithKeys[key] = [];
                    }
                    // eslint-disable-next-line no-unused-vars
                    let element = undefined;

                    // Nested keys are in format key1:key2:key3:etc.
                    const nestedColumnKeys = key.split(":");

                    if (nestedColumnKeys.length > 1) {
                        let currentElement = undefined;
                        for (const columnKey in nestedColumnKeys) {
                            if (!currentElement) {
                                currentElement = item[nestedColumnKeys[columnKey]];
                            } else {
                                currentElement = currentElement[nestedColumnKeys[columnKey]];
                            }
                        }
                        element = currentElement;
                    } else {
                        element = item[key];
                    }

                    columnsWithKeys[key].push(element);
                }
            };
        });

        if (Object.keys(data).length !== 0 && data[page]) {
            if (!data[page][index - 1].statusCode) {
                let tableData =  data[page][index - 1];
                if (sortBy) {
                    tableData = tableData.sort((a,b) => a[sortBy] > b[sortBy] ? -1 : 1);
                }
                tableData.forEach((item, index) => {
                    if (!item.statusCode) {
                        if (dataToPlot) {
                            // eslint-disable-next-line no-unused-vars
                            let element = undefined;

                            // Nested keys are in format key1:key2:key3:etc.
                            const nestedColumnKeys = dataToPlot.element.split(":");

                            if (nestedColumnKeys.length > 1) {
                                let currentElement = undefined;
                                for (const columnKey in nestedColumnKeys) {
                                    if (!currentElement) {
                                        currentElement = item[nestedColumnKeys[columnKey]];
                                    } else {
                                        currentElement = currentElement[nestedColumnKeys[columnKey]];
                                    }
                                }
                                element = currentElement;
                            } else {
                                element = item[dataToPlot.element];
                            }
                            if (dataToPlot.value.label === element) {
                                formatTableDataIntoColumnsWithKeys(item);
                            }
                        }
                        else {
                            formatTableDataIntoColumnsWithKeys(item);
                        }
                    }
                }
                );
            }
        };

        for (let key in columnsWithKeys) {
            columnsData.push(columnsWithKeys[key]);
        }

        if (dataType !== "ads" && dataType !== "c1" && dataType !== "c4") {// adding c4
            // Add year/month column values as first array
            columnsData.unshift(Array(columnsData[0] ? columnsData[0].length : 0).fill(date));
        }

        

        if (dataType === "cm" || dataType === "ads") {
            // Add Serial Number values as a column
            if (columnsData[0]) {
                let serialNumbersArray = [];
                for (let i = 0; i < columnsData[0].length; i++) {
                    serialNumbersArray.push(i + 1);
                }
                columnsData.unshift(serialNumbersArray);
            }
        }

        if (dataType === "c1") {
            if (data[page] && !data[page][index - 1].statusCode) {
                if (data[page][index - 1] && columnsData[0]) {
                    // columnsData.unshift('aaa');
                    let channelArray = [];
                    for (let i = 0; i < columnsData[0].length; i++) {
                        channelArray.push(data[page][index - 1][i].competitors_id.name);
                    }
                    columnsData.unshift(channelArray);
                }
            }
        }

        //testing
        if (dataType === "c4") {

       

            if (data[page] && !data[page][index - 1].statusCode) {
                if (data[page][index - 1] && columnsData[0]) {
                    // columnsData.unshift('aaa');
                    let channelArray = [];
                    for (let i = 0; i < columnsData[0].length; i++) {

                        
                        /*channelArray.push(
                            `${data[page][index][i].competitors_id.name}${
                              data.channels_id ?  data.channels_id.name : "_null"
                            }`
                          ); 
                          //add channels intead competitors here **** to do ******/
                            if (data[page][index-1][i].channels_id) {
                             //   channelArray.push("test");//data[page][index][i].channels_id.name);
                            }else
                            {
                                channelArray.push(data[page][index - 1][i].competitors_id.name);
                            }                         
                        
                    }
                    columnsData.unshift(channelArray);
                }
            }
        }//end testing

        setFormattedTableBody(columnsData);
    }, [data, page, dataToPlot, dataType, date, index, localizeColumns, sortBy]);

    // eslint-disable-next-line no-unused-vars
    let hasImageColumn = false;

    props.headings.forEach((element) => {
        if (element.image) {
            return hasImageColumn = true;
        }
    });


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className={hasImageColumn ? "table-row-with-image" : ""}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top', width: '100%' }} className="table-container-header">
                <h3 className={"chart-title table"}>{title}</h3>
            </div>

            {formattedTableBody[0] && formattedTableBody[0].length > 0 ? <Table id='mytable' head={props.headings} body={formattedTableBody} /> : <div style={{ alignSelf: 'center', marginBottom: '50px' }}>No data available</div>}
        </div>
    );
};

export default TableBlock;
