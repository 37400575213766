import React, { useState, useEffect, useCallback, useMemo, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { filterType } from "../data/dataOptions";
import * as companyActions from '../actions/companyActions';
import * as systemActions from '../actions/systemActions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

const DataFilter = (props) => {

  const selectCompany = (state) => state.company;
  const selectDate = (state) => state.system;
  const { data } = useSelector(selectCompany);
  const { globalDate, globalDateRange } = useSelector(selectDate);
  let d = useMemo(() => new Date(), []);
  const lastMonth = useMemo(() => d.setMonth(d.getMonth() - 1), [d]);
  const lastDayOfMonth = useMemo(() => {
    d.setMonth(d.getMonth() + 1);
    return d.setDate(0);
  }, [d]);
  const firstDayOfMonth = useMemo(() => {
    d.setMonth(d.getMonth() - 1);
    return d.setDate(1);
  }, [d]);

  const dispatch = useDispatch();
  // Is picker for selecting date range
  const isDateRange = props.dateRange;
  const [dateRangeSelectCounter, setDateRangeSelectCounter] = useState(1);
  let dateToUse = isDateRange ?  new Date(firstDayOfMonth) : new Date(lastMonth);
  if (isDateRange) {
    if (globalDateRange) {
      dateToUse = globalDateRange[0];
    }
  }
  else {
    if (globalDate) {
      dateToUse = globalDate;
    }
  }
  const [startDateGlobal, setStartDateGlobal] = useState(new Date(dateToUse));
  const [endDate, setEndDate] = useState(globalDateRange ? globalDateRange[1] : new Date(lastDayOfMonth));
  const filterTypeFile = useMemo(() => { return { ...filterType }; }, []);
  const { charts, page, options, onChange, source, width } = props;
  const passedData = useMemo(() => props.data, [props.data]);
  const [filteredData, setFilteredData] = useState();
  const [selectedOption, setSelectedOption] = useState();

  const [isInitialRender, setIsInitialRender] = useState(true);

  const formatDate = useCallback((date, withDay) => {
    if (date) {
      const day = withDay && (date.getDate() < 10 ? `0${date.getDate()}` : date.getDate());
      const month =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`;
      const year = date.getFullYear();
      return `${year}-${month}${day ? `-${day}` : ''}`;
    } else {
      const month =
        lastMonth.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`;
      const year = lastMonth.getFullYear();

      return `${year}-${month}`;
    }
  }, [lastMonth]);

  const yearBeginning = useCallback((date) => {
    if (date) {
      const month = '01';
      const year = date.getFullYear();
      return `${year}-${month}`;
    }
  }, []);

  const yearEnd = useCallback((date) => {
    if (date) {
      const month = '12';
      const year = date.getFullYear();
      return `${year}-${month}`;
    }
  }, []);

  // const monthEnd = useCallback((date) => {
  //   if (date) {
  //     const month = date.getMonth();
  //     const year = date.getFullYear();
  //     const day = new Date(year, month + 1, 0).getDate();

  //     return `${formatDate(new Date(year, month, day))}-${day}`;
  //   }
  // }, [formatDate]);

  const timeStampOfStartMonth = new Date(Date.UTC(startDateGlobal.getFullYear(), startDateGlobal.getMonth(), 1)).getTime() / 1000;
  const timeStampOfEndOfMonth = new Date(Date.UTC(startDateGlobal.getFullYear(), startDateGlobal.getMonth() + (startDateGlobal.getMonth() === 11 ? 0 : 1), startDateGlobal.getMonth() === 11 ? 31 : 1)).getTime() / 1000;

  const groupState = charts ? charts[0].group : undefined;

  const getData = useCallback(() => {
    const isDateRangeSelected = (isDateRange && dateRangeSelectCounter > 1) || (isDateRange && dateRangeSelectCounter === 0);
    const isSingleDateSelected = !isDateRange;

    if (isDateRangeSelected || isSingleDateSelected) {
      const dateRange = ['c1'];
      let requests = [];
      for (let i = 0; i < charts.length; i++) {
        if (Array.isArray(charts[i].sheet)) {
          if (dateRange.includes(charts[i].endpoint)) {
            charts[i].sheet.forEach((item, index) => {
              var request = `${filterTypeFile[charts[i].endpoint][0]}?${charts[i].sheet[index] ? `sheet=${charts[i].sheet[index]}&` : ``
                }date=${yearBeginning(startDateGlobal)}-01${`&date2=${yearEnd(startDateGlobal)}-31`}&${charts[i].group ? `group=${charts[i].group}` : ''
                }${source ? `&source=${source.value}` : ''}`;

              if (!requests.includes(request)) {
                requests.push(request);
              }
            });
          }
          else {
            if (charts[i].endpoint === "dc" && endDate) {
              charts[i].sheet.forEach((item, index) => {
                var request = `${filterTypeFile[charts[i].endpoint][0]}?${charts[i].sheet[index] ? `sheet=${charts[i].sheet[index]}&` : ``
                  }date=${formatDate(startDateGlobal, true)}${`&date2=${formatDate(endDate, true)}`}${charts[i].group ? `&group=${charts[i].group}` : ''
                  }${source ? `&source=${source.value}` : ''}`;

                if (!requests.includes(request)) {
                  requests.push(request);
                }
              });
            } else if (!isDateRange) {
              charts[i].sheet.forEach((item, index) => {
                var request = `${filterTypeFile[charts[i].endpoint][0]}?${charts[i].sheet[index] ? `sheet=${charts[i].sheet[index]}&` : ``
                  }date=${formatDate(startDateGlobal)}-01${charts[i].group ? `&group=${charts[i].group}` : ''
                  }${source ? `&source=${source.value}` : ''}`;

                if (!requests.includes(request)) {
                  requests.push(request);
                }
              });
            }
          }
        }
        else {
          if (charts[i].endpoint === "ads") {
            var request = `${filterTypeFile[charts[i].endpoint][0]}?created_gt=${timeStampOfStartMonth}&created_lt=${timeStampOfEndOfMonth}`;
          } else if (!dateRange.includes(charts[i].endpoint) || (dateRange.includes(charts[i].endpoint) && charts[i].sheet === 2)) {
            request = `${filterTypeFile[charts[i].endpoint][0]}?${charts[i].sheet ? `sheet=${charts[i].sheet}&` : ``
              }date=${formatDate(startDateGlobal)}-01${charts[i].group ? '&group=true' : ''}`;
          } else {
            request = `${filterTypeFile[charts[i].endpoint][0]}?${charts[i].sheet ? `sheet=${charts[i].sheet}&` : ``
              }date=${(!charts[i].monthly ? yearBeginning(startDateGlobal) : formatDate(startDateGlobal))}-01${(charts[i].endpoint === 'c1' && !charts[i].monthly ? `&date2=${yearEnd(startDateGlobal)}-31` : '')}${charts[i].group ? '&group=true' : ''}${source ? `&source=${source.value}` : ''
              }`;
          }
          if (!requests.includes(request)) {
            requests.push(request);
          }
        }
      }
      if (requests.length > 0) {
       //alert(requests); // testing **** to do  ***** sort by
        dispatch(companyActions.getCompanyData(requests, page));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateGlobal, endDate, dispatch, filterTypeFile, formatDate, page, yearBeginning, yearEnd, source, groupState, isDateRange]);

  useEffect(() => {
    if (!passedData) {
      getData();
    }
  }, [startDateGlobal, getData, passedData]);

  useEffect(() => {
    if (props.autoSet && isInitialRender) {
      if (options) {
        if (props.autoSetTo) {
          const selectedOption = options[props.autoSetTo];
          setSelectedOption(selectedOption);
        }
        else {
          const selectedOption = options[0] && options[0] !== "Select" ? options[0] : options[1];
          setSelectedOption(selectedOption);
          setFilteredData(selectedOption);
        }
      }
    }
  }, [options, props.autoSet, isInitialRender, props.autoSetTo]);

  const icon = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%"
        }}
      >
        <svg
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6.33325H2.66667V7.66659H4V6.33325ZM6.66667 6.33325H5.33333V7.66659H6.66667V6.33325ZM9.33333 6.33325H8V7.66659H9.33333V6.33325ZM10.6667 1.66659H10V0.333252H8.66667V1.66659H3.33333V0.333252H2V1.66659H1.33333C0.593333 1.66659 0.00666666 2.26659 0.00666666 2.99992L0 12.3333C0 13.0666 0.593333 13.6666 1.33333 13.6666H10.6667C11.4 13.6666 12 13.0666 12 12.3333V2.99992C12 2.26659 11.4 1.66659 10.6667 1.66659ZM10.6667 12.3333H1.33333V4.99992H10.6667V12.3333Z"
            fill="#23244D"
            opacity="1"
          />
        </svg>
        <span>{formatDate(startDateGlobal, isDateRange) + (isDateRange ? " - " : "") + (!endDate ? "Not selected" : "") + `${isDateRange && endDate ? `${formatDate(endDate, isDateRange)}` : ""}`}</span>
      </div>
    );
  };

  const dateChangeGlobalHandler = (dates) => {
    // Argument dates could be object or array - [startDate, endDate]
    const isDateRange = Array.isArray(dates);

    if (!isDateRange) {
      setStartDateGlobal(dates);
      dispatch(systemActions.setDate(dates));
    } else {
      const [start, end] = dates;

      if (end && end.getTime() > (startDateGlobal.getTime() ?? -1)) {
        setDateRangeSelectCounter(dateRangeSelectCounter => dateRangeSelectCounter += 1);
      } else {
        onChange(dates);
        setDateRangeSelectCounter(1);
      }
      setStartDateGlobal(start);
      setEndDate(end);
      if (start && end) {
        dispatch(systemActions.setDateRange([start, end]));
      }

    }
    const isDateRangeSelected = (onChange !== null && isDateRange && dateRangeSelectCounter + 1 > 1 && dates[1] !== null);

    const isSingleDateSelected = onChange !== null && !isDateRange;
    if (isSingleDateSelected || isDateRangeSelected) {
      onChange(dates);
    }
  };

  const ExampleCustomInput = forwardRef((props, ref) => {
    return (
      <button {...props} onClick={props.onClick}>
        {icon()}
      </button>
    );
  });


  const filterPassedData = (selected) => {
    if (props.autoSet) {
      setSelectedOption(selected);
      setIsInitialRender(false);
    }
    if (onChange) {
      onChange(selected);
    }
    setFilteredData(selected);
  };

  const DropdownIndicator = () => {
    return (
      <svg width="12" height="8" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L6 6L11 1" stroke="#23244D" />
      </svg>
    );
  };

  const [isEveryChartContainsData, setIsEveryChartContainsData] = useState(true);
  useEffect(() => {
    return data && data[page] && data[page]?.forEach((chart, index) => {
      const key = chart[index] ? Object.keys(chart[index])[0] : undefined;
      if (key) {
        if (chart[index][key] === 0) {
          setIsEveryChartContainsData(false);
        }
      }
    });
  }, [data, page]);


  let content = null;

  if (passedData && options) {
    content =
      <>
        <Select
          options={options}
          onChange={(value) => { filterPassedData(value); }}
          components={{ DropdownIndicator }}
          className={`filter-${page} ` + props.className && !charts ? props.className : ""}
          styles={{
            container: base => ({
              ...base,
              marginRight: "15px",
              paddingTop: 0,
              alignSelf: 'flex-end',
              fontSize: "11px"
            }),
            control: base => ({
              ...base,
              borderColor: 'transparent',
              backgroundColor: '#F1F2F5',
              borderRadius: '10px',
              minWidth: "71px",
              minHeight: "31px",
              width: width ? width : "115px",
              boxShadow: '0 0 0 0px transparent',
              '&:hover': {
                borderColor: 'transparent',
              }
            }),
            indicatorsContainer: base => ({
              ...base,
              width: '20%',
              marginRight: "5px",
            }),
            indicatorSeparator: base => ({
              ...base,
              backgroundColor: 'transparent'
            }),
            menu: base => ({
              ...base,
              width: '160px',
              right: '0px',
            }),
          }}
          value={selectedOption}
        />
        {props.children(filteredData)}
      </>;
  }
  else {
    const shouldCloseOnSelect = false;

    content = (
      <>
        <DatePicker
          selected={startDateGlobal}
          onChange={(dates) => dateChangeGlobalHandler(dates)}
          dateFormat="MM/yyyy"
          isClearable
          showMonthYearPicker={!isDateRange}
          placeholderText={`Filter by date`}
          customInput={<ExampleCustomInput className={"example-custom-input-" + (props.classSuffix ? props.classSuffix : "") + (isDateRange ? " example-custom-input__date-range" : "")} style={{ backgroundColor: "white" }} />}
          showPopperArrow={false}
          popperPlacement="bottom-end"
          wrapperClassName={`${props.className && charts ? props.className : ""}`}
          selectsRange={isDateRange}
          startDate={isDateRange ? startDateGlobal : null}
          endDate={isDateRange ? endDate : null}
          shouldCloseOnSelect={isDateRange && shouldCloseOnSelect}
          disabledKeyboardNavigation={isDateRange}
        />
        {props.children(data, formatDate(startDateGlobal), isEveryChartContainsData)}
      </>
    );
  }


  return content;

};

export default DataFilter;
