export const GREEN = "#33CC33";
export const LIGHT_GREEN = "#00b37a";
export const ORANGE = "#FF7733";
export const BLUE = "#66B3FF";
export const TABLE_TEXT_COLOR = "#23244D";
export const WHITE = "#FFFFFF";
export const LIGHT_SILVER = "#F1F2F5";
export const GREY = "#666666";
export const LIGHT_BLUE = "#3DADD0";
export const DARK_ORANGE = "#E84E1B";
