import React, { useEffect, useState, useCallback } from "react";
import BarChart from "./BarChart";

/**
 * Custom Bar Block component.
 *
 * BarBlock component renders Bar Chart. Based on the data, Bar Chart could have
 * one Bar for each of the data, or if it has more data it would have 2 Bars at
 * the same index of Bar.
 *
 * @param {string} title - The title of the block
 * @param {Object} filter
 * @param {Array<number>} filter.year - Year range (min and max is defined by
 * the raw dataset)
 * @param {Array<string>} filter.month - Min and Max is defined by the raw
 * dataset (do not hard code)
 * @returns {JSX.Function(any)} Renders <BarChart> component.
 * @public
 */
const BarBlock = (props) => {
  const options = {
    title: "Contacts per region",
    fg: false,
    bgColor: "#fff",
    axes: true,
    yLines: true,
    xLines: false,
    isLine: props.isLine ? props.isLine : [],
    angleLabel: props.angleLabel ? props.angleLabel : false,
    wrapLabel: props.wrapLabel ? props.wrapLabel : false,
    legend: [],
  };

  const currentData = 0;
  const {
    data,
    width,
    height,
    dataType,
    sheet,
    title,
    id,
    dataToPlot,
    animate,
    index,
    color,
    page,
    oddXAxesValuesLowerPosition,
    useIndexForArrayAccess,
    showTenLargestBars,
    longtailBarSuffix,
  } = props;
  const [filterData, setFilterData] = useState();


  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  let metric = ['', ''];

  if (Array.isArray(dataToPlot)) {
    dataToPlot.forEach((e, i) => {
      if (['total_spend', 'cpm', 'cpc', 'cpa', 'cpv'].includes(e)) {
        metric[i] = '€'
      }
      else if (['ctr', 'vtr'].includes(e)) {
        metric[i] = '%'
      }
    });
  }
  else {
    if (['total_spend', 'cpm', 'cpc', 'cpa', 'cpv'].includes(dataToPlot)) {
      metric[0] = '€'
    }
    else if (['ctr', 'vtr'].includes(dataToPlot)) {
      metric[0] = '%'
    }
  }


  const formatData = useCallback(
    (data, type, num = null) => {
      let value = [];

      ///sort array by week number **** Manuel
         //alert(type);
      ///End Sorting


      switch (type) {
        case "bo":
          if (dataToPlot) {
            if (data[dataToPlot]) {
              value.push(data[dataToPlot]);
            } else {
              if (data.net_budget) {
                value.push(data.net_budget);
              } else if (data.contacts_per_channel) {
                value.push(data.contacts_per_channel);
              } else if (data.visits_total) {
                value.push(data.visits_total);
              } else if (data.ndcs_medium) {
                value.push(data.ndcs_medium);
              } else {
                value.push(0);
              }
            }
          } else {
            if (data.net_budget) {
              value.push(data.net_budget);
            } else if (data.contacts_per_channel) {
              value.push(data.contacts_per_channel);
            } else if (data.visits_total) {
              value.push(data.visits_total);
            } else if (data.ndcs_medium) {
              value.push(data.ndcs_medium);
            } else {
              value.push(0);
            }
          }

          if (data.mediums_name) {
            value.push(data.mediums_name);
          } else {
            value.push(data.companies_name);
          }
          break;
        case "bpc":
          if (dataToPlot) {
            if (data[dataToPlot]) {
              value.push(data[dataToPlot]);
            } else {
              if (data.contacts_per_channel) {
                value.push(data.contacts_per_channel);
              } else if (data.net_budget) {
                value.push(data.net_budget);
              } else if (data.cpm_per_channel) {
                value.push(data.cpm_per_channel);
              } else {
                value.push(0);
              }
            }
          } else {
            if (data.contacts_per_channel) {
              value.push(data.contacts_per_channel);
            } else if (data.net_budget) {
              value.push(data.net_budget);
            } else if (data.cpm_per_channel) {
              value.push(data.cpm_per_channel);
            } else {
              value.push(0);
            }
          }

          if (data.channels_id) {
            value.push(data.channels_id.name);
          }
          break;
        case "bpci":
          if (dataToPlot) {
            if (data[dataToPlot]) {
              value.push(data[dataToPlot]);
            } else {
              if (data.net_budget) {
                //value.push(data.net_budget); **** test**** Manuel
              } else if (data.impressions_per_region) {
                value.push(data.impressions_per_region);
              } else {
                value.push(0);
              }
            }
          } else {
            if (data.net_budget) {
              //value.push(data.net_budget); 
            } else if (data.impressions_per_region) {
              value.push(data.impressions_per_region);
            } else {
              value.push(0);
            }
          }

          if (data.regions_id) {
            value.push(data.regions_id.name);
          }
          break;
        case "cm":
          if (dataToPlot) {
            if (data[dataToPlot]) {
              value.push(data[dataToPlot]);
            } else {
              if (data.gross_contacts_in_mio) {
                value.push(data.gross_contacts_in_mio);
              } else if (data.gross_costs_in_euro) {
                value.push(data.gross_costs_in_euro);
              } else if (data.net_costs) {
                value.push(data.net_costs);
              } else if (data.net_cpm_target_group) {
                value.push(data.net_cpm_target_group);
              } else if (data.cost_per_visit_net) {
                value.push(data.cost_per_visit_net);
              } else if (data.gross_rating_point_grp_channel) {
                value.push(data.gross_rating_point_grp_channel);
              } else if (data.visits) {
                value.push(data.visits);
              } else if (data.cost_per_visit_net) {
                value.push(data.cost_per_visit_net);
              } else if (data.visit_per_grp) {
                value.push(data.visit_per_grp);
              } else {
                value.push(0);
              }
            }
          } else {
            if (data.gross_contacts_in_mio) {
              value.push(data.gross_contacts_in_mio);
            } else if (data.gross_costs_in_euro) {
              value.push(data.gross_costs_in_euro);
            } else if (data.net_costs) {
              value.push(data.net_costs);
            } else if (data.net_cpm_target_group) {
              value.push(data.net_cpm_target_group);
            } else if (data.cost_per_visit_net) {
              value.push(data.cost_per_visit_net);
            } else if (data.gross_rating_point_grp_channel) {
              value.push(data.gross_rating_point_grp_channel);
            } else if (data.visits) {
              value.push(data.visits);
            } else if (data.cost_per_visit_net) {
              value.push(data.cost_per_visit_net);
            } else if (data.visit_per_grp) {
              value.push(data.visit_per_grp);
            } else {
              value.push(0);
            }
          }

          if (data.channels_id) {
            value.push(data.channels_id.name);
          }
          break;
        case "c2":
          value.push(data.gross_budget);
          value.push(data.grp_gross);
          value.push(data.cost_per_grp_gross);
          value.push(
            `${data.competitors_id.name}${
              data.channels_id ? "-" + data.channels_id.name : null
            }`
          );
          break;
        case "t":
          if (dataToPlot) {
            if (data[dataToPlot]) {
              value.push(data[dataToPlot]);
            } else {
              if (data.grp) {
                value.push(data.grp);
              } else if (data.gross_contacts) {
                value.push(data.gross_contacts);
              } else if (data.cpgrp_net) {
                value.push(data.cpgrp_net);
              } else if (data.net_cpm_target_group) {
                value.push(data.net_cpm_target_group);
              } else if (data.tv_to_web_conversion_simulated) {
                value.push(data.tv_to_web_conversion_simulated);
              } else if (data.cost_per_visit_net_simulated) {
                value.push(data.cost_per_visit_net_simulated);
              } else if (data.visits) {
                value.push(data.visits);
              } else {
                value.push(0);
              }
            }
          } else {
            if (data.grp) {
              value.push(data.grp);
            } else if (data.gross_contacts) {
              value.push(data.gross_contacts);
            } else if (data.cpgrp_net) {
              value.push(data.cpgrp_net);
            } else if (data.net_cpm_target_group) {
              value.push(data.net_cpm_target_group);
            } else if (data.tv_to_web_conversion_simulated) {
              value.push(data.tv_to_web_conversion_simulated);
            } else if (data.cost_per_visit_net_simulated) {
              value.push(data.cost_per_visit_net_simulated);
            } else if (data.visits) {
              value.push(data.visits);
            } else {
              value.push(0);
            }
          }

          if (data.time_slots_id.name) {
            value.push(data.time_slots_id.name);
          } else if (data.companies_id.name) {
            value.push(data.companies_id.name);
          }
          break;
        case "dc"://// *****  To do *****
          if (num !== null) {
            if (dataToPlot) {
              value.push(
                data[dataToPlot[num]]?.id
                  ? data[dataToPlot[num]].id
                  : data[dataToPlot[num]]
                  ? data[dataToPlot[num]]
                  : 0
              );
            }

            if (data.regions_id) {
              value.push(data.regions_id.name);
            } else if (data.week_number) {
              value.push(data.week_number);
            } else if (data.sources_id) {
              value.push(data.sources_id.id);
            }
          } else {
            if (dataToPlot) {
              if (data[dataToPlot]) {
                value.push(
                  data[dataToPlot].id ? data[dataToPlot].id : data[dataToPlot]
                );
              }
            }
            value.push(data.regions_id.name);
          }
          break;
        default:
          break;
      }
      return value;
    },
    [dataToPlot]
  );

  useEffect(() => {
    let formattedData = [];
    if (data) {
      if (Object.keys(data).length !== 0 && data[page]) {
        const longtailTitleSuffix = longtailBarSuffix ? longtailBarSuffix : "";

        if (Array.isArray(sheet)) {
          sheet.forEach((sheetNum, sheetIndex) => {
            let dataset = [];
            let fetchedData = data[page][sheetNum - 1];

            if (showTenLargestBars && longtailBarSuffix !== "week number") {
              let other = {
                name: "Longtail " + longtailTitleSuffix,
                value: 0,
                fgColor: "#000000",
                bgColor: getRandomColor(),
                barColor: Array.isArray(color) ? color[sheetIndex] : color,
                holeDiameter: 0,
                showValuesInside: true,
                additionalData: [],
              };

              let longtailCount = 0;
              fetchedData
                .sort((a, b) =>
                  a[dataToPlot[sheetIndex]] > b[dataToPlot[sheetIndex]] ? -1 : 1
                )
                .forEach((item, index) => {
                  if (index < 10) {
                    const value = formatData(item, dataType, sheetIndex);
                    let tempElem = {
                      name: value[1],
                      value: value[0] !== Infinity ? value[0] : 0,
                      fgColor: "#000000",
                      bgColor: getRandomColor(),
                      barColor: Array.isArray(color)
                        ? color[sheetIndex]
                        : color,
                      holeDiameter: 0,
                      showValuesInside: true,
                      additionalData: item,
                    };
                    dataset.push(tempElem);
                  } else {
                    const value = formatData(item, dataType, sheetIndex);
                    other.value = other.value + value[0];
                    longtailCount += 1;
                  }
                });
              if (other.value > 0) {
                other.value = other.value / longtailCount;
                dataset.push(other);
              }
            } else {
              fetchedData.forEach((item, index) => {
                if (!item.statusCode) {
                  const value = formatData(item, dataType, sheetIndex);
                  let tempElem = {
                    name: value[1],
                    value: value[0] !== Infinity ? value[0] : 0,
                    fgColor: "#000000",
                    bgColor: getRandomColor(),
                    barColor: Array.isArray(color) ? color[sheetIndex] : color,
                    holeDiameter: 0,
                    showValuesInside: true,
                    additionalData: item,
                  };
                  dataset.push(tempElem);
                }
              });
            }
            formattedData.push(dataset);
          });
          setFilterData([formattedData]);
        } else {
          const fetchedData = useIndexForArrayAccess
            ? data[page][index - 1]
            : data[page][sheet - 1];

          let other = {
            name: "Longtail " + longtailTitleSuffix,
            value: 0,
            fgColor: "#000000",
            bgColor: getRandomColor(),
            barColor: color,
            holeDiameter: 0,
            showValuesInside: true,
            additionalData: [],
          };

          if (fetchedData) {
            if (!fetchedData.statusCode) {
              if (showTenLargestBars) {
                let longtailCount = 0;
                fetchedData
                  .sort((a, b) => (a[dataToPlot] > b[dataToPlot] ? -1 : 1))
                  .forEach((item, index) => {
                    if (!item.statusCode) {
                      if (index < 10) {
                        const value = formatData(item, dataType);
                        let tempElem = {
                          name: value[1],
                          value: value[0] !== Infinity ? value[0] : 0,
                          fgColor: "#000000",
                          bgColor: getRandomColor(),
                          barColor: color,
                          holeDiameter: 0,
                          showValuesInside: true,
                          additionalData: item,
                        };
                        formattedData.push(tempElem);
                      } else {
                        const value = formatData(item, dataType);
                        other.value = other.value + value[0];
                        longtailCount += 1;
                      }
                    }
                  });
                if (other.value > 0) {
                  other.value = other.value / longtailCount;
                  formattedData.push(other);
                }
              } else {
                fetchedData.forEach((item, index) => {
                  if (!item.statusCode) {
                    const value = formatData(item, dataType);
                    let tempElem = {
                      name: value[1],
                      value: value[0] !== Infinity ? value[0] : 0,
                      fgColor: "#000000",
                      bgColor: getRandomColor(),
                      barColor: color,
                      holeDiameter: 0,
                      showValuesInside: true,
                      additionalData: item,
                    };
                    formattedData.push(tempElem);
                  }
                });
              }

              const areAllValuesZero = formattedData
                .map((data) => data.value)
                .every((v) => v === 0);
              setFilterData([
                [
                  !areAllValuesZero
                    ? formattedData
                    : formattedData.filter((item) => item.value !== 0),
                ],
              ]);
            }
          }
        }
      }
    }
  }, [
    data,
    color,
    dataType,
    index,
    formatData,
    page,
    sheet,
    useIndexForArrayAccess,
    showTenLargestBars,
    dataToPlot,
    longtailBarSuffix,
  ]);

  let listItems = [];
  if (filterData && filterData[0].length > 0) {
    const columnNum = filterData.length % 3;
    const rowNum = Math.ceil(filterData.length / 3);
    filterData.map((chartData, i) => {
      let mapped = chartData.map((item, index) => {
        return { index: index, value: item };
      }); //reformat data into { index, value } format, where value is the dataset itself
      let cleaned = mapped.sort((a, b) =>
        options.isLine.includes(a.index) ? 1 : -1
      ); //sort the array by newly added indexes
      let max = Math.max.apply(
        Math,
        cleaned[0].value.map((item) => {
          return item.value;
        })
      ); //get the highest value property from the dataset
      listItems.push(
        <BarChart
          containerId={id}
          title={title}
          key={`BarChart${id}`}
          id={`BarChart${id}`}
          width={width / columnNum}
          height={height / rowNum}
          options={options}
          data={cleaned}
          maxBarValue={max}
          isDataChanged={animate}
          currentData={currentData}
          previousData={cleaned}
          oddXAxesValuesLowerPosition={oddXAxesValuesLowerPosition}
          metric={metric}
        />
      );
      return listItems;
    });
  }

  return filterData && filterData[0][0]?.length > 0 ? (
    <div
      className={`barblock-${id}`}
      style={{
        width: "100%",
        height: height,
        display: id === "vt" || id === "nt" ? "flex" : "block",
        flexDirection: "column",
      }}
    >
      <div className="chartGrid">{data && listItems}</div>
    </div>
  ) : (
    <>
      <h3 className="chart-title">{props.title}</h3>
      <div
        style={{
          width: "100%",
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        No data available
      </div>
    </>
  );
};

export default BarBlock;
