import React from "react";
import SideBar from "./SideBar";
import { useSelector } from "react-redux";

const Layouts = () => {
  const selectUser = (state) => state.user;
  const { user } = useSelector(selectUser);

  return (
    user && user !== "not checked" ? (
      <SideBar />
    ) : (
        <div className="bg" ></div>

      )
  );
};

export default Layouts;
