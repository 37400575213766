import React, { useEffect, useState } from "react";
import "./../App.css";
import { useSelector, useDispatch } from "react-redux";
import * as systemActions from "../actions/systemActions";
import { ReactComponent as ArrowDown } from "./../assets/icons/arrow-down.svg";
import DataFilter from "../components/DataFilter"; ///key
import BarBlock from "../components/BarBlock";
import TableBlock from "../components/TableBlock";
import { months } from "../data/dataOptions";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../constants/colors";
import Table from "../components/Table";


//let mydata=[];
var myCounter=0;
var test=[];
let companies=[];
let listrows=[]; 
var tableheaders;
var tablefooter;
 let mylistAmount = [];
let listAux=[];
let MylistAux=[];
var myhtml=0;
let totalspend=0; let totalruns=0;  //fix this calculations

async function fetchFunction(param0) {

 // var kmonth=document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0];
 
 // var xdateg= new Date(selectedDates[0]).getUTCMonth();
  //alert(param0); //value + 1 //***to do */
   console.log("******** sssssss ********");
//alert('testing'); https://tapi.cnqr-group.com/competitors-data?_limit=-1&timestamp=2022-12 *** add timestamp to query ****

  const fetch = require("node-fetch");
  let mydata=[];
  
   //filter here only year - month ////***TO DO***** /
   var kjquery='';

   if(window.location.search!=="")
   {
    var kjyear= window.location.search.split('&')[1].split('=')[1];
    var kjmonth= window.location.search.split('&')[0].split('=')[1];
    //kjquery='&timestamp='+kjyear + '-' + kjmonth; ///to do
   kjquery='&timestamp='+ kjyear + '-' + kjmonth;
 /*   json=json.filter(function(mydataxi) {
      return mydataxi.timestamp==kjyear + '-' + kjmonth;
       });*/

   }

  try{
	const response = await fetch(`https://tapi.cnqr-group.com/competitors-data?_limit=-1` + kjquery);
	var json = await response.json();
   companies=[];
  

  /*
   var yeartemp= new Date(selectedDates[0]).getUTCFullYear();
   var monthtemp= new Date(selectedDates[0]).getUTCFullYear();
  //2022-1
  


*/

   for(var zindex=0;zindex<=json.length-1;zindex++ )
  {


    var xxmonth= json[zindex].timestamp.split('-')[1];// new Date(json[zindex].timestamp*1000).getUTCMonth();
    //if(xxmonth===(param0+1)) 2022-04-11
    {
      let temp=[];
      temp.month=parseInt(xxmonth);
      temp.competitor_id=json[zindex].competitors_id;
      temp.media_gross=json[zindex].media_gross;
      temp.timestamp=json[zindex].timestamp;
      temp.runs=json[zindex].runs;
      mydata.push(temp);
      if(!companies.includes(json[zindex].competitors_id.name))
      {
        companies.push((json[zindex].competitors_id.name));
      }  

    }

    //console.log(json[zindex]); 
    

   // test=test + json[zindex].competitors_id.name + "<br>";
  }
   test = mydata.slice();
   //console.log(companies)// ++++ continue;
  }
  catch(err) {
    throw err;
    console.log(err);
  }
}

const CompetitorStats = () => {


 //fetchFunction();
// console.log(test);
// State vars populated over redux.
const selectCompany = state => state.company;
const { data } = useSelector(selectCompany);
const dispatch = useDispatch();
const [init, setInit] = useState(true);
const [animate, setAnimate] = useState(-3);
const [noData, setNoData] = useState(false);
const [dataToPlot, /*setDataToPlot*/] = useState();
const [source, setSource] = useState();
const page = "competitorstats";

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];

var monthCaption='';
var monthselection=0;

window.addEventListener('keydown', (e) => {



});




const tempHeadings = [              
                  
  { name: "Name", key: 'name' }, 
    { name: "Runs", key: 'runs' }, 
      { name: "Total", key: 'total' } 

];




const tableHeadings = [
 
 
 
 
 
 
 
  { name: "  ", format: ' ' },
  { name: "Source", key: 'sources_id:name' }, //regions_id for regions week_number

 // { name: "timestamp", key: 'timestamp' }, //regions_id for regions week_number
  
  { name: "impressions", key: 'impressions' },
/*  { name: "Frequency", key: 'frequency' },
  { name: "CPM", key: 'cpm', appendText: myCurrency  },
  { name: "Clicks", key: 'clicks' },
  { name: "CPC", key: 'cpc', appendText: myCurrency }, //change for equivalenst currency code 
  { name: "CTR", key: 'ctr', appendText: "%", title: "Click through rate" }, //to do** calculated field (clicks/impressions)
  { name: "FTD", key: 'conversion_1' },
  { name: "CPA_FTD", key: 'cpa', appendText: myCurrency  },////to do** calculated field  (clicks/impressions)
  { name: "Registration", key: 'conversion_2' },
  { name: "CPA_Registration", key: 'conv_2', appendText: myCurrency },
  { name: "DC", key: 'conversion_3'  },
  { name: "CPA_DC", key: 'concersion_3', appendText: myCurrency  },
  { name: "Total Spend", key: 'total_spend', appendText: myCurrency  },+-*/
  //  { name: "Length", key: 'duration', appendText: "months" },
];

const selectSystem = (state) => state.system;
const { spinner } = useSelector(selectSystem);
let myCurrency=0;let currency=0; let totalconcersion3=0;let totalconv_2=0; let totalUniqueImpresions=0; let totalConv3=0;let totalConv2=0;let totalConv1=0;let totalftd=0; let totaltotal=0;let totalImpresions = 0; let totalcpm = 0; let totalcpc = 0; let totalcpa = 0; let totalclicks = 0; let totalctr = 0; let totalvtr = 0; let totalcpv = 0;
const mymonth=0;
useEffect(() => {
  if (!init) {

   // var kmonth=document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0];


  try{
    var monthSelected=document.getElementsByClassName("react-datepicker__month--selected")[0].innerText;
    console.log(monthSelected);
    var monthUrl=parseInt(window.location.search.split('=')[1]);
    console.log(monthSelected); 
    if(parseInt(monthUrl)===5)
      {
        console.log("month may");
      //  document.getElementsByClassName("react-datepicker__month-4")[0].click()
      }

  }catch{}

   var kmonth=document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0];
    var frx=1;//new Date(selectedDates[0]).getUTCMonth();
 
   fetchFunction(frx);

  if(kmonth=== undefined)
  {
   //to do  **** hidde calendar on loading **** 
   //setSelectedDates(new Date());
   //if(selectedDates===null)
   {
   document.getElementsByClassName("example-custom-input-1")[0].click();

    document.getElementById("myexp").focus();
  }

    kmonth=1;
    console.log("******** MONTH testing ********");
  }

   console.log(kmonth); //to do ///
   //console.log(selectedDates);
   console.log("******** sssssss ********");
  //  console.log(mylist);

    if (Object.keys(data).length > 0 && data[page] && data[page].filter((item) => item.length > 0).length > 0) {
      if (noData) {
        setNoData(false);
      }
      else {
        setAnimate((animate) => animate + 1);
      }
    }
    else {
      if (animate => animate !== -2) {
        setAnimate(-2);
        setNoData(true);
      }
    }

    if(document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0])
    {
     document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0].click();
    }

  }
  else {
    if (Object.keys(data).length > 0 && data[page]) {
      setAnimate((animate) => animate < -2 ? animate + 2 : animate + 1);
      setInit(false);
    }

   

   if(document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0])
   {
    document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0].click();
   }


  }
}, [data, init, noData]);

const [groupBy, setGroupBy] = useState("regions_id");

const charts = [
  {
    title: dataToPlot ? dataToPlot.label : 'competitor statistics ',
    id: "dc",
    sheet: [1, 1],
    chartType: "bar",
    //dataToPlot: "net_budget",
    color: ["#E84E1B", "#3DADD0"],
    endpoint: "dc",
    isLine: [1],
    group: groupBy,
  },
  {
    title: `competitor statistics`,
    id: "once",
    sheet: 2,
    chartType: "table",
    //dataToPlot: "contacts_per_channel",
    endpoint: "c1",
    monthly: true,
  },

];

const CUSTOMIZED_DROPDOWN_METRIC_LABELS = {
  conversion_1: "NDC",
  conversion_2: "Registration",
  conversion_3: "Deposit",
  post_click_conversion_1: "NDC PC",
  post_view_conversion_1: "NDC PV"
};

const initialStateOfFilters = [];
initialStateOfFilters["0"] = "undefined";
initialStateOfFilters["1"] = "undefined";
/*initialStateOfFilters["2"] = "undefined";
initialStateOfFilters["3"] = "undefined";*/

const [selectedDates, setSelectedDates] = useState(initialStateOfFilters);
const [selectedFilters, setSelectedFilters] = useState(initialStateOfFilters);

const containerWidth = document.getElementsByClassName("container-content")[0]
  ? document.getElementsByClassName("container-content")[0]
    .offsetWidth
  : 0;


  const mygroupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }), 
    {},
  );


const printToPdf = async () => {
  function getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }
 
  function getHeight() {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );
  }
  const params = {
     pathname: "competitorstats",//****to do**** build test app to fix export pdf, do not commit  */
    selectedDates: selectedDates,
    selectedFilters: selectedFilters,
    width: getWidth(),
    height: getHeight(),
  };

  try{
      dispatch(systemActions.printToPdf(params));
  }catch(err)
  {
    alert(err.message);
  }
 
};

const formatLabel = (label) => {
  let split = label.replace(".id", " ").split('_');
  split[0] = split[0].charAt(0).toUpperCase() + split[0].slice(1);
  let formatted = split.join(' ');
  return formatted;
};

let options = [{ value: 0, label: 'Select...' }];
if (data[page]) {
  if (data[page][0]) {
    if (data[page][0][0]) {
      let index = 1;
      data[page][0].forEach((bar, i) => {
        Object.keys(data[page][0][i])
          .filter(item => !['max_id', 'regions_id', 'sources_id','timestamp'].includes(item))
         // .sort((a, b)=> a.week_number-b.week_number)// sorting ***** To do ***** values by week number *** testing
          .forEach((key) => {
            const customizedKeyName = CUSTOMIZED_DROPDOWN_METRIC_LABELS[key];
            if (data[page][0][i]) {
              if (options.filter(option => option.value === key).length === 0 && data[page][0][i][key] && data[page][0][i][key] !== Infinity) {
                options.push({ index: index++, value: key, label: customizedKeyName ? customizedKeyName : formatLabel(key) });
              }
            }
          });
        if (options.filter(option => option.label === 'Select...').length === 0) {
        
          //options.unshift({ index: 0, value: 0, label: 'Select... test 1' });
        }
      });
    }
  }
}else
{
//load by default on page load  *** to do **** , check init and setnodata above
try{
//document.getElementsByClassName("example-custom-input-1")[0].click();
}catch(err)
{
//document.getElementsByClassName("example-custom-input-1")[0].click(); ****this is the key****

}

//document.getElementsByClassName("react-datepicker__month-1")[0].click();


}
if(charts[1].title==="Digital Performance")
{
try{ 
  
  var kmonth=document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0];
  //document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0].focus();
if(!kmonth)
{//key is here
  //document.getElementsByClassName("example-custom-input-1")[0].click();
  //kmonth=document.getElementsByClassName("react-datepicker__month-text--keyboard-selected")[0];
}


  if( kmonth)
  {

   
    var clickEvent = document.createEvent ("MouseEvents");
    clickEvent.initEvent ("click", true, true);
    kmonth.focus();

    var mytex = document.querySelectorAll("react-datepicker__month-text--keyboard-selected");	
    mytex[0].click();
  }else
  {
    //document.getElementsByClassName("example-custom-input-1")[0].click();

  }
} catch(err)
{
//document.getElementsByClassName("example-custom-input-1")[0].click(); ****this is the key****

}
//document.getElementsByClassName("example-custom-input-1")[0].click();
}


let options2 = [{ value: 0, label: 'Select...' }];///****To do**** pull the filter values from file as per file DG_Oct-March (3) 1st column , after the chart is generated *****
//let options2 = [{ value: 0, label: 'Clear Filter' }];///****April 14th 2021, Reload loaded*** 



if (data[page] && data[page].length) {
  data[page][0].forEach(row => {
    const { sources_id } = row;
    const { id, name } = sources_id;
    if (!options2.find(opt => opt.value === id && opt.label === name)) 
    {
      options2.push({ value: id, label: name });
    }
  });
}else
{
  //load report on landing ** to do**** swicth month selection 
  try{
     // document.getElementsByClassName("example-custom-input-1")[0].click();

  }catch(err)
  {
   //document.getElementsByClassName("example-custom-input-1")[0].click(); ****this is the key****
 
  }
  


  //let oxjmonth= new Date(selectedDates[0]).getUTCMonth();
   /* document.getElementsByClassName("example-custom-input-1")[0].click();
   let mkmonth=document.getElementsByClassName("react-datepicker__month-4")[0];

  if(mkmonth)
  {

    document.getElementsByClassName("react-datepicker__month-4")[0].click()
  }*/

}
//alert(options2.length);/// test

//const groupByFilters = ["regions.id", "week_number"];

const groupByFilters = [ "sources.id"];//,"regions.id"];// **** updated on May 11th 2021 **** it should be regions_id , or source_id

let groupByOptions = [];
if (data[page]) {
  if (data[page][0]) {
    if (data[page][0][0]) {
      let index = 1;
      groupByOptions = groupByFilters
        .map((item) => {
          if (options.filter(option => option?.label === item).length === 0) {
            //return { index: index++, value: item, label: formatLabel(item) };
               return undefined;
          }
          return undefined;
        });
    }
  }
}

const getDataToPlot = (data, dataLine) => {
  let dtp = ['impressions', 'total_spend'];
  if (data && (data.value && data.value !== 0)) {
    dtp[0] = data.value;
  }
  if (dataLine && dataLine.value !== 0) {
    dtp[1] = dataLine.value;
  }
  return dtp;
};


// self executing function here
/*(function() {
// your page initialization code here
// the DOM will be available here
var myte = document.querySelectorAll("table");	

if(myte.length>0)
{
  //alert(document.readyState);
}else
{

  //alert(document.readyState);
}
//add_table_titles(htmlx);

})();*/

///to do *** 
function download_csv(csv, filename) {
  var csvFile;
  var downloadLink;

  // CSV FILE
  csvFile = new Blob([csv], {type: "text/csv"});

  // Download link
  downloadLink = document.createElement("a");

  // File name
  downloadLink.download = filename;

  // We have to create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);

  // Make sure that the link is not displayed
  downloadLink.style.display = "none";

  // Add the link to your DOM
  document.body.appendChild(downloadLink);

  // Lanzamos
  downloadLink.click();

  //alert("test");
}
///check content before push to excel ******to do *****
function export_table_to_csv(html, filename) {


var csv = [];
var rows = document.querySelectorAll("tr");	
var heads=[];
/*
"Digital Performance - " + monthNames[new Date(selectedDates[0]).getUTCMonth()] + " " + xjYear
xjYear= new Date(selectedDates[0]).getUTCFullYear();
**/

//csv.push("Digital Performance - ," + monthNames[new Date(selectedDates[0]).getUTCMonth()] +","+ new Date(selectedDates[0]).getUTCFullYear());
heads.push("");
heads.push("");
heads.push("Source");
heads.push("Impressions");
heads.push("Frequency");
heads.push("CPM");
heads.push("Clicks");
heads.push("CPC");
heads.push("CTR");
heads.push("FTD");
heads.push("CPA_FTD");
heads.push("Registration");
heads.push("CPA_Registration");
heads.push("DC");
heads.push("CPA_DC");
heads.push("Total Spent");


//row.push('Company,Gross Spending,Share of Advertising %,Runs');
  for (var i = 0; i < rows.length; i++) {
  var row = [], cols = rows[i].querySelectorAll("td");
  
//  row.push("test,test,test");

      for (var j = 0; j < cols.length; j++) 

          //var x=cols[j].innerText;
          //x=x.replace(".","");
         // x=x.replace(",",".");
         row.push(cols[j].innerText.replace(",","."));
         // alert(x);
          //row.push(x);
          

        
          //row.push(cols[j].innerText); test
      
  csv.push(row.join(","));		
}

var mytempox=parseInt(window.location.search.split('=')[1]);
         
csv[0]="Competitor's Performance ," + monthNames[parseInt(mytempox)-1] +" "+ monthCaption;
//rearrange the csv data 
//csv.push("total");
var mycsv = [];
var myrow = [];
var mycol=[];

for (var ic = 0; ic < heads.length; ic++)
{
  
  myrow.push(heads[ic]);
}
mycsv.push("");
mycsv.push("Competitor's Performance -" + monthNames[parseInt(mytempox)-1] +" "+ monthCaption);
mycsv.push("");
mycsv.push("");
/*mycsv.push("");
mycsv.push(",");
mycsv.push(",");
mycsv.push("");-+*/
mycsv.push("Company,Gross Spending,Share of Advertising %,Runs");
myrow = [];
 
var ii=0;
var tempnumber=csv.length;

//alert('test');
//var totalruns=0;
for (var i = 1; i < tempnumber; i++) //only works for ** to do *** even numbers
{
 
    if(csv[i].length>10)
    {
      //for(var fr=0;fr<=se.length-2;fr++)
      console.log('test');
      console.log(csv[i]);

      var items=csv[i].split(',');
      var unity=items[0] + ',' + items[1].replace('.','').replace('.','') + ',' + items[2] + ','  + items[3].replace('.','').replace('.','');
      
      //myrow.push(csv[i]);   
      myrow.push(unity);   
      myrow.push(" ");
/*
      myrow.push(sources[ii]); //perfect 
      myrow.push(imp[ii]); //perfect 
      myrow.push(freq[ii]); //perfect 
      myrow.push(_cpm[ii]); //perfect 
      myrow.push(_clicks[ii]);
      myrow.push(_cpc[ii]);
      myrow.push(_ctr[ii]);
      myrow.push(_ftd[ii]);
      myrow.push(_ftdcpa[ii]);
      myrow.push(_reg[ii]);//_regcpa
      myrow.push(_regcpa[ii]);//_regcpa

      myrow.push(_dc[ii]);//_regcpa
      myrow.push(_dccpa[ii]);//_regcpa
      myrow.push(_total[ii]);//_regcpa
*/
      ii=ii+1;
 
          try{
            //console.log(sources[ii]);
            //if(sources[ii])
            {
            mycsv.push(myrow.join(",").trim());
            }
          }catch (error) {
            //console.error(error);
            // expected output: ReferenceError: nonExistentFunction is not defined
            // Note - error messages will vary depending on browser
          }
         
          myrow = [];
      

    }
   
  
    //  mycsv.push(myrow.join(","));	.toLocaleString('it-IT').replace(".",",")
}
mycsv.push("Total," + totalspend + " €,100," + totalruns);
  // Download CSV
   download_csv(mycsv.join("\n"), filename); //testing 
  //download_csv(csv.join("\n"), filename); // unc
}

//myexp


function add_table_titles(html) {

var titles=[];
titles.push("");
titles.push("campaign Source");
titles.push("Impressions");
titles.push("Frequency");
titles.push("Cost per Mile");
titles.push("Clicks");
titles.push("Cost per Click");
titles.push("Click through rate");
titles.push("Conversion 1");
titles.push("Conversion 1 -Cost per action ");
titles.push("Registration");
titles.push("Registration -Cost per action ");
titles.push("Conversion 3-DC ");
titles.push("Conversion 3-Cost per action ");
titles.push("Total spent");
titles.push(" ");  titles.push("Grand Total");  titles.push("Grand Total Impressions");  titles.push("Grand Total Frequency");  titles.push("Grand Total Cost per Mile"); titles.push("Grand Total Clicks"); titles.push("Grand Total Cost per Click");   titles.push("Grand Total Click through Rate");  titles.push("Grand Total FTD");  titles.push("Grand Total FTD , Cost per action");  titles.push("Grand Total Registration");  titles.push("Grand Total Registration, Cost per action");  titles.push("Grand Total DC");  titles.push("Grand Total DC, Cost per action");  titles.push("Grand Total , Total Spent");  titles.push("");  titles.push("");

var headers = document.querySelectorAll("th");
for (var i = 0; i < headers.length; i++) {

  var two = headers[i];

  two.setAttribute("title", titles[i]);
// alert(headers[i].innerHTML);
}

}





/*

*/
function testing()
{
//tableTotals
var html = document.querySelector("table").outerHTML;
//add_table_titles(html);
//alert('test');
export_table_to_csv(html, "table.csv");

}



const addCalculatedFields = (data,param=0) => {


  let xrowCount=0;
  let xjmonth=0;
  let xjYear=0;
  if (selectedDates[0] !== "undefined")
  {
     xjmonth= window.location.search.split('&')[0].split('=')[1] ;//new Date(selectedDates[0]).getUTCMonth();
     xjYear= window.location.search.split('&')[1].split('=')[1] ;//new Date(selectedDates[0]).getUTCFullYear();

     //alert(xjYear);
   // alert(options2.value);
     //if(xjmonth>0)
    {
     // xjmonth=xjmonth+ 1;
      //var d = new Date(selectedDates);
     // alert(xjmonth); month number ok , filter below ****** to do *****
     //charts[1].title="competitor stats - " + monthNames[new Date(selectedDates[0]).getUTCMonth()] + " " + xjYear ;
    }
     
  }
  let temp = [];
  let mylist = [];

  let tempIndex = 1; 
  let counter=2;
  let mytemp = [];
  let mydata = [];
  var mycontent="";

  if (data[page] && data[page].length && selectedDates!=null) 
  {
           var xxxxxc= new Date(selectedDates[0]).getUTCMonth();
           var xxxxxcyear= new Date(selectedDates[0]).getUTCFullYear();
           xxxxxc= (parseInt(xxxxxc)+1);
           var mytempo=parseInt(window.location.search.split('=')[1]);
           
           console.log(xxxxxc); //selected date + 1
           if(mytempo)
           {

            if(mytempo===xxxxxc)
            {
              console.log("**** not redirect *****"); //selected date + 1 *** to do ***** set window location here
            }else
            {


              console.log("**** redirect ***** " + xxxxxc); //selected date + 1 *** to do ***** set window location here
            
              if(parseInt(mytempo)!=parseInt(xxxxxc))
              {
                if(parseInt(xxxxxc)===10)
                {////to do
                  console.log("**** redirect *****  " + mytempo); //selected date + 1 *** to do ***** set window location here  
                  console.log("**** redirect ***** ccccccccccc "); //selected date + 1 *** to do ***** set window location here       
                //  window.location='competitorstats?month=' + xxxxxc;       
                }

              }
              if(xxxxxcyear === undefined)
              {
                xxxxxcyear= new Date(selectedDates[0]).getUTCFullYear();// new Date().getFullYear();/// fix error here
    
              }

              if(new Date().getFullYear()===xxxxxcyear)
              {
                  if(parseInt(xxxxxc)>(new Date().getMonth() + 1))
                  { //// to do ///
                   // alert('testing');
                    ///Future dates are disabled , reload rreport on default date
                    window.location='competitorstats?month=' + new Date().getMonth()  + '&year=' + xxxxxcyear;
                    //return;
                  }

              }

              if(parseInt(xxxxxc)=== 1)///***to do*** increase month here and below as the month changes***/
              {
                myCounter=myCounter +1;
                var testinh=document.referrer;
                console.log("**** redirect kk ***** " + testinh ); //selected date + 1 *** to do 

              }
              if(parseInt(xxxxxc)< 2)///***to do*** increase month here and below as the month changes***/
              {
                window.location='competitorstats?month=' + xxxxxc + '&year=' + xxxxxcyear;
              }else
              {

              if(parseInt(xxxxxc)> 2)///***to do    ***/
              {
                window.location='competitorstats?month=' + xxxxxc + '&year=' + xxxxxcyear;
              }else{

        
                if(mytempo!=xxxxxc)
                {
                  try{
                   //  window.location='competitorstats?month=8';
                    console.log("**** vgvgvg ***** " + xxxxxc); //selected date + 1 *** to do 
                    console.log("**** aaaaaa ***** " + mytempo);
                   // document.getElementsByClassName('competitor-stats')[0].click();
                  }catch{}
 //window.location='competitorstats?month=' + xxxxxc;//
                }
              
               
            console.log("**** xxxxxxxxxx ***** " + xxxxxc); //selected date + 1 *** to do 
                //window.location='competitorstats?month=8';

              }
              
              }




              //window.location='competitorstats?month=' + xxxxxc;
            }
            //continue *****
           monthselection=mytempo - 1;
           }else
           {
            monthselection=new Date(selectedDates[0]).getUTCMonth();

           }
         
           
  companies.forEach((e, i) => {//first loop

      
    let myClicks=0; let myRuns=0;  let myTotal=0;   let myUniqueImp=0; let myImp=0;let myConv1=0; let myConv2=0; let myConv3=0; let mycpm=0;let mycpc=0; let mycpa=0;let myctr=0;let myvtr=0;let mycpv=0;
      if(e.value!=0)
      {
        var filtereddataxaj=test.filter(function(mydatax) {
          return mydatax.competitor_id.name==e;
           });
          
           
          if(window.location.search==="")
          {   //***TO DO***/
            //if(xxxxxcyear === undefined)
              {
                xxxxxcyear=  new Date().getFullYear();
    
              }
            window.location='competitorstats?month=' + xxxxxc + '&year=' + xxxxxcyear;
          }else  //if(parseInt(window.location.search.split('=')[1]) != xxxxxc)
          {

            var mytemp=parseInt(window.location.search.split('=')[1]);
            //console.log('*********calendar*******');
         
            //console.log('*********url param*******');
            //console.log(mytemp);
            //alert('test');
            //if(localStorage.getItem("month")!=xxxxxc)
            {
              //alert(xxxxxc);fddfdf
             // localStorage.setItem("month",xxxxxc);
              //window.location='competitorstats?month=' + xxxxxc;
            }           
            
            if(xxxxxc!=mytemp)
            {
              console.log(mytemp);///to do 
              console.log("mytemp");///to do 

            }


            
            
         try{

          document.getElementsByClassName('react-datepicker__month-1')[0].addEventListener("click", function displayDate() {
            ///****** to do , update every month*******/
            var xxxxxcyear= document.getElementsByClassName('react-datepicker-year-header')[0].innerText;//new Date(selectedDates[0]).getUTCFullYear();
            window.location='competitorstats?month=2'  + '&year=' + xxxxxcyear;
            //alert('testing');
          }); 



          console.log(document.getElementsByClassName("react-datepicker__month--selected")[0].innerText);
         
         var jumonth=document.getElementsByClassName("react-datepicker__month--selected")[0].innerText;

         if(jumonth==='Nov') ///to do
         {

          var etemp=parseInt(window.location.search.split('=')[1]);
          if(etemp!==11)
          {
            console.log("/************ redirect eleven xxxxxxx************");///to do 
          }


         }

          const d = new Date(selectedDates[0]);
          var xxxxxcyear= new Date(selectedDates[0]).getUTCFullYear();
          let month = d.getMonth() + 1;
          const da=new Date().getMonth();

          if(xxxxxcyear === undefined)
          {
            xxxxxcyear=  new Date().getFullYear();

          }
          if(parseInt(da)===parseInt(month))
          {
            console.log(month);
            
            console.log("/************month redirect /************");///to do 
          if(parseInt(xxxxxc)===11)
          {
            if(window.location.search!=="")
            {
              var etemp=parseInt(window.location.search.split('=')[1]);
              if(etemp!==11)
              {
                console.log("/************ redirect eleven************");///to do 
              }
            }
            console.log("/************eleven************");///to do 
           // window.location='competitorstats?month=' + da + '&year=' + xxxxxcyear;
          }
            // 
            console.log(da);

          }

         }catch{}
          
           var etemp=parseInt(window.location.search.split('=')[1]);
            if(xxxxxc!=etemp)
            {
                xxxxxc=etemp;
            }
            //xxxxxc=etemp;
            //window.location='competitorstats?month=' + window.location.search.split('=')[1];
          }
          //else //if(parseInt(window.location.search.split('=')[1]) != xxxxxc)
          {
            //xxxxxc=parseInt(window.location.search.split('=')[1]);
        //    window.location='competitorstats?month=' + xxxxxc;
          }

           var filtereddataxaj=filtereddataxaj.filter(function(mydataxi) {
            return mydataxi.month==xxxxxc;
             });


         
           filtereddataxaj.forEach((element, index) => {//second loop

          
           //console.log(element);
           //console.log("**** element month *****"); //selected date + 1
            var xxxxx= new Date(element.timestamp*1000).getUTCMonth();
            xxxxx= (parseInt(xxxxx)+1);
          
         
            //console.log(xxxxx); //selected date + 1
           // console.log("testing"); //selected date + 1
         
            //alert('testing');
          //if(new Date(element.timestamp*1000).getUTCMonth()=== new Date(selectedDates[0]).getUTCMonth())
          //if(element.month===xxxxxc) //***to do , this comparison works ******/ issue with the date
          {
            myTotal=myTotal + element.media_gross;  
            myRuns=myRuns + element.runs; 
            // alert('testing');
          }
          //else
          {
            ////****set to 0 instea ////***** */ */
            //filtereddataxaj.splice(index,1);
           // myTotal=1;//myTotal - element.media_gross;  
           // myRuns=1;//myRuns - element.runs; 
          }
         

          try{
          myCurrency= "";//element.currencies_id.name;
         //{ name: "CPC", key: 'cpc', appendText: myCurrency }
         /*
         tableHeadings[4]= { name: "CPM", key: 'cpm', appendText: myCurrency  };
         tableHeadings[6]= { name: "CPC", key: 'cpc', appendText: myCurrency  };
         tableHeadings[9]= {name: "CPA_FTD", key: 'cpa', appendText: myCurrency  };
         tableHeadings[11]= { name: "CPA_Registration", key: 'conv_2', appendText: myCurrency };
         tableHeadings[13]= {  name: "CPA_DC", key: 'concersion_3', appendText: myCurrency  };
         tableHeadings[14]= {  name: "Total Spend", key: 'total_spend', appendText: myCurrency   };
        */
         let elementMonth= new Date(element.timestamp*1000).getUTCMonth();
         elementMonth=elementMonth +1;

          let elementYear= new Date(element.timestamp*1000).getUTCFullYear();
         
       
        if( xjmonth===elementMonth && xjYear===elementYear)
        {

        {
       //
        {
            
              
               
        if(options2.length===2)
        {
            // charts[1].title="Digital Performance - " + monthNames[new Date(selectedDates[0]).getUTCMonth()] + " "+ new Date(selectedDates[0]).getFullYear()+ " - " + e.label;
        
        //  options2.unshif(i,e.value,e.label);//testing
        
        //options2.unshift({ index: 0, value: e.value, label: e.label });//test

          if(data[page][0].length>1)
         {

          for (let i = 0; i < 2; i++) 
          {
            ///test below code
var myselecti= document.getElementsByClassName("sidebar-item sidebar-item-active menu-digital"); 
 myselecti[0].click();
//  myselecti[0].click();
          }
       

         }
        
        
        }
                if(mytemp.includes(e.label))
                {

                 
                
                  if(new Date(element.timestamp*1000).getUTCMonth()!==xjmonth)
                  {
                   // alert(element.impressions);
                    data[page][0].splice(1,1);
                  }else  if(new Date(element.timestamp*1000).getUTCMonth()===xjmonth){
                  
                  
                }
                 }
            
              }
                
            }

          }else
          {

            //data[page][0].splice(index,1);

            
            if(elementMonth!==xjmonth)                   
            {       
            //  data[page][0].splice(index,1);
            }
            
            if(elementYear!==xjYear)                   
            {       
              //data[page][0].splice(index,1);
            }

            if(xjYear===2020 && xjmonth < 6)
            {
              if(elementYear!==xjYear)  
              {
                data[page][0].splice(index,1);
              }
              //

            }


          }
      }catch(merr)
      {
        alert(merr.message);

      }
    }//end second loop
  
);
  //  alert(totalcpm);//test
  let myEntity= [];
  myEntity.runs=myRuns;
  myEntity.total=myTotal;

  myEntity.name=e;
  mylist.push(myEntity);
  myRuns=0;
  myTotal=0;
  }  counter=counter+1;


});

//console.log(mylist);
for(var eee=0;eee<=mylist.length-1;eee++)
{
  if(!listAux.includes(mylist[eee].name))
  {


    listAux.push(mylist[eee].name);
    totalruns= parseInt(totalruns) + parseInt(mylist[eee].runs);
    totalspend=parseInt(totalspend) + parseInt(mylist[eee].total);

  }

}
console.log(mylist);
mylist.sort((a, b) => a.total < b.total);
for(var ee=0;ee<=mylist.length-1;ee++)
{
  
  var share=( parseInt(mylist[ee].total) / parseInt(totalspend)) * 100;
  if(!MylistAux.includes(mylist[ee].name))
  {
      if(parseInt(mylist[ee].runs)>0)
      {
        MylistAux.push(mylist[ee].name);
        listAux.push(mylist[ee].name);
        mycontent= mylist[ee].name ;
        myhtml=  <tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mylist[ee].name }</td><td>{mylist[ee].total.toLocaleString('it-IT').replace(".",",") } €</td><td>{share.toFixed(1)}</td><td>{mylist[ee].runs.toLocaleString('it-IT').replace(".",",") }</td></tr>;  
         mylistAmount.push(mylist[ee].total);
        listrows.push(myhtml);
      }else
      {////to do 
        myhtml=  <tr><td>{mylist[ee].name }</td><td>{mylist[ee].total.toLocaleString('it-IT').replace(".",",") } €</td><td>0</td><td>{mylist[ee].runs.toLocaleString('it-IT').replace(".",",") } XXXX</td></tr>;  
       // listrows.push(myhtml); //// fix this
      }
   
  }
}
console.log(listrows); //// ****** sort here ///// to do
if(listrows.length===0)
{
tableheaders=<tr>
  <th align='center'>
    <b>No data available</b>
    </th></tr>;
tablefooter="";
}else{

  tableheaders=  <tr><th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company</th><th>Gross Spending</th><th>Share of Advertising %</th><th>Runs</th></tr>;
  tablefooter=   <tr><th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total</th><th>{totalspend.toLocaleString('it-IT').replace(".",",")} €</th><th>100</th><th>{totalruns.toLocaleString('it-IT').replace(".",",")}</th></tr>;
}


mylistAmount.sort().reverse();

console.log(mylistAmount.sort((a, b) => a < b)); //// ****** sort here ///// to do

//alert('test');
try{
  var myt = document.querySelectorAll("table");	
  var footer = myt[xx].createTFoot();
  var row = footer.insertRow(0);    

// Insert a new cell (<td>) at the first position of the "new" <tr> element:
var cell = row.insertCell(0);
cell.outerHTML = "<th></th>";
cell = row.insertCell(1);
cell.outerHTML = "<th align='left'>Grand Total</th>";

}catch{}

if(totaltotal < 1)
{
data[page][0].splice(1,1);
totaltotal=0;
totalConv1=0;
totalConv2=0;
totalConv3=0;
try{
  document.getElementById("myexp").style.visibility= "visible" ;
  //document.getElementById("myexp2").style.visibility= "hidden" ;
// document.getElementById("resultTotals").style.visibility= "hidden" ;
}catch(err)
{
  /*let xjmonth=0;
  let xjYear=0;
  var juju = document.getElementsByClassName("example-custom-input-1")[0].value;
  var res = juju.split("/");
  xjmonth=res[0];
  xjYear=res[1];
  xjmonth=xjmonth-1; 
  const d = new Date(Date.UTC(xjYear, xjmonth));     // new Date(xjYear, xjmonth, 8, 1, 1, 1, 1);
  var frel= document.getElementsByClassName("example-custom-input-1")[0];*/
   
  //if(charts[1].title==="Digital Performance") change code below and on data filter ***to do ***
  {
    try{
      //document.getElementsByClassName("example-custom-input-1")[0].click();
    }catch(err)
    {}
  }
  
  
}

}else
{
try{
//document.body.style.zoom = "80%"; 
//var elements = document.querySelectorAll('.newSearchResultsList li:not([style*="display:none"]):not([style*="display: none"])');
var xx=0;
var myt = document.querySelectorAll("table");	
myt[xx].style.zoom = "90%";

//myt[0].setAttribute("zoom", "85%");

myt[xx].deleteTFoot();
var footer = myt[xx].createTFoot();
// Create an empty <tr> element and add it to the first position of <tfoot>:
var row = footer.insertRow(0);     


// Insert a new cell (<td>) at the first position of the "new" <tr> element:
var cell = row.insertCell(0);
cell.outerHTML = "<th></th>";
cell = row.insertCell(1);
cell.outerHTML = "<th align='left'>Grand Total</th>";
cell = row.insertCell(2);
cell.outerHTML = "<th align='right'>"+ parseInt(Math.round(100 * totalImpresions) / 100).toLocaleString('it-IT') + "</th>";

cell = row.insertCell(3);
cell.outerHTML = "<th align='right'>"+ ((totalImpresions/totalUniqueImpresions)).toLocaleString('it-IT',{minimumFractionDigits: 2,maximumFractionDigits: 2}) +"</th>";

cell = row.insertCell(4);
cell.outerHTML = "<th align='right'>"+ ((totaltotal/totalImpresions) * 1000).toFixed(2).toLocaleString('it-IT').replace(".",",") + " " + myCurrency + "</th>";

cell = row.insertCell(5);
cell.outerHTML = "<th align='right'>"+ parseInt(Math.round(100 * totalclicks) / 100).toLocaleString('it-IT').replace(",",".") +"</th>";

cell = row.insertCell(6);
cell.outerHTML = "<th align='right'> "+ (totaltotal/totalclicks).toFixed(2).toLocaleString('it-IT').replace(".",",")   + " "+ myCurrency + "</th>";

cell = row.insertCell(7);
cell.outerHTML = "<th align='right'>" + ((totalclicks/totalImpresions).toFixed(3) * 100).toFixed(2).replace(".",",")   +"%</th>";

cell = row.insertCell(8);
//cell.outerHTML = "<th align='right'>"+ parseInt(Math.round(100 * totalConv1) / 100)+"</th>";
//uncomment
cell.outerHTML = "<th align='right'>"+ parseInt(Math.round(100 * (totalConv1)) / 100).toLocaleString('it-IT')+"</th>";

//cell.outerHTML = "<th align='right'>"+ totalConv1+"</th>";


cell = row.insertCell(9);
if(totalConv1>1)
{
cell.outerHTML = "<th align='right'>"+ (totaltotal.toFixed(2)/(totalConv1)).toFixed(2).toLocaleString('it-IT').replace(".",",")+ " " + myCurrency +"</th>";
}else
{
cell.outerHTML = "<th align='right'>0" + myCurrency +"</th>";

}
cell = row.insertCell(10);
//cell.outerHTML = "<th align='right'>"+parseInt(Math.round(100 * (totalConv2)) / 100)+ "</th>";
cell.outerHTML = "<th align='right'>"+ parseInt(Math.round(100 * (totalConv2)) / 100).toLocaleString('it-IT')+"</th>";

cell = row.insertCell(11);
if(totalConv2>1)
{
cell.outerHTML = "<th align='right'>"+ (totaltotal.toFixed(2) / (totalConv2)).toFixed(2).toLocaleString('it-IT').replace(".",",") + " " + myCurrency+"</th>";//totaltotal.toFixed(2)) / (totalConv2)).toFixed(2) }&nbsp;{myCurrency
}else
{
cell.outerHTML = "<th align='right'>0"+ " " + myCurrency +"</th>";

}
cell = row.insertCell(12);
cell.outerHTML = "<th align='right'>"+ totalConv3.toLocaleString('it-IT')+"</th>";

cell = row.insertCell(13);
if(totalConv3>1)
{
   cell.outerHTML = "<th align='right'>"+ ((totaltotal.toFixed(2)) / (totalConv3)).toFixed(2).toLocaleString('it-IT').replace(".",",")+ " " + myCurrency+"</th>";
}else
{
cell.outerHTML = "<th align='right'>0"+ " " + myCurrency +"</th>";

}
cell = row.insertCell(14);//d.toLocaleString("fr-FR");
cell.outerHTML = "<th align='right'>"+ totaltotal.toLocaleString('it-IT') +" " + myCurrency+"</th>";

var htmlx = document.querySelector("table").outerHTML;
add_table_titles(htmlx);

//if(!element.cpm)
{
  //element.cpm=totalcpm;

}

//  document.getElementById("resultTotals").style.visibility= "hidden" ;
 document.getElementById("myexp").style.visibility= "visible" ;
 //document.getElementById("myexp2").style.visibility= "visible" ;
}
 catch(err)
  {
   // document.getElementById("react-select-2-input").tFoot.innerHTML)
     // alert(myt.length);///*** to do*****
      var mytt = document.querySelectorAll("table"); //// to do   move at the end of the process
      
      testO();
     
  }
}

//document.getElementsByClassName('react-datepicker')[0].style.visibility='hidden';
  }

  return { [page]: [mylist] };
};
            if(window.location.search!=="")
            {
              var etemp=window.location.search.split('&')[1].split('=')[1];
              monthCaption=etemp;
            }
var ctl=document.getElementsByClassName('react-datepicker__month-0')[0];
console.log(ctl);
console.log('ctl');
/* to do */
return (
  <div className="App digital">
     
    <div className={`${page}`} style={{ display: "flex", flexDirection: "column" }}>
      <div className="title-container">
        <h2 className="title overview-title">Competitor Statistics</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          
       


          
          <button id="myexp" style={{  visibility: 'visible'}}
           
           className="btn btn-white download-as-pdf"
           title="Export CSV data"
           onClick={testing}
           
         
          >
            <>
              CSV &nbsp;
               
                  <ArrowDown />
                
            </>
          </button> 
          

          

        </div>
       
      </div>
      <DataFilter charts={charts} page={page} source={source} classSuffix="1" onChange={data => {
        if (data[1] !== null) {
          setAnimate((animate) => animate + 1);
        }
//continue here ++++++
        const updatedDates = [...selectedDates];
        updatedDates["0"] = data;//JSON.stringify({ startDate: data[0], endDate: data[1] });
        setSelectedDates(updatedDates);
         const xdate=updatedDates;
         console.log(xdate);    
       
      }}>
        {filteredData => (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
             className="top-charts-container"
             // className="competitors-date-picker"
            >
              <div className="chart-container top-container">
               

                            <DataFilter data={addCalculatedFields(filteredData)} page={page}  autoSet={true} onChange={data => {
                             
                              setSource(data && data.value !== 0 ? data : null);
                              setAnimate(-1);
                              const updatedFilters = [...selectedFilters];
                              updatedFilters["3"] = data.index ? data.index : data.value;
                              setSelectedFilters(updatedFilters);
                           
                            }} className="data-filter">
                            
                                   {dataSource =>   
                                   <div id="mycontainer" className="chart-container table" style={{ borderRadius: '10px', height: '2px', visibility: 'hidden' }}>
                    <TableBlock
                      headings={tableHeadings}
                      title={charts[1].title}
                      key={0}
                      id={charts[1].id}
                      index={1}
                      chartType={charts[1].chartType}
                      width={"70%"}
                      sheet={charts[1].sheet}
                      data={filteredData}
                      animate={animate}
                      colors={charts[1].color}
                      page={page}
                      dataToPlot={data && data.value !== 0 ? { element: 'start_date', value: data } : null}
                      sortBy={'sources_id'}
                    />
                  
                  </div>}
                            </DataFilter>
                       


                           
                            <table  style={{
        backgroundColor: 'white'
      
      
      }} >
        <tr><th colSpan={4}><h3 class="chart-title table">Competitor's Performance - { monthNames[monthselection] } &nbsp;{monthCaption}</h3></th></tr>
    {tableheaders}
     {listrows[0]}
     {listrows[1]}
     {listrows[2]}
     {listrows[3]}
     {listrows[4]}
     {listrows[5]}
     {listrows[6]}
     {listrows[7]}
     {listrows[8]}
     {listrows[9]}
      {listrows[10]}

      {listrows[11]}
      {listrows[12]}
     {tablefooter}
     </table>

              </div>
            </div>
          </div> 
        )}
      </DataFilter>
    
     </div>
     
    
   
  </div>

);
};

function testO(){

///test below code
var myselect= document.getElementsByClassName("sidebar-item sidebar-item-active menu-digital");

var i;
//for(i=0;i<3;i++)
{
 myselect[0].click();
}



}
 

export default CompetitorStats;
