// @flow
import * as types from '../actions/types';
import * as interfaces from '../interfaces';

const initState: interfaces.IUserState = {
  user: 'not checked',
  spinner: false,
  error: null,
  errorMessage: null,
  errorMessageLogin: null,
  globalDate: null,
  globalDateRange: null,
};

const userReducer = (state = initState, action: interfaces.UserActionTypes): interfaces.IUserState => {
  switch (action.type) {
    case types.user.LOG_IN_USER:
    case types.user.LOG_OUT_USER:
    case types.user.REGISTER_USER:
    case types.user.FORGOTTEN_PASSWORD:
    case types.user.RESET_PASSWORD:
      return {
        ...state,
        spinner: true,
      };
    case types.user.REGISTER_SUCCEEDED:
      return {
        ...state,
        spinner: false,
        errorMessage: null,
        user: action.payload as interfaces.IUser,
      };
    case types.user.REGISTER_FAILED:
      return {
        ...state,
        spinner: false,
        error: action.payload as interfaces.IError,
        user: null,
      };
    case types.user.LOG_IN_SUCCEEDED:
      return {
        ...state,
        user: action.payload as interfaces.IUser,
        spinner: false,
      };
    case types.user.LOG_IN_FAILED:
      return {
        ...state,
        user: null,
        spinner: false,
        error: action.payload as interfaces.IError,
      };
    case types.user.LOG_OUT_SUCCEEDED:
      return {
        ...state,
        user: null,
        spinner: false,
      };
    case types.user.LOG_OUT_FAILED:
      return {
        ...state,
        spinner: false,
        error: action.payload as interfaces.IError,
      };
    case types.user.CHECK_AUTH_SUCCEEDED:
      return {
        ...state,
        user: action.payload as interfaces.IUser,
        spinner: false,
      };
    case types.user.CHECK_AUTH_FAILED:
      return {
        ...state,
        spinner: false,
        user: null,
        error: action.payload as interfaces.IError,
      };
    case types.user.FIRST_LOG_IN_SUCCEEDED:
      return {
        ...state,
        user: action.payload as interfaces.IUser,
        spinner: false,
      };
    case types.user.FORGOTTEN_PASSWORD_FAILED:
      return {
        ...state,
        spinner: false,
        errorMessage: (action.payload as interfaces.IError).message,
      };
    case types.user.FORGOTTEN_PASSWORD_SUCCEEDED:
      return {
        ...state,
        spinner: false,
        errorMessage: action.payload as string,
      };
    case types.user.RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        spinner: false,
        errorMessage: action.payload as string,
      };
    case types.user.RESET_PASSWORD_FAILED:
      return {
        ...state,
        spinner: false,
        errorMessage: (action.payload as interfaces.IError).message,
      };
    default:
      return state;
  }
};

export default userReducer;
