import React, { useState, useEffect, useCallback, useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { dataOptions } from "../data/dataOptions";
import PieChart from "./PieChart";

/**
 * WheelBlockContainer is a component where we are putting wheelChart components.
 *
 * @param {string} title  - The title of the chart (eg. Channel Mix, TimeZone Mix).
 * @param {Object} filter .
 * @param {Array<number>} filter.year - Year range (min and max is defined by the raw dataset).
 * @param {Array<string>} filter.month -  Min and Max is defined by the raw dataset (do not hard-code).
 *
 * @return {Object} Returns WheelChart components.
 */

const PieBlock = (props: any) => {
  const [filterData, setFilterData] = useState();
  const dataOptionFile: any = useMemo(() => {
    return { ...dataOptions };
  }, []);
  const [horizontal] = useState(true);
  const {
    width,
    height,
    id,
    sheet,
    title,
    description,
    descriptionAppend,
    data,
    dataToPlot,
    page,
    dataType,
    index,
    colors,
    toolTipAll,
    showTotal,
  } = props;
  const [total, setTotal] = useState(0);

  const formatData = useCallback(
    (data: any, type: any) => {
      let value = [];
      switch (type) {
        case "bo":
          if (dataToPlot) {
            if (data[dataToPlot]) {
              value.push(data[dataToPlot]);
            } else {
              value.push(0);
            }
          } else {
            if (data.net_budget) {
              value.push(data.net_budget);
            } else if (data.contacts_per_channel) {
              value.push(data.contacts_per_channel);
            } else if (data.visits_total) {
              value.push(data.visits_total);
            } else if (data.ndcs_medium) {
              value.push(data.ndcs_medium);
            } else {
              value.push(0);
            }
          }

          if (data.mediums_name) {
            value.push(data.mediums_name);
          } else {
            value.push(data.companies_name);
          }
          break;
        case "bpc":
          value.push(data.contacts_per_channel);
          value.push(data.channels_id.name);
          break;
        case "bpci":
          if (data.impressions_per_region) {
            value.push(data.impressions_per_region);
          } else if (data.contacts_per_region) {
            value.push(data.contacts_per_region);
          }

          value.push(data.regions_id.name);
          break;
        case "cm":
          if (dataToPlot) {
            if (data[dataToPlot]) {
              value.push(data[dataToPlot]);
            } else {
              value.push(0);
            }
          } else {
            if (data.gross_costs_in_euro) {
              value.push(data.gross_costs_in_euro);
            } else {
              value.push(0);
            }
          }

          if (data.channels_id) {
            value.push(data.channels_id.name);
          } else if (data.companies_id) {
            value.push(data.companies_id.name);
          }

          break;
        case "tm":
          if (dataToPlot) {
            if (data[dataToPlot]) {
              value.push(data[dataToPlot]);
            } else {
              value.push(0);
            }
          } else {
            if (data.gross_budget) {
              value.push(data.gross_budget);
            } else {
              value.push(0);
            }
          }

          if (data.time_slots_id) {
            value.push(data.time_slots_id.name);
          }
          break;
        case "t":
          if (dataToPlot) {
            if (data[dataToPlot]) {
              value.push(data[dataToPlot]);
            } else {
              value.push(0);
            }
          } else {
            if (data.net_budget) {
              value.push(data.net_budget);
            } else if (data.created_at) {
              value.push(data.created_at);
            } else if (data.updated_at) {
              value.push(data.updated_at);
            } else {
              value.push(0);
            }
          }

          if (data.time_slots_id) {
            value.push(data.time_slots_id.name);
          }
          break;
        case "c2":
          value.push(data.gross_budget);
          value.push(
            `${data.competitors_id.name}${
              data.channels_id ? "-" + data.channels_id.name : null
            }`
          );
          break;
        default:
          break;
      }
      return value;
    },
    [dataToPlot]
  );

  useEffect(() => {
    let formattedData: any = [];
    if (Object.keys(data).length !== 0 && data[page]) {
      let other = {
        name: "Longtail Channels",
        value: 0,
        fgColor: "#23244D",
        bgColor: colors[colors.length - 1],
        barColor: "#80e79d",
        holeDiameter: 0,
        showValuesInside: true,
        additionalData: [],
      };

      if (data[page][sheet - 1].length > 0) {
        data[page][sheet - 1]
          .sort((a: any, b: any) => (a[dataToPlot] > b[dataToPlot] ? -1 : 1))
          .forEach((item: any, index: number) => {
            if (!item.statusCode) {
              if (index < 10) {
                const value = formatData(item, dataType);
                let tempItem = {
                  name: value[1],
                  value: value[0],
                  fgColor: "#23244D",
                  bgColor: colors[index]
                    ? colors[index]
                    : colors[colors.length - 1],
                  barColor: "#80e79d",
                  holeDiameter: 0,
                  showValuesInside: true,
                  additionalData: item,
                };
                formattedData.push(tempItem);
                setTotal((total) => total + tempItem.value);
              } else {
                const value = formatData(item, dataType);
                other.value = other.value + value[0];
              }
            }
          });
        if (other.value > 0) {
          formattedData.push(other);
        }
        setTotal((total) => total + other.value);
      }
      setFilterData(formattedData);
    }
  }, [data, dataType, index, formatData, colors, dataToPlot, page, sheet]);

  let renderArray: any = [];

  if (filterData) {
    renderArray.push(
      <div
        key={index}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="atl-tv-top-container"
      >
        {(filterData as any).length > 1 ? (
          description && (
            <p className="chart-left-description">
              {description +
                " " +
                (showTotal ? Math.floor(total / 1000) : "") +
                " " +
                descriptionAppend}
            </p>
          )
        ) : (
          <div
            style={{
              height: height,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="atl-tv-top-container"
          >
            No data available
          </div>
        )}
        {(filterData as any).length > 1 && (
          <PieChart
            title={title}
            key={`PieChart${id}`}
            id={`PieChart${id}`}
            data={filterData}
            width={width}
            height={width / 4}
            options={dataOptionFile}
            vertical={horizontal}
            toolTipAll={toolTipAll}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <h3 className={"chart-title"}>{title}</h3>
      {data && renderArray}
    </div>
  );
};

export default PieBlock;
