import React, { useState } from "react";
import pencilIcon from "./../assets/icons/pencil.svg";

/**
 * Custom Form component.
 *
 * Description.
 *
 * @param {Object} title - Title.
 * @param {string} title.fieldName - The name of the field, eg. "Company name".
 * @param {string} title.fieldValue - Value, eg. "Microsoft".
 * @param {Array<Object>} elements
 * @param {string} elements[].label - eg. "Contact information".
 * @param {string} elements[].type - eg. "file", "hidden", or "input".
 * @param {string} elements[].name - eg. "email".
 * @param {Object} elements[].options - other specific form element options in the
 * format of key: value, eg. {cols: 5}.
 * @param {Array<number|string>} elements[].values - mostly an array of one element
 * eg. ["my@email.com"].
 *
 * @return Form component.
 */
const Form = (props) => {
  const { elements, title } = props;
  const [isUpdateActive, setIsUpdateActive] = useState(false);
  const onUpdateClick = () => {
    setIsUpdateActive(true);
  };
  const onSave = () => {
    setIsUpdateActive(false);
  };
  const onCancel = () => {
    setIsUpdateActive(false);
  };
  return (
    <div className="form-component">
      <div className="form-fields">
        {isUpdateActive ? (
          <div className="form-control margin-0 company-heading">
            <label htmlFor={title.fieldName}>Company name</label>
            <input className="company-name" value={title.fieldValue} />
          </div>
        ) : (
          <h2 className="company-name">{title.fieldValue}</h2>
        )}

        <div className="form-button-holder">
          {isUpdateActive ? (
            <>
              <button onClick={onCancel} className="cancel">
                Cancel
              </button>
              <button onClick={onSave} className="save">
                Save
              </button>
            </>
          ) : (
            <button
              onClick={onUpdateClick}
              style={{ display: "none" }}
              className="update-form-button"
            >
              <img src={pencilIcon} alt="icon" />
            </button>
          )}
        </div>
      </div>
      <div className="form-values">
        {elements.map((element, index) => {
          const { label, name, type, options } = element;
          const { placeholder, value } = options;
          return (
            <div key={index} className="form-row">
              <span>{label}</span>
              <div className="input-holder">
                {isUpdateActive && <label htmlFor={label}>{label}</label>}

                <input
                  type={type}
                  name={name}
                  placeholder={placeholder ? placeholder : ""}
                  value={value ? value : ""}
                  disabled={!isUpdateActive}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Form;
