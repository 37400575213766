import React from "react";

/**
 * Article component.
 *
 * @param {string} teaser - If present, display the teaser in a block with a bottom line,
 * below the title and above the rest of the txt, such as on design.
 *
 * @return Article component.
 */
const Article = (props: any) => {
  const teaser =
    props.teaser !== null ? (
      <div className="article-teaser">
        <h3>H3 - Teaser text header</h3>
        {props.teaser}
      </div>
    ) : null;
  return (
    <div className="article">
      <h1>H1 - Datenshutzerklarung</h1>
      <h2>H2 - Einleitung und allegemeine Angaben</h2>
      {teaser}
      {props.children}
    </div>
  );
};

export default Article;
