import * as types from './types';
import * as interfaces from '../interfaces';

export const firstLoginSucceeded = (params: interfaces.IUser): interfaces.UserActionTypes => {
  return {
    type: types.user.FIRST_LOG_IN_SUCCEEDED,
    payload: params,
  };
};

export const loginUser = (params: interfaces.IFormInput): interfaces.UserActionTypes => {
  return {
    type: types.user.LOG_IN_USER,
    payload: params,
  };
};

export const loginUserSucceeded = (params: interfaces.IUser): interfaces.UserActionTypes => {
  return {
    type: types.user.LOG_IN_SUCCEEDED,
    payload: params,
  };
};

export const loginUserFailed = (params: interfaces.IError): interfaces.UserActionTypes => {
  return {
    type: types.user.LOG_IN_FAILED,
    payload: params,
  };
};

export const logoutUser = (): interfaces.UserActionTypes => {
  return {
    type: types.user.LOG_OUT_USER,
    payload: null,
  };
};

export const logoutUserSucceeded = (): interfaces.UserActionTypes => {
  return {
    type: types.user.LOG_OUT_SUCCEEDED,
    payload: null,
  };
};

export const logoutUserFailed = (params: interfaces.IError): interfaces.UserActionTypes => {
  return {
    type: types.user.LOG_OUT_FAILED,
    payload: params,
  };
};

export const checkAuth = (): interfaces.UserActionTypes => {
  return {
    type: types.user.CHECK_AUTH,
    payload: null,
  };
};

export const checkAuthSucceeded = (params: interfaces.IUser): interfaces.UserActionTypes => {
  return {
    type: types.user.CHECK_AUTH_SUCCEEDED,
    payload: params,
  };
};

export const checkAuthFailed = (params: interfaces.IError): interfaces.UserActionTypes => {
  return {
    type: types.user.CHECK_AUTH_FAILED,
    payload: params,
  };
};

export const registerUser = (params: interfaces.IFormInput): interfaces.UserActionTypes => {
  return {
    type: types.user.REGISTER_USER,
    payload: params,
  };
};

export const registerUserSucceeded = (params: interfaces.IUser): interfaces.UserActionTypes => {
  return {
    type: types.user.REGISTER_SUCCEEDED,
    payload: params,
  };
};

export const registerUserFailed = (params: interfaces.IError): interfaces.UserActionTypes => {
  return {
    type: types.user.REGISTER_FAILED,
    payload: params,
  };
};

export const forgottenPassword = (params: interfaces.IFormInput): interfaces.UserActionTypes => {
  return {
    type: types.user.FORGOTTEN_PASSWORD,
    payload: params,
  };
};

export const forgottenPasswordSucceeded = (params: string): interfaces.UserActionTypes => {
  return {
    type: types.user.FORGOTTEN_PASSWORD_SUCCEEDED,
    payload: params,
  };
};

export const forgottenPasswordFailed = (params: interfaces.IError): interfaces.UserActionTypes => {
  return {
    type: types.user.FORGOTTEN_PASSWORD_FAILED,
    payload: params,
  };
};

export const resetPassword = (params: interfaces.IResetPassFormInput): interfaces.UserActionTypes => {
  return {
    type: types.user.RESET_PASSWORD,
    payload: params,
  };
};

export const resetPasswordSucceeded = (params: string): interfaces.UserActionTypes => {
  return {
    type: types.user.RESET_PASSWORD_SUCCEEDED,
    payload: params,
  };
};

export const resetPasswordFailed = (params: interfaces.IError): interfaces.UserActionTypes => {
  return {
    type: types.user.RESET_PASSWORD_FAILED,
    payload: params,
  };
};