export const dataOptions =
{
    "holeDiameter": 0.0,
    "showValuesInside": true,
    "toolTip": true,
    "legend": true,
    "title": "initial string",
    "fgThreshold": 7,
    "image": "https://www.polovniautomobili.com/user-images/agencies/37910/a9fe4a39d28e.jpg",
};

export const filterType = {
    'ads': ["ads", 2, true],
    'bo': ['budget-overviews', 4, true],
    'bpc': ['budget-per-channels', 1, false],
    'bpci': ['budget-per-contacts-impressions', 4, true],
    'cm': ['channel-mixes', 3, true],
    'c1': ['competitors-1-s', 2, true],
    'c2': ['competitor-2-s', 2, true],
    'dc': ['digital-campaigns', 1, false],
    'kd': ['kpi-dailies', 1, false],
    't': ['timezones', 2, true],
    'tm': ['timezone-mixes', 1, false],
    'cd': ['competitors-data', 1, false]
//competitors-data
    
};

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
