import React, { useEffect, useState, useCallback, useMemo } from "react";
import { filterType } from "../data/dataOptions";
import * as companyActions from "../actions/companyActions";
import { useSelector, useDispatch } from "react-redux";

const DataBlock = (props) => {

  const selectCompany = (state) => state.company;
  const { data } = useSelector(selectCompany);
  const {
    dataType,
    sheet,
    title,
    id,
    dataToPlot,
    animate,
    index,
    color,
    page
  } = props;
  const [/*isDataChanged*/, setDataChanged] = useState(animate);
  const [filterData, setFilterData] = useState();
  const filterTypeFile = useMemo(() => { return { ...filterType }; }, []);
  let d = new Date();
  const lastMonth = useMemo(() => { d.setMonth(d.getMonth() - 1); }, [d]);
  const dispatch = useDispatch();

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const formatData = useCallback(
    (data, type) => {
      let value = [];
      switch (type) {
        case "bo":
          if (dataToPlot) {
            if (data[dataToPlot]) {
              value.push(data[dataToPlot]);
            } else {
              value.push(0);
            }
          } else {
            if (data.net_budget) {
              value.push(data.net_budget);
            } else if (data.contacts_per_channel) {
              value.push(data.contacts_per_channel);
            } else if (data.visits_total) {
              value.push(data.visits_total);
            } else if (data.ndcs_medium) {
              value.push(data.ndcs_medium);
            } else {
              value.push(0);
            }
          }

          if (data.mediums_name) {
            value.push(data.mediums_name);
          } else {
            value.push(data.companies_name);
          }
          break;
        case "bpc":
          value.push(data.contacts_per_channel);
          value.push(data.channels_id.name);
          break;
        case "bpci":
          if (data.impressions_per_region) {
            value.push(data.impressions_per_region);
          } else if (data.contacts_per_region) {
            value.push(data.contacts_per_region);
          }

          value.push(data.regions_id.name);
          break;
        case "cm":
          value.push(data.net_costs);
          value.push(data.channels_id.name);
          break;
        case "c2":
          value.push(data.gross_budget);
          value.push(
            `${data.competitors_id.name}${data.channels_id ? "-" + data.channels_id.name : null
            }`
          );
          break;
        default:
          break;
      }
      return value;
    },
    [dataToPlot]
  );

  const getData = useCallback(
    (date, type) => {
      let requests = [];
      for (let i = 0; i < sheet.length; i++) {
        requests.push(
          `${filterTypeFile[type][0]}?${sheet ? `sheet=${sheet[i]}&` : ``
          }date=${date}`
        );
      }
      dispatch(companyActions.getCompanyData(requests, id));
    },
    [filterTypeFile, dispatch, id, sheet]
  );

  const formatDate = useCallback((date) => {
    if (date) {
      const month =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`;
      const year = date.getFullYear();
      return `${year}-${month}`;
    } else {
      const month =
        lastMonth.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`;
      const year = lastMonth.getFullYear();
      return `${year}-${month}`;
    }
  }, [lastMonth]);

  useEffect(() => {
    setDataChanged(animate);
  }, [animate]);

  useEffect(() => {
    if (!data) {
      getData(formatDate(new Date(lastMonth)), "bo");
    }
    let formattedData = [];
    if (Object.keys(data).length !== 0 && data[page]) {
      if (!data[page][sheet - 1].statusCode) {
        data[page][sheet - 1].forEach((item) => {
          if (!item.statusCode) {
            const value = formatData(item, dataType);
            let tempElem = {
              name: value[1],
              value: value[0],
              fgColor: "#000000",
              bgColor: getRandomColor(),
              barColor: color,
              holeDiameter: 0,
              showValuesInside: true,
              additionalData: item,
            };
            formattedData.push(tempElem);
          }
        });
      }

    }
    setFilterData([[formattedData]]);
  }, [data, color, dataType, index, formatData, formatDate, page, sheet, lastMonth, getData]);


  let listItems = [];
  if (filterData && filterData[0][0].length > 0) {
    filterData.map((chartData, i) => {
      let mapped = chartData.map((item, index) => {
        return { index: index, value: item };
      }); //reformat data into { index, value } format, where value is the dataset itself

      return listItems.push(
        mapped[0].value[0].additionalData.ndcs_total ? (
          <div className={`datablock-${id}`} style={{ display: 'flex', flexDirection: 'column', height: '100%' }} key={id}>
            <div>
              <h3 className='chart-title'>{title}</h3>
            </div>
            <div style={{ display: 'flex', height: '100%', justifyContent: 'flex-start', paddingLeft: '19px' }}>
              <div className='data-block-visits'>
                {mapped[0].value[0].additionalData.visits_total ? mapped[0].value[0].additionalData.visits_total.toLocaleString() : 0}
              </div>
              <div className='data-block-ndcs'>
                {mapped[0].value[0].additionalData.ndcs_total ? mapped[0].value[0].additionalData.ndcs_total.toLocaleString() : 0}
              </div>
            </div>
          </div>) : <div key={id} className={`datablock-${id}`}
            style={{
              width: "100px",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "10px"
            }}
          >
            No data available
          </div>
      );
    });
  }

  return <>{listItems}</>;
};

export default DataBlock;

