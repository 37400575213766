export class FirstLoginError extends Error {
    constructor(err: Error) {
      // Pass remaining arguments (including vendor specific ones) to parent constructor
      super(err.message)

      Object.setPrototypeOf(this, new.target.prototype);
      this.name = FirstLoginError.name;
      this.message = err.message
  
      // Maintains proper stack trace for where our error was thrown (only available on V8)
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, FirstLoginError)
      }
    }
}