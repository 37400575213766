import * as types from './types';
import * as interfaces from '../interfaces';

export const getCompany = (params: any): interfaces.CompanyActionTypes => {
  return {
    type: types.company.GET_COMPANY,
    payload: params,
  };
};

export const getCompanySucceeded = (params: interfaces.ICompany): interfaces.CompanyActionTypes => {
  return {
    type: types.company.GET_COMPANY_SUCCEEDED,
    payload: params,
  };
};

export const getCompanyFailed = (params: interfaces.IError): interfaces.CompanyActionTypes => {
  return {
    type: types.company.GET_COMPANY_FAILED,
    payload: params,
  };
};

export const getCompanyData = (entityNames: string[], id: any): interfaces.CompanyActionTypes => {
  return {
    type: types.company.GET_COMPANY_DATA,
    payload: {value: entityNames, id},
  };
};

export const getCompanyDataSucceeded = (params: any): interfaces.CompanyActionTypes => {
  return {
    type: types.company.GET_COMPANY_DATA_SUCCEEDED,
    payload: params,
  };
};

export const getCompanyDataFailed = (params: interfaces.IError): interfaces.CompanyActionTypes => {
  return {
    type: types.company.GET_COMPANY_DATA_FAILED,
    payload: params,
  };
};
