import React, { useEffect, useState } from "react";
import "./../../App.css";
import { useSelector, useDispatch } from "react-redux";
import * as systemActions from "../../actions/systemActions";
import { ReactComponent as ArrowDown } from "./../../assets/icons/arrow-down.svg";
import DataFilter from "../../components/DataFilter";
import BarBlock from "../../components/BarBlock";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../../constants/colors";

/**
 * A company profile page.
 *
 * The page displays all the thins of interest to the clients.
 * TODO: Name which things.
 *
 * @param props - For now just the user object
 */
const Print = () => {
  // State vars populated over redux.
  const selectCompany = (state) => state.company;
  const { data } = useSelector(selectCompany);
  const dispatch = useDispatch();
  const [animate, setAnimate] = useState(-2);
  const [init, setInit] = useState(true);
  const [noData, setNoData] = useState(false);
  const page = "print";

  const selectSystem = (state) => state.system;
  const { spinner } = useSelector(selectSystem);

  useEffect(() => {
    if (!init) {
      if (Object.keys(data).includes(v => v !== page).length > 0 && data[page] && data[page].filter((item) => item.length > 0).length > 0) {
        if (noData) {
          setNoData(false);
        }
        else {
          setAnimate((animate) => animate + 1);
        }
      }
      else {
        if (animate => animate !== -2) {
          setAnimate(0);
          setNoData(true);
        }
      }
    }
    else {
      if (Object.keys(data).length > 0 && data[page] && data[page][0].filter(item => item.impressions_per_region > 0 || item.net_budget > 0).length > 0) {
        setAnimate((animate) => animate + 1);
        setInit(false);
      }
    }
  }, [data, init, noData]);



  const charts = [
    {
      title: "Net Budget per Region in EURO",
      id: "nbpre",
      sheet: 4,
      chartType: "bar",
      dataToPlot: "net_budget",
      color: "#E84E1B",
      endpoint: "bpci",
      group: true
    },
    {
      title: "Impressions per Region",
      id: "cpr",
      sheet: 4,
      chartType: "bar",
      dataToPlot: "impressions_per_region",
      color: "#3DADD0",
      endpoint: "bpci",
      group: true
    }
  ];

  const initialStateOfFilters = [];
  initialStateOfFilters["0"] = "undefined";
  initialStateOfFilters["1"] = "undefined";

  const [selectedDates, setSelectedDates] = useState(initialStateOfFilters);

  const containerWidth = document.getElementsByClassName("container-content")[0]
    ? (document.getElementsByClassName("container-content")[0])
      .offsetWidth
    : 0;


  const printToPdf = async () => {
    function getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
    function getHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      );
    }
    const params = {
      pathname: "atl.print",
      selectedDates: selectedDates,
      selectedFilters: [],
      width: getWidth(),
      height: getHeight(),
    };
    dispatch(systemActions.printToPdf(params));
  };

  const calculateChartsWidth = () => {
    if (containerWidth < 420) {
      return containerWidth / 0.4;
    } else if (containerWidth < 450) {
      return containerWidth / 0.5;
    } else if (containerWidth < 576) {
      return containerWidth / 0.5;
    } else if (containerWidth < 669) {
      return containerWidth / 0.7;
    } else if (containerWidth < 768) {
      return containerWidth / 0.75;
    } else if (containerWidth < 860) {
      return containerWidth / 0.7;
    } else if (containerWidth < 953) {
      return containerWidth * 1.2;
    } else if (containerWidth < 1170) {
      return containerWidth * 1.1;
    } else if (containerWidth < 1300) {
      return containerWidth / 1.2;
    } else {
      return containerWidth / 1.3;
    }
  };

  const calculateChartsHeight = () => {
    if (containerWidth < 630) {
      return containerWidth / 2.5;
    } else if (containerWidth < 768) {
      return containerWidth / 3.1;
    } else if (containerWidth < 860) {
      return containerWidth / 3.5;
    } else if (containerWidth < 953) {
      return containerWidth / 3.9;
    } else if (containerWidth < 1170) {
      return containerWidth / 3.2;
    } else {
      return (containerWidth / 2) * 0.39;
    }
  };

  return (
    <div className="App overview digital-tv">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="title-container">
          <h2 className="title overview-title">ATL Print</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={printToPdf}
              className="btn btn-white download-as-pdf"
            >
              <>
                PDF &nbsp;
                {spinner ? (
                  <Spinner
                    radius={12}
                    color={TABLE_TEXT_COLOR}
                    stroke={2}
                    visible={true}
                  />
                ) : (
                    <ArrowDown />
                  )}
              </>
            </button>
          </div>
        </div>
        <DataFilter charts={charts} page={page} classSuffix="1" onChange={data => {
          setAnimate((animate) => animate + 1);

          const updatedDates = [...selectedDates];
          updatedDates["0"] = data;
          setSelectedDates(updatedDates);
        }}>
          {(filteredData) => (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "11px",
                }}
                className="top-charts-container"
              >
                <div className="chart-container top-container">
                  <BarBlock
                    containerId={`BarChart${charts[0].id}`}
                    title={charts[0].title}
                    data={filteredData}
                    key={0}
                    id={charts[0].id}
                    index={1}
                    chartType={charts[0].chartType}
                    height={calculateChartsHeight()}
                    width={calculateChartsWidth()}
                    dataType={"bpci"}
                    sheet={charts[0].sheet}
                    dataToPlot={charts[0].dataToPlot}
                    animate={animate}
                    color={charts[0].color}
                    page={page}
                    angleLabel={true}
                    wrapLabel={true}
                    useIndexForArrayAccess={true}
                  />
                </div>
                <div className="chart-container top-container">
                  <BarBlock
                    containerId={`BarChart${charts[1].id}`}
                    title={charts[1].title}
                    data={filteredData}
                    key={1}
                    id={charts[1].id}
                    index={1}
                    chartType={charts[1].chartType}
                    height={calculateChartsHeight()}
                    width={calculateChartsWidth()}
                    dataType={"bpci"}
                    sheet={charts[1].sheet}
                    dataToPlot={charts[1].dataToPlot}
                    animate={animate}
                    color={charts[1].color}
                    page={page}
                    angleLabel={true}
                    wrapLabel={true}
                    useIndexForArrayAccess={true}
                  />
                </div>
              </div>
            </div>
          )}
        </DataFilter>
      </div>
    </div>
  );
};
export default Print;
