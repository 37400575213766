import React, { useEffect, useState } from "react";
import "./../../App.css";
import { useSelector, useDispatch } from "react-redux";
import * as systemActions from "../../actions/systemActions";
import { ReactComponent as ArrowDown } from "./../../assets/icons/arrow-down.svg";
import DataFilter from "../../components/DataFilter";
import BarBlock from "../../components/BarBlock";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../../constants/colors";

const Timezone2 = () => {
    // State vars populated over redux.
    const selectCompany = (state) => state.company;
    const { data } = useSelector(selectCompany);
    const dispatch = useDispatch();
    const [init, setInit] = useState(true);
    const [animate, setAnimate] = useState(-2);
    const [noData, setNoData] = useState(false);
    const page = "timezone2";

    const selectSystem = (state) => state.system;
    const { spinner } = useSelector(selectSystem);

    useEffect(() => {
        if (!init) {
            if (
                Object.keys(data).length > 0 &&
                data[page] &&
                data[page].filter(item => item.length > 0).length > 0
            ) {
                if (noData) {
                    setNoData(false);
                } else {
                    setAnimate((animate) => animate + 1);
                }
            } else {
                if (animate => animate !== -2) {
                    setAnimate(-2);
                    setNoData(true);
                }
            }
        } else {
            if (Object.keys(data).length > 0 && data[page]) {
                setAnimate((animate) => animate + 1);
                setInit(false);
            }
        }
    }, [data, init, noData]);


    const charts = [
        {
            title: "GRP",
            id: "grp",
            sheet: 2,
            chartType: "bar",
            dataToPlot: "grp",
            color: "#E84E1B",
            endpoint: "t",
            group: true,
        },
        {
            title: "Gross Contacts",
            id: "gc",
            sheet: 2,
            chartType: "bar",
            dataToPlot: "gross_contacts",
            color: "#3DADD0",
            endpoint: "t",
            group: true,
        },
        {
            title: "CpGRP (net)",
            id: "cp_grp",
            sheet: 2,
            chartType: "bar",
            dataToPlot: "cpgrp_net",
            color: "#3DADD0",
            endpoint: "t",
            group: true,
        },
        {
            title: "Net CPM Target Group",
            id: "nctg",
            sheet: 2,
            chartType: "bar",
            dataToPlot: "net_cpm_target_group",
            color: "#3DADD0",
            endpoint: "t",
            group: true,
        },
        {
            title: "TV-To-Web-Conversion",
            id: "ttwc",
            sheet: 2,
            chartType: "bar",
            dataToPlot: "tv_to_web_conversion_simulated",
            color: "#3DADD0",
            endpoint: "t",
            group: true,
        },
        {
            title: "Cost per Visit (net)",
            id: "cpv",
            sheet: 2,
            chartType: "bar",
            dataToPlot: "cost_per_visit_net_simulated",
            color: "#3DADD0",
            endpoint: "t",
            group: true,
        },
        {
            title: "Visits",
            id: "v",
            sheet: 2,
            chartType: "bar",
            dataToPlot: "visits",
            color: "#3DADD0",
            endpoint: "t",
            group: true,
        },
    ];

    const initialStateOfFilters = [];
    initialStateOfFilters["0"] = "undefined";
    initialStateOfFilters["1"] = "undefined";

    const [selectedDates, setSelectedDates] = useState(initialStateOfFilters);

    const containerWidth = document.getElementsByClassName("container-content")[0]
        ? (document.getElementsByClassName("container-content")[0])
            .offsetWidth
        : 0;

    const printToPdf = async () => {
        function getWidth() {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );
        }
        function getHeight() {
            return Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.documentElement.clientHeight
            );
        }
        const params = {
            pathname: "atl.tv.timezone-2",
            selectedDates: selectedDates,
            selectedFilters: [],
            width: getWidth(),
            height: getHeight(),
        };
        dispatch(systemActions.printToPdf(params));
    };

    useEffect(() => {
        const datePicker = document.getElementsByClassName('timezone2-date-picker')[0];

        // Move date picker into title container
        const titleContainer = document.getElementsByClassName('title-container')[0];
        const pdfButton = document.getElementsByClassName('download-as-pdf')[0].parentElement;
        titleContainer.insertBefore(datePicker, pdfButton);
    }, [data]);

    const calculateChartsWidth = () => {
        if (containerWidth < 450) {
            return containerWidth * 1.8;
        } else if (containerWidth < 576) {
            return containerWidth * 1.25;
        } else if (containerWidth < 860) {
            return containerWidth / 1.04;
        } else if (containerWidth < 768) {
            return containerWidth * 1.2;
        } else if (containerWidth < 1100) {
            return containerWidth / 1.7;
        } else if (containerWidth < 1200) {
            return containerWidth / 1.5;
        } else {
            return containerWidth / 2;
        }
    };

    const calculateChartsHeight = () => {
        if (containerWidth < 630) {
            return containerWidth / 2.5;
        } else if (containerWidth < 870) {
            return containerWidth / 4.5;
        } else if (containerWidth < 953) {
            return containerWidth / 6.2;
        } else if (containerWidth < 1200) {
            return containerWidth / 6.2;
        } else {
            return (containerWidth / 2) * 0.39;
        }
    };

    return (
        <div className="App timezone2">
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="title-container">
                    <h2 className="title overview-title">Timezone 2</h2>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                            onClick={printToPdf}
                            className="btn btn-white download-as-pdf"
                        >
                            <>
                                PDF &nbsp;
                                {spinner ? (
                                    <Spinner
                                        radius={12}
                                        color={TABLE_TEXT_COLOR}
                                        stroke={2}
                                        visible={true}
                                    />
                                ) : (
                                        <ArrowDown />
                                    )}
                            </>
                        </button>
                    </div>
                </div>
                <DataFilter charts={charts} page={page} classSuffix="1" onChange={data => {
                    setAnimate((animate) => animate + 1);

                    const updatedDates = [...selectedDates];
                    updatedDates["0"] = data;
                    setSelectedDates(updatedDates);
                }}
                    className="timezone2-date-picker"
                >
                    {(filteredData) => (
                        <div>
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "auto auto auto",
                                    gridRowGap: "15px"
                                }}
                                className="top-charts-container charts-container-timezone2"
                            >
                                <div className="chart-container top-container">
                                    <BarBlock
                                        containerId={`BarChart${charts[0].id}`}
                                        title={charts[0].title}
                                        data={filteredData}
                                        key={0}
                                        id={charts[0].id}
                                        index={1}
                                        chartType={charts[0].chartType}
                                        height={calculateChartsHeight()}
                                        width={calculateChartsWidth()}
                                        dataType={"t"}
                                        sheet={charts[0].sheet}
                                        dataToPlot={charts[0].dataToPlot}
                                        animate={animate}
                                        color={charts[0].color}
                                        page={page}
                                        oddXAxesValuesLowerPosition={true}
                                        useIndexForArrayAccess={true}
                                    />
                                </div>
                                <div className="chart-container top-container">
                                    <BarBlock
                                        containerId={`BarChart${charts[1].id}`}
                                        title={charts[1].title}
                                        data={filteredData}
                                        key={1}
                                        id={charts[1].id}
                                        index={1}
                                        chartType={charts[1].chartType}
                                        height={calculateChartsHeight()}
                                        width={calculateChartsWidth()}
                                        dataType={"t"}
                                        sheet={charts[1].sheet}
                                        dataToPlot={charts[1].dataToPlot}
                                        animate={animate}
                                        color={charts[1].color}
                                        page={page}
                                        oddXAxesValuesLowerPosition={true}
                                        useIndexForArrayAccess={true}
                                    />
                                </div>
                                <div className="chart-container top-container">
                                    <BarBlock
                                        containerId={`BarChart${charts[2].id}`}
                                        title={charts[2].title}
                                        data={filteredData}
                                        key={2}
                                        id={charts[2].id}
                                        index={1}
                                        chartType={charts[2].chartType}
                                        height={calculateChartsHeight()}
                                        width={calculateChartsWidth()}
                                        dataType={"t"}
                                        sheet={charts[2].sheet}
                                        dataToPlot={charts[2].dataToPlot}
                                        animate={animate}
                                        color={charts[2].color}
                                        page={page}
                                        oddXAxesValuesLowerPosition={true}
                                        useIndexForArrayAccess={true}
                                    />
                                </div>


                                <div className="chart-container top-container">
                                    <BarBlock
                                        containerId={`BarChart${charts[3].id}`}
                                        title={charts[3].title}
                                        data={filteredData}
                                        key={3}
                                        id={charts[3].id}
                                        index={1}
                                        chartType={charts[3].chartType}
                                        height={calculateChartsHeight()}
                                        width={calculateChartsWidth()}
                                        dataType={"t"}
                                        sheet={charts[3].sheet}
                                        dataToPlot={charts[3].dataToPlot}
                                        animate={animate}
                                        color={charts[3].color}
                                        page={page}
                                        oddXAxesValuesLowerPosition={true}
                                        useIndexForArrayAccess={true}
                                    />
                                </div>
                                <div className="chart-container top-container">
                                    <BarBlock
                                        containerId={`BarChart${charts[4].id}`}
                                        title={charts[4].title}
                                        data={filteredData}
                                        key={4}
                                        id={charts[4].id}
                                        index={1}
                                        chartType={charts[1].chartType}
                                        height={calculateChartsHeight()}
                                        width={calculateChartsWidth()}
                                        dataType={"t"}
                                        sheet={charts[4].sheet}
                                        dataToPlot={charts[4].dataToPlot}
                                        animate={animate}
                                        color={charts[4].color}
                                        page={page}
                                        oddXAxesValuesLowerPosition={true}
                                        useIndexForArrayAccess={true}
                                    />
                                </div>
                                <div className="chart-container top-container">
                                    <BarBlock
                                        containerId={`BarChart${charts[5].id}`}
                                        title={charts[5].title}
                                        data={filteredData}
                                        key={5}
                                        id={charts[5].id}
                                        index={1}
                                        chartType={charts[5].chartType}
                                        height={calculateChartsHeight()}
                                        width={calculateChartsWidth()}
                                        dataType={"t"}
                                        sheet={charts[5].sheet}
                                        dataToPlot={charts[5].dataToPlot}
                                        animate={animate}
                                        color={charts[5].color}
                                        page={page}
                                        oddXAxesValuesLowerPosition={true}
                                        useIndexForArrayAccess={true}
                                    />
                                </div>



                                <div className="chart-container top-container">
                                    <BarBlock
                                        containerId={`BarChart${charts[6].id}`}
                                        title={charts[6].title}
                                        data={filteredData}
                                        key={7}
                                        id={charts[6].id}
                                        index={1}
                                        chartType={charts[6].chartType}
                                        height={calculateChartsHeight()}
                                        width={calculateChartsWidth()}
                                        dataType={"t"}
                                        sheet={charts[6].sheet}
                                        dataToPlot={charts[6].dataToPlot}
                                        animate={animate}
                                        color={charts[6].color}
                                        page={page}
                                        oddXAxesValuesLowerPosition={true}
                                        useIndexForArrayAccess={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </DataFilter>
            </div>
        </div>
    );
};
export default Timezone2;
