// @flow
import * as types from "../actions/types";
import * as interfaces from "../interfaces";

const initState: interfaces.IUserState = {
  user: null,
  spinner: false,
  error: null,
  errorMessage: null,
  errorMessageLogin: null,
  globalDate: null,
  globalDateRange: null,
};

const userReducer = (
  state = initState,
  action: interfaces.UserActionTypes
): interfaces.IUserState => {
  switch (action.type) {
    case types.system.PRINT_TO_PDF:
      return {
        ...state,
        spinner: true,
      };
    case types.system.PRINT_TO_PDF_SUCCEEDED:
      return {
        ...state,
        user: action.payload as interfaces.IUser,
        spinner: false,
      };
    case types.system.PRINT_TO_PDF_FAILED:
      return {
        ...state,
        user: null,
        spinner: false,
        error: action.payload as interfaces.IError,
      };
    case types.system.SET_DATE:
      return {
        ...state,
        globalDate: action.payload as interfaces.IUser,
      };
    case types.system.SET_DATE_RANGE:
      return {
        ...state,
        globalDateRange: action.payload as interfaces.IUser,
      };
    default:
      return state;
  }
};

export default userReducer;
