import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as systemActions from "../../actions/systemActions";
import DataFilter from "../../components/DataFilter";
import PieBlock from "../../components/PieBlock";
import TableBlock from "../../components/TableBlock";
import { ReactComponent as ArrowDown } from "./../../assets/icons/arrow-down.svg";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../../constants/colors";

const Timezone1 = () => {
  // State vars populated over redux.
  const selectCompany = (state) => state.company;
  const [animate, setAnimate] = useState(-1);
  const { data } = useSelector(selectCompany);

  const dispatch = useDispatch();

  useEffect(() => {
    setAnimate(animate => animate + 1);
  }, [data]);

  const page = "timezone1";

  const selectSystem = (state) => state.system;
  const { spinner } = useSelector(selectSystem);

  const firstChartColors =
    [
      "rgba(61, 173, 208, 0.65)",
      "rgba(61, 173, 208, 0.4)",
      "rgba(61, 173, 208, 0.3)",
      "rgba(61, 173, 208, 0.2)",
      "rgba(191, 208, 0, 0.65)",
      "rgba(191, 208, 0, 0.4)",
      "rgba(191, 208, 0, 0.3)",
      "rgba(191, 208, 0, 0.2)",
      "rgba(191, 208, 0, 0.15)",
      "rgba(191, 208, 0, 0.10)",
      "rgba(191, 208, 0, 0.05)",
    ];

  const charts = [
    {
      title: "Net Budget",
      //description: "TV Total:",
      //descriptionAppend: "k EURO",
      id: "nb",
      sheet: 1,
      chartType: "pie",
      dataType: "t",
      dataToPlot: "net_budget",
      colors: firstChartColors,
      toolTipAll: false,
      endpoint: "t",
      group: true,
    },
    {
      title: "Time Zone Report",
      id: "once",
      sheet: 1,
      chartType: "table",
      //dataToPlot: "contacts_per_channel",
      color: "#3DADD0",
      endpoint: "t",
    },
  ];

  const tableHeadings = [
    { name: "year/month" },
    { name: "Time Zone", bar: "rgba(191, 208, 0, 1)", key: 'time_slots_id:name' },
    { name: "CpGRP", bar: "rgba(191, 208, 0, 0.6)", key: 'cpgrp_net', numberOfDecimals: 2 },
    { name: "Gross Budget", bar: "rgba(191, 208, 0, 0.5)", key: 'gross_budget', numberOfDecimals: 2 },
    { name: "GRP", bar: "rgba(129, 202, 224, 1)", key: 'grp', numberOfDecimals: 2 },
    { name: "Net CpM Target Group", bar: "rgba(129, 202, 224, 0.6)", key: 'net_cpm_target_group', numberOfDecimals: 2 },
    { name: "TV-to-Web Conversion", bar: "rgba(129, 202, 224, 1)", key: 'tv_to_web_conversion_simulated', numberOfDecimals: 2 },
    { name: "Cost per Visit (net) Simulated", bar: "rgba(210, 190, 220, 1)", key: 'cost_per_visit_net_simulated', numberOfDecimals: 2 },
    { name: "Visits", bar: "rgba(210, 190, 220, 1)", key: 'visits' }
  ];

  console.log("DATA", data);

  const options = [
    { value: 0, label: 'Select...' },
    { value: 1, label: '03:00-06:00' },
    { value: 2, label: '06:00-09:00' },
    { value: 3, label: '09:00-13:00' },
    { value: 4, label: '13:00-17:00' },
    { value: 5, label: '17:00-20:00' },
    { value: 6, label: '20:00-23:00' },
    { value: 7, label: '23:00-01:00' },
    { value: 8, label: '01:00-03:00' },
  ];


  const initialStateOfFilters = [];
  initialStateOfFilters["0"] = "undefined";
  initialStateOfFilters["1"] = "undefined";

  const [selectedDates, setSelectedDates] = useState(initialStateOfFilters);
  const [selectedFilters, setSelectedFilters] = useState(initialStateOfFilters);

  const containerWidth = document.getElementsByClassName("container-content")[0]
    ? (document.getElementsByClassName("container-content")[0])
      .offsetWidth
    : 0;

  const printToPdf = async () => {
    function getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
    function getHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      );
    }
    const params = {
      pathname: "atl.tv.timezone-1",
      selectedDates: selectedDates,
      selectedFilters: selectedFilters,
      width: getWidth(),
      height: getHeight(),
    };
    dispatch(systemActions.printToPdf(params));
  };

  const calculateChartsWidth = () => {
    if (containerWidth < 450) {
      return containerWidth * 1.8;
    } else if (containerWidth < 576) {
      return containerWidth * 1.25;
    } else if (containerWidth < 860) {
      return containerWidth / 1.04;
    } else if (containerWidth < 768) {
      return containerWidth * 1.2;
    } else if (containerWidth < 1030) {
      return containerWidth / 1.4;
    } else {
      return containerWidth / 2;
    }
  };

  const calculateChartsHeight = () => {
    if (containerWidth < 953) {
      return containerWidth * 3.5;
    } else {
      return containerWidth / 3;
    }
  };

  return (
    <div className="App atl-tv timezone">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="title-container">
          <h2 className="title overview-title">Timezone 1</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={printToPdf}
              className="btn btn-white download-as-pdf"
            >
              <>
                PDF &nbsp;
                {spinner ? (
                  <Spinner
                    radius={12}
                    color={TABLE_TEXT_COLOR}
                    stroke={2}
                    visible={true}
                  />
                ) : (
                    <ArrowDown />
                  )}
              </>
            </button>
          </div>
        </div>

        <DataFilter charts={charts} page={page} classSuffix="1" onChange={data => {
          const updatedDates = [...selectedDates];
          updatedDates["0"] = data;
          setSelectedDates(updatedDates);
        }}>
          {(filteredData, date) => (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                className="top-charts-container"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: '10px',
                  }}
                  className="top-charts-container top-charts-container-atl "
                >
                  <div className="chart-container chart-container-atl" style={{ borderRadius: '10px' }}>
                    <PieBlock
                      containerId={`PieChart${charts[0].id}`}
                      title={charts[0].title}
                      description={charts[0].description}
                      descriptionAppend={charts[0].descriptionAppend}
                      data={filteredData}
                      key={0}
                      id={charts[0].id}
                      index={1}
                      chartType={charts[0].chartType}
                      height={data.length > 0 ? calculateChartsHeight() : "90px"}
                      width={calculateChartsWidth()}
                      sheet={charts[0].sheet}
                      dataType={charts[0].dataType}
                      dataToPlot={charts[0].dataToPlot}
                      animate={animate}
                      colors={charts[0].colors}
                      toolTipAll={charts[0].toolTipAll}
                      page={page}
                      useIndexForArrayAccess={true}
                    />
                  </div>
                </div>

                <div className="chart-container table" style={{ marginTop: '10px', borderRadius: '10px' }}>
                  <DataFilter data={filteredData} page={page} options={options} onChange={data => {
                    const updatedFilters = [...selectedFilters];
                    updatedFilters["0"] = data.value;
                    setSelectedFilters(updatedFilters);
                  }}
                    className="data-filter">
                    {(data) => <TableBlock
                      headings={tableHeadings}
                      title={charts[1].title}
                      key={1}
                      id={charts[1].id}
                      index={2}
                      chartType={charts[1].chartType}
                      //height={(containerWidth / 2) * 0.39}
                      width={"100%"}
                      sheet={charts[1].sheet}
                      //dataToPlot={"contacts_per_channel"}
                      data={filteredData}
                      date={date}
                      animate={animate}
                      color={charts[1].color}
                      page={page}
                      dataToPlot={data && data.value !== 0 ? { element: 'time_slots_id:name', value: data } : null}
                      useIndexForArrayAccess={true}
                    />}
                  </DataFilter>
                </div>
              </div>
            </div>
          )}
        </DataFilter>
      </div>
    </div >
  );
};

export default Timezone1;
