// @flow
import * as types from '../actions/types';
import * as interfaces from '../interfaces';

const initState: interfaces.ICompanyState = {
  company: null,
  spinner: false,
  error: null,
  errorMessage: null,
  errorMessageLogin: null,
  data: {},
  isDataChanged: -1,
};

const companyReducer = (state = initState, action: interfaces.CompanyActionTypes): interfaces.ICompanyState => {
  switch (action.type) {
    case types.company.GET_COMPANY:
      return {
        ...state,
        spinner: true,
      };
    case types.company.GET_COMPANY_SUCCEEDED:
      return {
        ...state,
        spinner: false,
        errorMessage: null,
        company: action.payload as interfaces.ICompany,
      };
    case types.company.GET_COMPANY_FAILED:
      return {
        ...state,
        spinner: false,
        error: action.payload as interfaces.IError,
        company: null,
      };
    case types.company.GET_COMPANY_DATA:
      return {
        ...state,
      };
    case types.company.GET_COMPANY_DATA_SUCCEEDED:
      return {
        ...state,
        errorMessage: null,
        data: {...state.data as any, [action.payload.id]: action.payload.response},
        id: action.payload.id as string,
        isDataChanged: state.isDataChanged as any + 1,
      };
    case types.company.GET_COMPANY_DATA_FAILED:
      return {
        ...state,
        spinner: false,
        error: action.payload as interfaces.IError,
      };
    default:
      return state;
  }
};

export default companyReducer;
