import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import * as types from '../actions/types';
import * as userActions from '../actions/userActions';
import {FirstLoginError} from './errorClasses';
import {IError, UserActionTypes, IFormInput, IResetPassFormInput, IUser} from '../interfaces';

const API_URL = process.env.REACT_APP_API_URL;

const headerDefaults = {
  Accept: "application/json",
  'Content-Type': 'application/json'
};

// Change firstLogin to false
const firstLoginApi = (params: IUser) => {
  const url = API_URL + "/users/" + params.id;
  const headers = {
    method: 'PUT',
    headers: headerDefaults,
    body: JSON.stringify({firstLogin: false}),
  };
  return fetch(url, headers).then(response => {
    if (response.status === 500) {
      throw response.statusText;
    }
    return response.json()
  }).catch(e => {throw new FirstLoginError(e)});
};

// Log in User
const loginUserApi = (params: IFormInput) => {
  const url = API_URL + "/auth/local";
  const headers = {
    method: 'POST',
    headers: headerDefaults,
    body: JSON.stringify(params),
  };
  return fetch(url, headers).then(response => {
    if (response.status === 500) {
      throw response.statusText;
    }
    return response.json()
  });
};

/* Test with
// const gen = fetchUsers();
// test('should hit api', () => {
//   const param = 1;
//   expect(gen.next(param).value).toEqual(call(api, 'http...'))
// }) */
function* loginUser(action: UserActionTypes): SagaIterator {
  let response;
  try {
    response = yield call(loginUserApi, action.payload as IFormInput);
    if (typeof response.error !== "undefined") {
      throw response;
    }
    let { from, history } = action.payload as IFormInput;
    if (history && from) {
      history.replace(from);
    }
    if (response.firstLogin == null || response.firstLogin) {
      const response2 = yield call(firstLoginApi, response as IUser);
      if (typeof response2.error !== "undefined") {
        throw new FirstLoginError(response2);
      }
    }
    yield put(userActions.loginUserSucceeded(response));
  } catch (e) {
    if(e instanceof FirstLoginError) {
        // handle InvalidArgumentError
      yield put(userActions.loginUserSucceeded(response));
    }
    else {
      const err: IError = e;
      yield put(userActions.loginUserFailed(err));
    }
  }
}

export function* loginUserWatcher(): SagaIterator {
  yield takeLatest(types.user.LOG_IN_USER, loginUser);
}

// Log out User
const logoutUserApi = () => {
  const url = API_URL + "/users/logout";
  const headers = {
    headers: {
      'Content-Type': 'application/json'
    },
  };
  return fetch(url, headers).then(response => {
    if (response.status === 500) {
      throw response.statusText;
    }
    return response.json()
  });
};

function* logoutUser(): SagaIterator {
  try {
    const response = yield call(logoutUserApi);
    if (typeof response.error !== "undefined") {
      throw response;
    }
    yield put(userActions.logoutUserSucceeded());
  } catch (e) {
    const err: IError = e;
    yield put(userActions.logoutUserFailed(err));
  }
}

export function* logoutUserWatcher(): SagaIterator {
  yield takeLatest(types.user.LOG_OUT_USER, logoutUser);
}

// Check auth
function checkAuthApi() {
  const url = API_URL + "/users/me";
  const headers = {
    headers: {
      'Content-Type': 'application/json'
    },
  };
  return fetch(url, headers).then(response => {
    if (response.status === 500) {
      throw response.statusText;
    }
    return response.json()
  });
}

function* checkAuth(): SagaIterator {
  try {
    const response = yield call(checkAuthApi);
    if (typeof response.error !== "undefined") {
      throw response;
    }
    yield put(userActions.checkAuthSucceeded(response));
  } catch (e) {
    const err: IError = e;
    yield put(userActions.checkAuthFailed(err));
  }
}

export function* checkAuthWatcher(): SagaIterator {
  yield takeLatest(types.user.CHECK_AUTH, checkAuth);
}

const registerUserApi = (params: IFormInput) => {
  const url = API_URL + "/auth/local/register";
  const headers = {
    method: 'POST',
    headers: headerDefaults,
    body: JSON.stringify(params),
  };
  return fetch(url, headers).then(response => {
    if (response.status === 500) {
      throw response.statusText;
    }
    return response.json()
  });
};

function* registerUser(action: UserActionTypes): SagaIterator {
  try {
    const response = yield call(registerUserApi, action.payload as IFormInput);
    if (typeof response.error !== "undefined") {
      throw response;
    }
    yield put(userActions.registerUserSucceeded(response));
  } catch (e) {
    const err: IError = e;
    yield put(userActions.registerUserFailed(err));
  }
}

export function* registerUserWatcher(): SagaIterator {
  yield takeLatest(types.user.REGISTER_USER, registerUser);
}

const forgottenPasswordApi = (params: IFormInput) => {
  const url = API_URL + "/auth/forgot-password";
  const headers = {
    method: 'POST',
    headers: headerDefaults,
    body: JSON.stringify(params),
  };
  return fetch(url, headers).then(response => {
    if (response.status === 500) {
      throw response.statusText;
    }
     return response.json();
  });
};

function* forgottenPassword(action: UserActionTypes): SagaIterator {
  try {
    const response = yield call(forgottenPasswordApi, action.payload as IFormInput);
    if (typeof response.error !== "undefined") {
      throw response;
    }
    yield put(
      userActions.forgottenPasswordSucceeded(
        'Password reset email has been sent. Please check inbox and spam folder.',
      ),
    );
  } catch (e) {
    yield put(userActions.forgottenPasswordFailed(e));
  }
}

export function* forgottenPasswordWatcher(): SagaIterator {
  yield takeLatest(types.user.FORGOTTEN_PASSWORD, forgottenPassword);
}

const resetPasswordApi = (params: IResetPassFormInput) => {
  const url = API_URL + "/auth/reset-password";
  const headers = {
    method: 'POST',
    headers: headerDefaults,
    body: JSON.stringify(params),
  };
  return fetch(url, headers).then(response => {
    if (response.status === 500) {
      throw response.statusText;
    }
    return response.json()
  });
};

function* resetPassword(action: UserActionTypes): SagaIterator {
  try {
    const response = yield call(resetPasswordApi, action.payload as IResetPassFormInput);
    if (typeof response.error !== "undefined") {
      throw response;
    }
    yield put(
      userActions.resetPasswordSucceeded(
        'Your password has been changed.',
      ),
    );
  } catch (e) {
    yield put(userActions.resetPasswordFailed(e));
  }
}

export function* resetPasswordWatcher(): SagaIterator {
  yield takeLatest(types.user.RESET_PASSWORD, resetPassword);
}
