import React, { useEffect } from "react";
import { connect, useSelector, useDispatch, ConnectedProps } from "react-redux";
import "./App.css";
import ProfilePage from "./pages/ProfilePage";
import RegisterOrLogin from "./pages/RegisterOrLogin";
import LostPassword from "./pages/LostPassword";
import ResetPassword from "./pages/ResetPassword";
import TV from "./pages/ATL/TV";
import OoH from "./pages/ATL/OoH";
import Print from "./pages/ATL/Print";
import Radio from "./pages/ATL/Radio";
import Digital from "./pages/Digital";


import CompetitorStats from "./pages/CompetitorStats";
import HeatMaps from "./pages/HeatMaps";
import AtlAndDigital from "./pages/AtlAndDigital";
import CompetitorsAnalysis from "./pages/CompetitorsAnalysis";
import Timezone1 from "./pages/ATL/Timezone1";
import Competitors1 from "./pages/ATL/Competitors1";
import Layout from "./layouts/Layouts";
import * as userActions from "./actions/userActions";
import * as companyActions from "./actions/companyActions";
import { IUserState, IUser } from "./interfaces";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Page from "./pages/Page";
import Timezone2 from "./pages/ATL/Timezone2";
import Competitors2 from "./pages/ATL/Competitors2";
import Programmatic from "./pages/reports/Programmatic";
import Channels1 from "./pages/ATL/Channels1";
import Channels2 from "./pages/ATL/Channels2";
import NewEntryAlert from "./pages/NewEntryAlert";
import DigitalTV from "./pages/ATL/DigitalTV";
import Overview from "./pages/Overview";

/**
 * The main component.
 *
 * @param props Some default stuff we don't need right now.
 */
const App = (props: Props) => {
  const selectUser = (state: IUserState) => state.user;
  const { user } = (useSelector(selectUser) as unknown) as IUserState;
  const dispatch = useDispatch();
  /**
   * Checks if the user is authenticated.
   */
  useEffect(() => {
    dispatch(userActions.checkAuth());
  }, [dispatch]);

  useEffect(() => {
    if (user && typeof user === "object") {
      const id =
        typeof user.company === "object" ? user.company.id : user.company;
      dispatch(companyActions.getCompany(id));
    }
  }, [user, dispatch]);

  // console.log((useSelector(selectUser) as unknown) as IUserState);

  return (
    <div
      className={`container ${
        !user || user === "not checked" ? "container-login-register-page" : ""
      }`}
    >
      <Router>
        <Layout />
        <div className="container-content">
          {
            <Switch>
              <Route exact path="/password">
                <LostPassword />
              </Route>
              <Route exact path="/reset-password">
                <ResetPassword />
              </Route>
              <Route exact path="/page">
                <Page />
              </Route>

              <PrivateRoute exact path="/profile" user={user}>
                {user && typeof user === "object" && (
                  <ProfilePage user={user} />
                )}
              </PrivateRoute>

              <PrivateRoute exact path="/atl/tv" user={user} component={TV} />
              <PrivateRoute exact path="/atl/ooh" user={user} component={OoH} />
              <PrivateRoute
                exact
                path="/atl/print"
                user={user}
                component={Print}
              />
              <PrivateRoute
                exact
                path="/atl/radio"
                user={user}
                component={Radio}
              />
              <PrivateRoute
                exact
                path="/atl/tv/channels-1"
                user={user}
                component={Channels1}
              />
              <PrivateRoute
                exact
                path="/atl/tv/channels-2"
                user={user}
                component={Channels2}
              />
              <PrivateRoute
                exact
                path="/digital"
                user={user}
                component={Digital}
              />
              <PrivateRoute
                exact
                path="/atl-and-digital"
                user={user}
                component={AtlAndDigital}
              />
              <PrivateRoute
                exact
                path="/competitors-analysis"
                user={user}
                component={CompetitorsAnalysis}
              />

            <PrivateRoute
                exact
                path="/HeatMaps"
                user={user}
                component={HeatMaps}
              />


            <PrivateRoute
                exact
                path="/CompetitorStats"
                user={user}
                component={CompetitorStats}
              />



              <PrivateRoute
                exact
                path="/atl/tv/timezone-1"
                user={user}
                component={Timezone1}
              />
              <PrivateRoute
                exact
                path="/atl/tv/timezone-2"
                user={user}
                component={Timezone2}
              />
              <PrivateRoute
                exact
                path="/atl/tv/competitors-1"
                user={user}
                component={Competitors1}
              />

              <PrivateRoute
                exact
                path="/atl/tv/competitors-2"
                user={user}
                component={Competitors2}
              />

              <PrivateRoute
                exact
                path="/report/programmatic"
                user={user}
                component={Programmatic}
              />

              <PrivateRoute
                exact
                path="/atl/digital-tv"
                user={user}
                component={DigitalTV}
              />

              <PrivateRoute
                exact
                path="/competitive-intelligence-tv/new-entry-alert"
                user={user}
                component={NewEntryAlert}
              />

              <PrivateRoute user={user} exact path="/" />
              <PrivateRoute
                exact
                path="/overview"
                user={user}
                component={Overview}
              />
            </Switch>
          }
          {/* {!userValue &&
            <Switch>
              <PrivateRoute user={userValue} exact path="/" />
            </Switch>
          } */}
        </div>
      </Router>
    </div>
  );
};
type PRProps = {
  user: IUser | null | string;
  component?: React.FC;
  path: string;
  exact: boolean;
};
/**
 * A custom component that redirects based on some criteria.
 *
 * If we are on the HP: Redirect to /profile if user is authenticated, else stay.
 * Else: the other way around.
 *
 * @param user - The user object
 * @param path - The path (eg. /profile)
 * @returns Either render <RegisterOrLogin>|<ProfilePage> or redirect to their routs.
 */
const PrivateRoute: React.FC<PRProps> = ({
  path,
  exact,
  user,
  component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (location.pathname === "/") {
          return user && typeof user === "object" ? (
            <Redirect
              to={{
                pathname: "/overview",
                state: { from: location },
              }}
            />
          ) : (
            <RegisterOrLogin />
          );
        }
        return user ? (
          <Route {...rest} path={path} exact={exact} component={component} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

const mapDispatchToProps = {
  //actions
};
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;
export default connector(App);
