import {applyMiddleware, combineReducers, createStore} from 'redux';
// import thunk from 'redux-thunk';
// import promise from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';
import userReducer from './reducers/userReducer';
import companyReducer from './reducers/companyReducer';
import systemReducer from './reducers/systemReducer';
import rootWatcher from './sagas';

const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
  user: userReducer,
  company: companyReducer,
  system: systemReducer,
});

const middleware = applyMiddleware(
  // thunk,
  // promise,
  sagaMiddleware,
);

const store = createStore(reducers, middleware);
const myThing:any = rootWatcher;
sagaMiddleware.run(myThing);

store.subscribe(() => {
  // store.getState();
});

export default store;
