export const user = {
    LOG_IN_USER: 'LOG_IN_USER',
    LOG_IN_SUCCEEDED: 'LOG_IN_SUCCEEDED',
    LOG_IN_FAILED: 'LOG_IN_FAILED',
    LOG_OUT_USER: 'LOG_OUT_USER',
    LOG_OUT_SUCCEEDED: 'LOG_OUT_SUCCEEDED',
    LOG_OUT_FAILED: 'LOG_OUT_FAILED',
    CHECK_AUTH: 'CHECK_AUTH',
    CHECK_AUTH_SUCCEEDED: 'CHECK_AUTH_SUCCEEDED',
    CHECK_AUTH_FAILED: 'CHECK_AUTH_FAILED',
    REGISTER_USER: 'REGISTER_USER',
    REGISTER_SUCCEEDED: 'REGISTER_SUCCEEDED',
    REGISTER_FAILED: 'REGISTER_FAILED',
    FORGOTTEN_PASSWORD: 'FORGOTTEN_PASSWORD',
    FORGOTTEN_PASSWORD_SUCCEEDED: 'FORGOTTEN_PASSWORD_SUCCEEDED',
    FORGOTTEN_PASSWORD_FAILED: 'FORGOTTEN_PASSWORD_FAILED',
    FIRST_LOG_IN_SUCCEEDED: 'FIRST_LOG_IN_SUCCEEDED',
    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCEEDED: 'RESET_PASSWORD_SUCCEEDED',
    RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED'
};
export const company = {
    GET_COMPANY: 'GET_COMPANY',
    GET_COMPANY_SUCCEEDED: 'GET_COMPANY_SUCCEEDED',
    GET_COMPANY_FAILED: 'GET_COMPANY_FAILED',
    GET_COMPANY_DATA: 'GET_COMPANY_DATA',
    GET_COMPANY_DATA_SUCCEEDED: 'GET_COMPANY_DATA_SUCCEEDED',
    GET_COMPANY_DATA_FAILED: 'GET_COMPANY_DATA_FAILED',
};
export const system = {
    PRINT_TO_PDF: 'PRINT_TO_PDF',
    PRINT_TO_PDF_SUCCEEDED: 'PRINT_TO_PDF_SUCCEEDED',
    PRINT_TO_PDF_FAILED: 'PRINT_TO_PDF_FAILED',
    SET_DATE: 'SET_DATE',
    SET_DATE_RANGE: 'SET_DATE_RANGE',
};
