import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { months } from "../../data/dataOptions";
import * as systemActions from "../../actions/systemActions";
import DataFilter from "../../components/DataFilter";
import TableBlock from "../../components/TableBlock";
import { ReactComponent as ArrowDown } from "./../../assets/icons/arrow-down.svg";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../../constants/colors";

const Competitors2 = () => {
    // State vars populated over redux.
    const selectCompany = (state) => state.company;
    const [animate, setAnimate] = useState(-1);
    const { data } = useSelector(selectCompany);

    const dispatch = useDispatch();

    useEffect(() => {
        setAnimate(animate => animate + 1);
    }, [data]);

    const page = "competitors2";

    const selectSystem = (state) => state.system;
    const { spinner } = useSelector(selectSystem);

    const charts = [
        {
            title: `Competitors Channel`,
            id: "cc",
            sheet: 1,
            chartType: "table",
            //dataToPlot: "contacts_per_channel",
            endpoint: "c2",
            group: true,
        },
        {
            title: `Competitors Time-Zone`,
            id: "ctz",
            sheet: 2,
            chartType: "table",
            //dataToPlot: "contacts_per_channel",
            endpoint: "c2",
            group: true
        },
    ];

    const tableHeadings1 = [
        { name: "Year/Month", format: 'year' },
        { name: "Competitor", key: 'competitors_id:name' },
        { name: "Channel", key: 'channels_id:name' },
        { name: "Cost per GRP  Gross", key: 'cost_per_grp_gross' },
        { name: "CPM (Gross)", key: 'cpm_gross' },
        { name: "Gross Budget", key: 'gross_budget' },
        { name: "Gross Contacts", key: 'gross_contacts' },
        { name: "GRP (Gross)", key: 'grp_gross' },
        { name: "OTS", key: 'ots' }
    ];

    const tableHeadings2 = [
        { name: "Year/Month", format: 'year' },
        { name: "Competitor", key: 'competitors_id:name' },
        { name: "Time-Zone", key: 'time_slots_id:name' },
        { name: "Cost per GRP  Gross", key: 'cost_per_grp_gross' },
        { name: "CPM (Gross)", key: 'cpm_gross' },
        { name: "Gross Budget", key: 'gross_budget' },
        { name: "Gross Contacts", key: 'gross_contacts' },
        { name: "GRP (Gross)", key: 'grp_gross' },
        { name: "Net Reach", key: 'net_reach' },
        { name: "OTS", key: 'ots' }
    ];

    let options = [];
    if (data[page]) {
        if (data[page][0]) {
            let index = 1;
            data[page][0].map((item) => {
                if (options.filter(option => option.label === item.competitors_id.name).length === 0) {
                    return options.push({ value: index++, label: item.competitors_id.name });
                }
                return undefined;
            });
            options.unshift({ value: 0, label: 'Select...' });
        }
    }

    const initialStateOfFilters = [];
    initialStateOfFilters["0"] = "undefined";

    const [selectedDates, setSelectedDates] = useState(initialStateOfFilters);
    const [selectedFilters, setSelectedFilters] = useState(initialStateOfFilters);

    const printToPdf = async () => {
        function getWidth() {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );
        }
        function getHeight() {
            return Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.documentElement.clientHeight
            );
        }
        const params = {
            pathname: "atl.tv.competitors-2",
            selectedDates: selectedDates,
            selectedFilters: selectedFilters,
            width: getWidth(),
            height: getHeight(),
        };
        dispatch(systemActions.printToPdf(params));
    };

    return (
        <div className="App atl competitors-1">
            <div className='page-content-container'>
                <div className="title-container">
                    <h2 className="title overview-title">Competitors 2</h2>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                            onClick={printToPdf}
                            className="btn btn-white download-as-pdf"
                        >
                            <>
                                PDF &nbsp;
                            {spinner ? (
                                    <Spinner
                                        radius={12}
                                        color={TABLE_TEXT_COLOR}
                                        stroke={2}
                                        visible={true}
                                    />
                                ) : (
                                        <ArrowDown />
                                    )}
                            </>
                        </button>
                    </div>
                </div>

                <DataFilter charts={charts} page={page} classSuffix="1" onChange={data => {
                    const updatedDates = [...selectedDates];
                    updatedDates["0"] = data;
                    setSelectedDates(updatedDates);
                }} className="competitors-date-picker">
                    {(data, date) => (
                        <DataFilter data={data} page={page} options={options} onChange={data => {
                            const updatedFilters = [...selectedFilters];
                            updatedFilters["0"] = data.value;
                            setSelectedFilters(updatedFilters);
                        }}
                            className="data-filter data-filter-competitors">
                            {(filter) => (
                                <div className='charts-wrapper'>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                        className="top-charts-container"
                                    >


                                        <div className="chart-container table" style={{ borderRadius: '10px' }}>
                                            <TableBlock
                                                headings={tableHeadings1}
                                                title={charts[0].title}
                                                key={0}
                                                id={charts[0].id}
                                                index={1}
                                                chartType={charts[0].chartType}
                                                //height={(containerWidth / 2) * 0.39}
                                                width={"100%"}
                                                sheet={charts[0].sheet}
                                                //dataToPlot={"contacts_per_channel"}
                                                data={data}
                                                date={months[new Date(date).getMonth()].substr(0, 3) + ", " + new Date(date).getFullYear().toString()}
                                                animate={animate}
                                                colors={charts[0].color}
                                                page={page}
                                                dataToPlot={filter && filter.value !== 0 ? { element: 'competitors_id:name', value: filter } : null}
                                            />
                                        </div>


                                        <div className="chart-container table" style={{ borderRadius: '10px' }}>
                                            <TableBlock
                                                headings={tableHeadings2}
                                                title={charts[1].title}
                                                key={1}
                                                id={charts[1].id}
                                                index={2}
                                                chartType={charts[1].chartType}
                                                //height={(containerWidth / 2) * 0.39}
                                                width={"100%"}
                                                sheet={charts[1].sheet}
                                                //dataToPlot={"contacts_per_channel"}
                                                data={data}
                                                date={months[new Date(date).getMonth()].substr(0, 3) + ", " + new Date(date).getFullYear().toString()}
                                                animate={animate}
                                                color={charts[1].color}
                                                page={page}
                                                dataToPlot={filter && filter.value !== 0 ? { element: 'competitors_id:name', value: filter } : null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </DataFilter>
                    )}
                </DataFilter>
            </div>
        </div >
    );
};

export default Competitors2;
