import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Spinner from "react-spinner-material";
import * as qs from "query-string";
import * as userActions from "../actions/userActions";
import { IUserState, IResetPassFormInput } from "../interfaces";
import "../App.css";

/**
 * Reset password component.
 *
 * The component lets the user change his/her password, but only if they had
 * arrived to the page over a special URL which the LostPassword component
 * should have emailed them.
 */
const ResetPassword = () => {
  // Parse the URL query parameters.
  const parsed = qs.parse(window.location.search);

  // react-hook-form handles form validation.
  const { handleSubmit, register, getValues, errors } = useForm<
    IResetPassFormInput
  >();

  // State vars populated over redux.
  const selectUser = (state: IUserState) => state.user;
  const { errorMessage, spinner } = (useSelector(
    selectUser
  ) as unknown) as IUserState;

  // Local component state.
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const dispatch = useDispatch();

  /**
   * Text input handler. Sets changed text to component state.
   *
   * @param e - Input field on change event.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    name === "password" ? setPassword(value) : setPasswordConfirmation(value);
  };

  /**
   * Form submit handler.
   *
   * Gathers form data and dispatches resetPassword action to redux
   * the result of which is either a filled 'errorMessage' variable here or null.
   *
   * @param d - Data from the form.
   */
  const onSubmit = async (d: IResetPassFormInput) => {
    const data: IResetPassFormInput = {
      ...d,
      code: parsed.code as string,
    };
    dispatch(userActions.resetPassword(data));
  };

  // @see react-hook-form docs
  const sub = handleSubmit(onSubmit);

  return (
    <div className="App">
      App
      <div className="ResetPassword">
        <h1>Reset password</h1>
        <form onSubmit={sub}>
          {spinner ? (
            <Spinner radius={120} color={"#333"} stroke={2} visible={true} />
          ) : errorMessage !== "" ? (
            <div className="error">{errorMessage}</div>
          ) : (
            ""
          )}
          <div>
            <label htmlFor="password">New password</label>
            <input
              name="password"
              id="password"
              type="password"
              value={password}
              placeholder="New password"
              onChange={handleChange}
              ref={register({
                required: "Required",
                minLength: {
                  value: 8,
                  message:
                    "The password length should be minimum 8 characters.",
                },
              })}
            />
            {errors.password && (
              <span className="error">{errors.password.message}</span>
            )}
          </div>
          <div>
            <label htmlFor="passwordConfirmation">New password (again)</label>
            <input
              name="passwordConfirmation"
              id="passwordConfirmation"
              type="password"
              value={passwordConfirmation}
              placeholder="New password (again)"
              onChange={handleChange}
              ref={register({
                required: "Required",
                minLength: {
                  value: 8,
                  message:
                    "The password length should be minimum 8 characters.",
                },
                validate: (value) => value === getValues("password"),
              })}
            />
            {errors.passwordConfirmation && (
              <span className="error">
                {errors.passwordConfirmation.message}
              </span>
            )}
            {errors.passwordConfirmation &&
              errors.passwordConfirmation.type === "validate" && (
                <span className="error">The passwords must match.</span>
              )}
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
