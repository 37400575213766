// @flow
import {all} from 'redux-saga/effects';
import * as user from './userSagas';
import * as company from './companySagas';
import * as system from './systemSagas';

/**
 * RootWatcher. Runs all the other watchers in parallel.
 */
export default function* rootWatcher() {
  yield all([
    user.checkAuthWatcher(),
    user.loginUserWatcher(),
    user.logoutUserWatcher(),
    user.registerUserWatcher(),
    user.forgottenPasswordWatcher(),
    user.resetPasswordWatcher(),
    company.getCompanyWatcher(),
    company.getCompanyDataWatcher(),
    system.printToPdfWatcher(),
  ]);
}
