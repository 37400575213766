import { useEffect, useCallback } from "react";

const useHover = (ref, callback) => {
  const handleMouseOver = useCallback(() => {
    callback(true);
  }, [callback]);
  const handleMouseOut = useCallback(() => {
    callback(false);
  }, [callback]);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref, handleMouseOut, handleMouseOver] // Recall only if ref changes
  );

  return [ref];
};

export default useHover;
