import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as systemActions from "../actions/systemActions";
import DataFilter from "../components/DataFilter";
import TableBlock from "../components/TableBlock";
import { ReactComponent as ArrowDown } from "./../assets/icons/arrow-down.svg";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../constants/colors";




const HeatMaps = () => {

  return  <iframe src="https://tapp.cnqr-group.com/DE_regions.html" width="100%" height="100%"> </iframe>;

};

export default HeatMaps;