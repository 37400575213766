import { call, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import * as types from "../actions/types";
import * as companyActions from "../actions/companyActions";
import { IError, CompanyActionTypes } from "../interfaces";

const API_URL = process.env.REACT_APP_API_URL;

const headerDefaults = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

/**
 * Fetch company details.
 *
 * @param params - Company id.
 */
function getCompanyDataApi(params: string[]) {
  const url = API_URL + "/company-data";
  const headers = {
    method: "POST",
    headers: headerDefaults,
    body: JSON.stringify(params),
  };
  return fetch(url, headers).then((response) => {
    if (response.status === 500) {
      throw response.statusText;
    }
    return response.json();
  });
}

/**
 * Saga iterator for getting one company details.
 *
 * Dispatches a getCompanySucceeded or getCompanyFailed action depending on fetch result.
 *
 * @param action - An action object.
 */
function* getCompanyData(action: CompanyActionTypes): SagaIterator {
  try {
    const response = yield call(
      getCompanyDataApi,
      action.payload.value as string[]
    );
    if (typeof response.error !== "undefined") {
      throw response;
    }
    yield put(
      companyActions.getCompanyDataSucceeded({
        response: response,
        id: action.payload.id,
      })
    );
  } catch (e) {
    const err: IError = e;
    yield put(companyActions.getCompanyDataFailed(err));
  }
}

export function* getCompanyDataWatcher(): SagaIterator {
  yield takeLatest(types.company.GET_COMPANY_DATA, getCompanyData);
}

/**
 * Fetch company details.
 *
 * @param params - Company id.
 */
function getCompanyApi(params: number) {
  const url = API_URL + "/companies/" + params;
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(url, headers).then((response) => {
    if (response.status === 500) {
      throw response.statusText;
    }
    return response.json();
  });
}

/**
 * Saga iterator for getting one company details.
 *
 * Dispatches a getCompanySucceeded or getCompanyFailed action depending on fetch result.
 *
 * @param action - An action object.
 */
function* getCompany(action: CompanyActionTypes): SagaIterator {
  try {
    const response = yield call(getCompanyApi, action.payload as number);
    if (typeof response.error !== "undefined") {
      throw response;
    }
    yield put(companyActions.getCompanySucceeded(response));
  } catch (e) {
    const err: IError = e;
    yield put(companyActions.getCompanyFailed(err));
  }
}

export function* getCompanyWatcher(): SagaIterator {
  yield takeLatest(types.company.GET_COMPANY, getCompany);
}
