import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Spinner from "react-spinner-material";
import * as userActions from "../actions/userActions";
import { IUserState, IFormInput } from "../interfaces";
import "../App.css";
import { useHistory } from "react-router-dom";
import { ReactComponent as LeftArrow } from "./../assets/icons/left-arrow.svg";
/**
 * Lost password component.
 *
 * The component lets the user start the change password process, by providing
 * the registered email address to which a resetPassword link is sent.
 */
const LostPassword = () => {
  // react-hook-form handles form validation.
  const { handleSubmit, register, errors } = useForm<IFormInput>();
  // State vars populated over redux.
  const selectUser = (state: IUserState) => state.user;
  const { errorMessage, spinner } = (useSelector(
    selectUser
  ) as unknown) as IUserState;
  // Local component state.
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  /**
   * Text input handler. Sets changed text to component state.
   *
   * @param e - Input field on change event.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setEmail(value);
  };
  /**
   * Form submit handler.
   *
   * Gathers form data and dispatches forgottenPassword action to redux
   * the result of which is either a filled 'errorMessage' variable here or null.
   *
   * @param d - Data from the form.
   */
  const onSubmit = async (d: IFormInput) => {
    const data: IFormInput = {
      ...d,
      email,
    };
    dispatch(userActions.forgottenPassword(data));
  };
  // @see react-hook-form docs
  const sub = handleSubmit(onSubmit);
  const history = useHistory();
  const goToPreviousLocation = () => {
    history.goBack();
  };
  return (
    <div className="App">
      <div className="cnqr-logo"></div>
      <div className="form-container">
        <h1 className="form-header">Recover Password</h1>
        <form onSubmit={sub}>
          {spinner ? (
            <Spinner radius={120} color={"#333"} stroke={2} visible={true} />
          ) : errorMessage !== "" ? (
            <div className="error">{errorMessage}</div>
          ) : (
            ""
          )}
          <div className="form-control">
            <label htmlFor="email">Email</label>
            <input
              name="email"
              id="email"
              value={email}
              placeholder="example@example.com"
              onChange={handleChange}
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address.",
                },
              })}
            />
            {errors.email && (
              <span className="error">{errors.email.message}</span>
            )}
          </div>
          <button type="submit" className="btn">
            Recover password
          </button>
          <div
            onClick={goToPreviousLocation}
            className="cursor-pointer text-green margin-top link"
          >
            <span className="icon-with-text">
              <LeftArrow /> Go back
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};
export default LostPassword;
