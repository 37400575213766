import React, { useEffect, useState } from "react";
import * as d3 from "d3";
// import { all } from "redux-saga/effects";
/**
 * A custom BarChart element.
 *
 * Depends out of the properties how many bars should it have. To call component
 * it needs to have the property options where it's decided what is shown, and
 * what is not(e.g if want to hide default properties, like legend).
 *
 * @param {Array<Array<Object>>} data - Array array.
 * @param {number} data[][].value  - Bar value (eg . 13).
 * @param {string} data[][].label - Bar label (eg . "DMAX").
 * @param {string} data[][].textColor - - RGB value (eg. #FFFFFF) - If left out, the
 * textColor will be the previous element's text color.
 * @param {number} maxBarValue - Maximum value of Bar height, got from data,
 * that is used in scaling the Bar height.
 * @param {boolean} isDataChanged - Returns the property from the BarBlock,
 * should the data be changed.
 * @param {Object} options -  Gets the options as property from BarBlock.
 * @param {string} options.title - The title of the graph (eg. Title.png - The
 * tab - If no title, then don't show the tab).
 * @param {boolean} options.fg - show/hide the numbers in the bar - default hide.
 * @param {string} options.bgColor - RGB value (eg. #CCCCCC) - Default white.
 * @param {boolean} options.legend - show/hide legend - default show.
 * @param {boolean} options.axes -  show/hide axes - default show.
 * @param {boolean} options.xLines - show/hide xLines - default hide.
 * @param {boolean} options.yLines - show/hide yLines - default hide.
 * @param {boolean} options.angleLabel - Show the labels with angle on xAxis. Default
 * false.
 * @param {Array<Object>} options.legend -
 * @param {Object} options.legend[] - Gets the legend property from BarBlock.
 * @param {string} options.legend[].label - Label that is shown in legend.
 * @param {string} options.legend[].color - eg. #FFCC00.
 * @param {number} width - Sets the width of the chart.
 * @param {number} height - Sets the height of the chart.
 * @param {string} id - It is used as unique class name of the svg.
 * Chart that should be rendered.
 * @returns {JSX.Function(any)} Renders <BarChart> component.
 * @public
 */
const BarChart = (props) => {
  const margin = { top: 30, right: 0, bottom: 30, left: 40 };
  const width = props.width;
  const height = props.height;
  const [allData, setAllData] = useState();
  const [prevData, setPrevData] = useState();
  const options = props.options;
  const legend = options.legend;
  const [numberOfBarGroups, setNumberOfBarGroups] = useState(1);
  const [isDataChanged, setDataChanged] = useState(props.isDataChanged);
  const id = props.containerId;
  const { oddXAxesValuesLowerPosition } = props;
  const { isLine } = props.options;
  const { metric } = props;
  let max = 0;
  if (allData && prevData) {
    max = allData.length > prevData.length ? allData.length : prevData.length; // length of larger dataset
  }

  let barChart = [];
  let lineChart = [];
  let { maxBarValue } = props;

  const formatTooltipValue = (value) => Number(value).toLocaleString("it-IT");

  var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
  root.setAttribute("class", "light");

  const getMaxValue = (data) => {
    if (data) {
      let max = Math.max.apply(
        Math,
        data.value.map((item) => {
          return item.value;
        })
      );
      return max;
    }
    return 0;
  };

  function wrap(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text
          .text()
          .match(/[^\s-]+-?/g)
          ?.reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy + "em");
      while ((word = words?.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width && line.length > 1) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }

  useEffect(() => {
    setDataChanged(props.isDataChanged);
  }, [props.isDataChanged]);

  const getGranulation = (value) => {
    let granulation = 0;
    const quotient = value / 1000000000;

    if (quotient > 1) {
      granulation = 1000000000;
    } else if (quotient > 0.001) {
      granulation = 1000000;
    } else if (quotient > 0.000001) {
      granulation = 1000;
    } else {
      granulation = 1;
    }
    return granulation;
  };

  const padding = (length) => (length < 6 ? 0.4 : 0.5);

  const adjustColor = (color, percent) => {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR =
      R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
    var GG =
      G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
    var BB =
      B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
  };

  const roundToDecimal = (number) => {
    let dec = 1;
    // for (let i = 10; number * i >= 1; i*10) {
    //   dec = i * 10;
    // }
    if (number > 0) {
      while (number * dec < 1) {
        dec = dec * 10;
      }
      let result = Math.round(number * dec) / dec;
      return result;
    }
    return number;
  };

  // designate which datasets are bar and which are line charts
  for (var i = 0; i < max; i++) {
    if (isLine.indexOf(i) > -1) {
      lineChart.push(i);
     
    } else {
      barChart.push(i);
    }
  }

  /**
   * Returns the number of Bar groups that is going to be rendered.
   */
  if (allData !== undefined && numberOfBarGroups !== allData.length) {
    setNumberOfBarGroups(allData.length);
    
  }
  

  useEffect(() => {
    setDataChanged(props.isDataChanged);
    setPrevData(allData);
    setAllData(props.data);
    if (allData !== undefined) {
      setNumberOfBarGroups(allData.length);
    }
  }, [props.isDataChanged, props.data, allData, props.maxBarValue]);

  const check =
    props.isDataChanged && props.isDataChanged === isDataChanged
      ? isDataChanged
      : props.isDataChanged;
  const checkData = props.data && props.data === allData ? allData : props.data;
  /**
   * Function to get the width of Bar.
   */

  if (checkData) {
    const granulation = getGranulation(maxBarValue);
    const domain =
      maxBarValue > 1
        ? Math.ceil((maxBarValue * 1.2) / granulation)
        : maxBarValue;
    const x = d3
      .scaleBand()
      .domain(d3.range(checkData["0"].value.length))
      .range([margin.left, width - margin.right])
      .paddingInner(padding(max))
      .paddingOuter(checkData["0"].value.length >= 6 ? 1.5 : 2.9);
    const y = d3
      .scaleLinear()
      .domain([0, domain])
      .nice()
      .range([height - margin.bottom, margin.top]);

    let y2 = null;
    if (checkData.length > 1) {
      const mv = getMaxValue(checkData[1]);
      const gv = getGranulation(mv);
      const d = mv > 1 ? Math.ceil((mv * 1.2) / gv) : mv;
      y2 = d3
        .scaleLinear()
        .domain([0, d])
        .nice()
        .range([height - margin.bottom, margin.top]);
    }

    /**
     * Function to draw the X Axis.
     */
    const len = checkData["0"].value.length >= 6 ? 3 : 0.9;
    const xAxis = (g) =>
      g
        .attr(
          "transform",
          `translate(${
            -x(0) +
            x(0) / 1.5 +
            (options.angleLabel ? 1 : 0) +
            x.bandwidth() / len
          },${
            height - margin.bottom + index * 5 - (options.angleLabel ? 20 : 0)
          })`
        )
        .call(
          d3
            .axisBottom(x)
            .tickFormat((i) => checkData["0"].value[i].name)
            .tickSizeOuter(0)
        );
    /**
     *Function to make the YLines.
     */

    const makeYLines = () => d3.axisLeft().scale(y);
    const makeXLines = () => d3.axisBottom().scale(x);
    const svg = d3
      .select(`.barblock-${id} .${props.id} svg`)
      .attr("viewBox", [
        0,
        margin.bottom + 10,
        width + margin.left + margin.right,
        height + margin.top + margin.bottom,
      ])
      .attr("height", `${height}px`)
      .attr("width", `${width}px`)
      .attr("preserveAspectRatio", "none")
      .style("overflow", "unset");

    d3.selectAll(`.barblock-${id} .${props.id} p`).remove();

    for (var index = 0; index < max; index++) {
      // eslint-disable-next-line no-unused-vars
      let chart = null;
      if (check === 0) {
        d3.selectAll(`.barblock-${id} .${props.id} .chart-${index}`).remove();
//here*****
        chart = svg
          .append("g")
          .attr("class", `chart chart-${index}`)
          .attr("id", `${index}`)
          .attr("transform", `translate(${margin.left - 8}, ${margin.top})`);
      } else {
        chart = svg.selectAll(
          `.barblock-${id} .${props.id} chart chart-${index}`
        );

        if (index > prevData.length - 1) {//here******
          chart = svg
            .append("g")
            .attr("class", `chart chart-${index}`)
            .attr("id", `${index}`)
            .attr("transform", `translate(${margin.left}, ${margin.top})`);
        }

        d3.selectAll(`.barblock-${id} .${props.id} g.chart`)
          .filter((item, index) =>
            checkData.length > prevData.length
              ? index > checkData.length - 1
              : index > checkData.length - 1
          )
          .remove();
      }
    }

    if (
      options.legend !== null &&
      options.legend !== undefined &&
      options.legend !== false &&
      options.legend !== ""
    ) {
      d3.selectAll(`.barblock-${id} .${props.id} .chart .legend-bar`).remove();
      d3.selectAll(`.barblock-${id} .${props.id} .chart .legend-text`).remove();
      checkData
        .filter((d, i) => typeof legend[i] !== "undefined")
        .forEach((item, index) => {
          d3.selectAll(`.barblock-${id} .${props.id} .chart:first-child`)
            .append("rect")
            .attr("class", "legend-bar")
            .attr("x", 80 + index * 70)
            .attr("y", 5)
            .attr("height", 15)
            .attr("width", 25)
            .style("fill", () => checkData[index].value[0].barColor);
          d3.selectAll(`.barblock-${id} .${props.id} .chart:first-child`)
            .append("text")
            .attr("class", "legend-text")
            .attr("x", 110 + index * 70)
            .attr("y", 15)
            .text(legend[index].label)
            .style("font-size", "14px")
            .attr("alignment-baseline", "middle");
        });
    }

    if (options.axes === true) {
      const ticksAmount = 5;
      const tickStep = maxBarValue / granulation / ticksAmount;
      let step =
        Math.ceil(tickStep / 5) > 1
          ? Math.ceil(tickStep / 5) * 5
          : maxBarValue < 1
          ? Math.ceil((tickStep / 5) * 10) / 10
          : (Math.ceil((tickStep / 5) * 10) / 10) * 5;
      let temp = 1;
      if (tickStep * 10 < 1) {
        step = 0.01;
        temp = 100;
      }
      if (tickStep * 100 < 1) {
        step = 0.005;
        temp = 1000;
      }
      if (tickStep * 1000 < 1) {
        step = 0.0005;
        temp = 10000;
      }

      d3.selectAll(`.barblock-${id} .${props.id} g[class*="xAxis"]`).remove();
      d3.selectAll(`.barblock-${id} .${props.id} g[class*="yAxis"]`).remove();
      d3.selectAll(`.barblock-${id} .${props.id} .chart:first-child`)
        .append("g")
        .call(xAxis)
        .attr(
          "class",
          options.angleLabel === true ? `xAxisAngle${index}` : `xAxis${index}`
        )
        .attr("text-anchor", options.angleLabel === true ? "end" : "middle");

      if (options.wrapLabel === true) {
        d3.selectAll(`.${props.id} g[class*=xAxis] .tick text`).call(wrap, 40);
      }

      const getGranulationSuffix = (value = null) => {
        const g = value ? getGranulation(value) : granulation;
        switch (g) {
          // Billion
          case 1000000000:
            return "B";
          case 1000000:
            return "M";
          case 1000:
            return "K";
          default:
            return "";
        }
      };
      d3.selectAll(`.barblock-${id} .${props.id} .chart:first-child`)
        .append("g")
        .call(
          d3
            .axisLeft(y)
            //.ticks(5)
            .tickValues(d3.range(0, maxBarValue / granulation + step, step))
            .tickFormat(
              (d) =>
                `${
                  d >= 1000
                    ? d / 1000
                    : maxBarValue < 1 && temp !== 1
                    ? `${Math.round(d * temp) / temp}`
                    : Math.round(d * 10) / 10
                }${getGranulationSuffix(maxBarValue)} ${metric[0]}`
            )
        )
        .attr("class", "yAxis");

      // Make odd x-axis values have lower position
      if (oddXAxesValuesLowerPosition) {
        d3.selectAll(
          `.barblock-${id} .${props.id} .xAxis${index} g[class*="tick"] text`
        ).style("transform", (d, index) =>
          index % 2 !== 0 ? "translateY(18px)" : ""
        );
      }

      if (checkData.length > 1) {
        const maxValue = getMaxValue(checkData[1]);
        const granulationValue = getGranulation(maxValue);
        const ta = 5;
        const ts = maxValue / granulationValue / ta;
        let s =
          Math.ceil(ts / 5) > 1
            ? Math.ceil(ts / 5) * 5
            : maxValue < 1
            ? Math.ceil((ts / 5) * 10) / 10
            : (Math.ceil((ts / 5) * 10) / 10) * 5;
        let t = 1;
        if (ts * 10 < 1) {
          s = 0.01;
          t = 100;
        }
        if (ts * 100 < 1) {
          s = 0.005;
          t = 1000;
        }
        if (ts * 1000 < 1) {
          s = 0.0005;
          t = 10000;
        }

        d3.selectAll(`.barblock-${id} .${props.id} .chart:first-child`)
          .append("g")
          .call(
            d3
              .axisLeft(y2)
              //.ticks(5)
              .tickValues(d3.range(0, maxValue / granulationValue + s, s))
              .tickFormat(
                (d) =>
                  `${
                    d >= 1000
                      ? d / 1000
                      : maxValue < 1 && t !== 1
                      ? `${Math.round(d * t) / t}`
                      : Math.round(d * 10) / 10
                  }${getGranulationSuffix(maxValue)} ${metric[1]}`
              )
          )
          .attr("class", "yAxis")
          .style("transform", `translate(${width}px, 0px)`);
      }
    }
    if (options.yLines === true && checkData.length < 2) {
      d3.selectAll(
        `.barblock-${id} .${props.id} .chart:first-child .grid-lines`
      ).remove();
      const ticksAmount = 5;
      const tickStep = maxBarValue / granulation / ticksAmount;
      const step = Math.ceil(tickStep / 5) * 5;

      d3.selectAll(`.barblock-${id} .${props.id} .chart:first-child`)
        .append("g")
        .lower()
        .attr("class", "grid-lines")
        .attr("transform", `translate(${0}, ${0})`)
        .call(
          makeYLines()
            .tickSize(-width + margin.right + margin.left, 0, 0)
            .tickFormat("")
            //.ticks(6)
            .tickValues(d3.range(0, maxBarValue / granulation + step, step))
        );
    }
    if (options.xLines === true && check === 0) {
      d3.selectAll(`.barblock-${id} .${props.id} .chart:first-child`)
        .append("g")
        .attr("class", "grid-lines")
        .attr("transform", `translate(${-margin.left}, ${height - margin.top})`)
        .call(
          makeXLines()
            .tickSize(-height + margin.top + margin.bottom, 0, 0)
            .tickFormat("")
        );
    }

    if (check === 0) {
      d3.selectAll(`.barblock-${id} .${props.id} .chart .bar`).remove();
      for (let ix = 0; ix < max; ix++) {
        let set = checkData[ix] ? checkData[ix] : checkData["0"];
        let total = 0;
        // alert(set);

        set.value.forEach((element) => {
          total = total + element.value;
        });

        set.value.forEach((element) => {
          d3.selectAll(`.barblock-${id} .${props.id} .chart-${ix}`)
            .append("g")
            .attr("class", ` bar bar-${ix}`);
        });

        d3.selectAll(`.barblock-${id} .${props.id} .chart-${ix} .bar`)
          .data(set.value)
          .append("rect")
          .attr("class", `rect rect-${ix}`)
          .attr("fill", (d) => d.barColor)
          //.attr('opacity', (d,ind) => 1 - ((allData.length - 1 - ix) * 0.15))
          .attr("opacity", 0.65)
          .attr("width", Math.min(x.bandwidth(), 50))
          .attr("x", (d, ind) => x(ind) - margin.left)
          .attr("y", y(0))
          .attr("height", 0)
          .attr("stroke-width", 1)
          .attr("stroke", (d) => adjustColor(d.barColor, -50));

        d3.selectAll(`.barblock-${id} .${props.id} .chart-${ix} .bar`)
          .data(set.value)
          .append("text")
          .attr("class", `value value-${ix}`)
          .attr(
            "x",
            (d, ind) => x(ind) - margin.left + max * (x.bandwidth() / 1.9)
          )
          .attr(
            "dy",
            (d) =>
              y(((d.value / total) * 100).toFixed(2)) +
              (props.height - y(((d.value / total) * 100).toFixed(2))) / 3
          )
          .attr("fill", "transparent")
          .style("font-size", "0px")
          .style("font-weight", "bold")
          .transition()
          .duration(6000 + index * 300)
          .attr("text-anchor", "middle")
          .attr("fill", "transparent")
          .text((d) => ((d.value / total) * 100).toFixed(2) + "%");

        if (
          barChart.indexOf(set.index) > -1 &&
          typeof checkData[ix] !== "undefined"
        ) {
          d3.selectAll(`.barblock-${id} .${props.id} .chart-${ix} .rect`)
            .transition()
            .duration(600 + index * 300)
            //.attr("height", (d) => y(0) - y(((d.value / total) * 100).toFixed(2)))
            .attr(
              "height",
              (d) =>
                y(0) -
                y(roundToDecimal(d.value > 0 ? d.value / granulation : d.value))
            )
            // .attr("y", (d) => y(((d.value / total) * 100).toFixed(2)));
            .attr("y", (d) =>
              y(roundToDecimal(d.value > 0 ? d.value / granulation : d.value))
            );

          // d3
          //   .selectAll(`.barblock-${id} .${props.id} .chart-${ix} .value`)
          //   .transition()
          //   .duration(600 + index * 300)
          //   .attr("fill", (d) =>
          //       ((d.value / total) * 100).toFixed(2) > 7 && set.index === Math.min(...barChart)
          //         ? d.textColor
          //         : "transparent"
          //     )
          //     .style("font-size", (d) =>
          //     ((d.value / total) * 100).toFixed(2) > 7 && set.index === Math.min(...barChart)
          //       ? `${props.width / 45}px`
          //       : `0px`
          //   )
        }

        d3.selectAll(
          `.barblock-${id} .${props.id} .chart-${ix} .line`
        ).remove();
        d3.selectAll(`.barblock-${id} .${props.id} .chart-${ix} .dot`).remove();

        let line = d3
          .line()
          .x(function (d, ind) {
            return x(ind) - margin.left + 4;
          })
          .y(function (d) {
            return (
              -height +
              (y2
                ? y2(
                    roundToDecimal(
                      d.value > 0 ? d.value / granulation : d.value
                    )
                  )
                : 0)
            );
          });

        d3.selectAll(`.barblock-${id} .${props.id} .chart-${ix}`)
          .data([set.value])
          .append("path")
          .attr("class", `line line-${ix}`) // Assign a class for styling
          .attr("d", (d) => line(d))
          .attr("fill", "none")
          .attr(
            "stroke",
            isLine.indexOf(set.index) > -1 && checkData[ix]
              ? set.value[0].barColor
              : "transparent"
          )
          .attr("stroke-width", "4px")
          .style("transform", `translate(${width}px, ${height}px)`);

        set.value.forEach((element, ind) => {
          d3.selectAll(`.barblock-${id} .${props.id} .chart-${ix}`)
            .data(set.value)
            .append("circle")
            .attr("class", (d, i) => `dot-${ind}`)
            .attr("r", isLine.indexOf(set.index) > -1 && checkData[ix] ? 5 : 0)
            .attr(
              "cx",
              x(ind) - margin.left + 4 + Math.min(x.bandwidth(), 50) / 2
            )
            .attr(
              "cy",
              -(set.value[ind].value / getGranulation(getMaxValue(set))) *
                (height /
                  1.36 /
                  (getMaxValue(set) / getGranulation(getMaxValue(set)))) -
                margin.bottom +
                height
            )
            .attr(
              "fill",
              isLine.indexOf(set.index) > -1 && checkData[ix]
                ? set.value[ind].barColor
                : "transparent"
            )
            .attr("fill", "transparent")
            .style("font-size", "0px")
            .style("font-weight", "bold")
            .transition()
            .duration(6000 + index * 300)
            .attr("text-anchor", "middle")
            .attr("fill", "transparent")
            .text((d) => d.value);

          if (
            barChart.indexOf(set.index) > -1 &&
            typeof checkData[ix] !== "undefined"
          ) {
            d3.selectAll(`.barblock-${id} .${props.id} .chart-${ix} .rect`)
              .transition()
              .duration(600 + index * 300)
              //.attr("height", (d) => y(0) - y(((d.value / total) * 100).toFixed(2)))
              .attr(
                "height",
                (d) =>
                  y(0) -
                  y(
                    roundToDecimal(
                      d.value > 0 ? d.value / granulation : d.value
                    )
                  )
              )
              // .attr("y", (d) => y(((d.value / total) * 100).toFixed(2)));
              .attr("y", (d) =>
                y(roundToDecimal(d.value > 0 ? d.value / granulation : d.value))
              );

            // d3
            //   .selectAll(`.barblock-${id} .${props.id} .chart-${ix} .value`)
            //   .transition()
            //   .duration(600 + index * 300)
            //   .attr("fill", (d) =>
            //       ((d.value / total) * 100).toFixed(2) > 7 && set.index === Math.min(...barChart)
            //         ? d.textColor
            //         : "transparent"
            //     )
            //     .style("font-size", (d) =>
            //     ((d.value / total) * 100).toFixed(2) > 7 && set.index === Math.min(...barChart)
            //       ? `${props.width / 45}px`
            //       : `0px`
            //   )
          }
          d3.selectAll(
            `.barblock-${id} .${props.id} .chart-${ix} .line`
          ).remove();
          d3.selectAll(
            `.barblock-${id} .${props.id} .chart-${ix} .dot`
          ).remove();
          var line = d3
            .line()
            .x(function (d, ind) {
              return (
                x(ind) -
                margin.left +
                max * (x.bandwidth() / (x.bandwidth() / 10))
              );
            })
            .y(function (d) {
              return (
                -height +
                (y2
                  ? y2(
                      roundToDecimal(
                        d.value > 0
                          ? d.value / getGranulation(getMaxValue(checkData[1]))
                          : d.value
                      )
                    )
                  : 0)
              );
            });

          d3.selectAll(`.barblock-${id} .${props.id} .chart-${ix}`)
            .data([set.value])
            .append("path")
            .attr("class", `line line-${ix}`) // Assign a class for styling
            .attr("d", (d) => line(d))
            .attr("fill", "none")
            .attr(
              "stroke",
              isLine.indexOf(set.index) > -1 && checkData[ix]
                ? set.value[0].barColor
                : "transparent"
            )
            .attr("stroke-width", "4px")
            .style("transform", `translate(${0}px, ${height}px)`);
        });
      }
    } else {
      const previousDataLength = d3
        .selectAll(`.barblock-${id} .${props.id} .chart-${0} .rect`)
        .data().length;

      const nextDataLength = checkData[0].value.length;

      // Remove bars if number of previous state bars is greater then number of next state bars
      if (previousDataLength > nextDataLength) {
        const unnecessaryRects = d3
          .selectAll(`.barblock-${id} .${props.id} .chart-${0} .rect`)
          .filter((bar, index) => index >= nextDataLength);

        unnecessaryRects.remove();
        // Add bars if number of previous state bars is smaller than number of next state bars
      } else if (previousDataLength < nextDataLength) {
        for (let ix = previousDataLength; ix < nextDataLength; ix++) {
          d3.select(`.chart.chart-${0}`)
            .append("g")
            .attr("class", ` bar bar-${0}`)
            .append("rect")
            .attr("x", (d, ind) => x(ix) - margin.left)
            .attr("class", `rect rect-${0}`)
            .data(checkData[0].value[ix]);
        }
      }

      // Remove lines if number of previous state bars is greater then number of next state bars
      if (previousDataLength > nextDataLength) {
        d3.selectAll(`.barblock-${id} .${props.id} .line-${0}`)
          .filter((bar, index) => index >= checkData[0].value.length)
          .remove();
      }

      for (let ie = 0; ie < max; ie++) {
        let set = checkData[ie] ? checkData[ie] : checkData["0"];
        let total = 0;

        set.value.forEach((element) => {
          total = total + element.value;
        });

        if (
          barChart.indexOf(set.index) > -1 &&
          typeof checkData[ie] !== "undefined"
        ) {
          d3.selectAll(`.barblock-${id} .${props.id} .chart-${ie} .rect`)
            .data(set.value)
            .transition()
            //.attr('opacity', (d,ind) => 1 - ((allData.length - 1 - ie) * 0.15))
            .attr("opacity", 0.65)
            .duration(600 + index * 300)
            // .attr("height", (d) => y(0) - y(((d.value / total) * 100).toFixed(2)))
            // .attr("y", (d) => y(((d.value / total) * 100).toFixed(2)))
            .attr(
              "height",
              (d) =>
                y(0) -
                y(roundToDecimal(d.value > 0 ? d.value / granulation : d.value))
            )
            // .attr("y", (d) => y(((d.value / total) * 100).toFixed(2)));
            .attr("x", (d, ind) => x(ind) - margin.left)
            .attr("y", (d) =>
              y(roundToDecimal(d.value > 0 ? d.value / granulation : d.value))
            )
            .attr("fill", (d) => d.barColor)
            .attr("width", Math.min(x.bandwidth(), 50))
            .attr("stroke-width", 1)
            .attr("stroke", (d) => adjustColor(d.barColor, -30));

          d3.selectAll(`.barblock-${id} .${props.id} .chart-${ie} .value`)
            .data(set.value)
            // .style("font-size", (d) =>
            //     ((d.value / total) * 100).toFixed(2) > 7 && set.index === Math.min(...barChart)
            //       ? `${props.width / 45}px`
            //       : `0px`
            //   )
            .style("font-size", "0px")
            .style("font-weight", "bold")
            .attr("text-anchor", "middle")
            .transition()
            .duration(600 + index * 300)
            .attr(
              "dy",
              (d) =>
                d.value &&
                y(((d.value / total) * 100).toFixed(2)) +
                  (props.height - y(((d.value / total) * 100).toFixed(2))) / 3
            )
            .attr("fill", (d) =>
              ((d.value / total) * 100).toFixed(2) > 7 &&
              set.index === Math.min(...barChart)
                ? d.textColor
                : "transparent"
            )
            .text((d) => ((d.value / total) * 100).toFixed(2) + "%");
        } else {
          d3.selectAll(`.barblock-${id} .${props.id} .chart-${ie} .rect`)
            .transition()
            .duration(600 + index * 300)
            .attr("height", 0)
            .attr("y", y(0));

          d3.selectAll(`.barblock-${id} .${props.id} .chart-${ie} .value`)
            .data(set.value)
            .transition()
            .duration(600 + index * 300)
            .attr("fill", "transparent")
            .attr("font-size", "0px");
        }

        // Line

        // Create a update selection: bind to the new data
        let u = d3
          .selectAll(`.barblock-${id} .${props.id} .line-${ie}`)
          .data([set.value], function (d) {
            return d.value;
          });

        // There is an error here with adding attribute d and length of values
        // is shorter than initial length
        // Now x(ind) value is checked and previous ind is used

        // Update the line
        if (checkData.length > 1) {
          u.enter()
            .append("path")
            .attr("class", `.line-${i}`)
            .merge(u)
            .transition()
            .duration(300)
            .attr(
              "d",
              d3
                .line()
                .x(function (d, ind) {
                  if (ind < checkData[0].value.length && x(ind)) {
                    return (
                      x(ind) -
                      margin.left +
                      max * (x.bandwidth() / (x.bandwidth() / 10))
                    );
                  }
                  //return 0;
                })
                .y(function (d) {
                  return (
                    -height +
                    y2(
                      roundToDecimal(
                        d.value > 0
                          ? d.value / getGranulation(getMaxValue(checkData[1]))
                          : d.value
                      )
                    )
                  );
                })
            )
            .attr("fill", "none")
            .attr(
              "stroke",
              isLine.indexOf(set.index) > -1 && checkData[ie]
                ? set.value[0].barColor
                : "transparent"
            )
            .attr("stroke-width", "4px");
        }
        // d3.selectAll(`.barblock-${id} .${props.id} .chart-${ie} circle`)
        //   .data(set.value)
        //   .attr("r", isLine.indexOf(set.index) > -1 && allData[ie] ? 5 : 0)
        //   .attr("text", (d) => d.value.toLocaleString())
        //   .transition()
        //   .duration(300)
        //   .attr("cy", (d) => {
        //     //return -(d.value / getGranulation(getMaxValue(set))) - margin.bottom + height
        //     return (
        //       -(d.value / getGranulation(getMaxValue(set))) *
        //         (height /
        //           1.36 /
        //           (getMaxValue(set) / getGranulation(getMaxValue(set)))) -
        //       margin.bottom +
        //       height
        //     );
        //   })
        //   .attr("fill", (d, ind) =>
        //     isLine.indexOf(set.index) > -1 && allData[ie]
        //       ? set.value[ind].barColor
        //       : "transparent"
        //   );
      }
    }

    /**
     * Add animation to increase or decrease opacity of Bars, on mouseenter.
     */
    d3.selectAll(`.barblock-${id} .${props.id} .chart .rect, circle, .line`)
      .on("mouseenter", function () {
        d3.select(this).attr("opacity", 0.55);
        mouseover();
      })
      .on("mouseleave", function () {
        d3.select(this).attr("opacity", 0.65);
        mouseout();
      })
      .on("mousemove", mousemove);
    if (check === 0) {
      d3.select(`.${props.id}`)
        .append("a")
        .attr("class", "tooltip")
        .style("display", "none");
    }
    function mouseover() {
      d3.select(`.barblock-${id} .${props.id} .tooltip`).style(
        "display",
        "inline"
      );
      // div.style("display", "inline");
    }
    function mousemove(event) {
      if (d3.select(this).attr("fill") !== "transparent") {
        var tooltipData = d3.select(this).data()[0]?.value;
        if (
          (index === 0 && numberOfBarGroups === 2) ||
          d3.select(this).attr("class")?.includes("dot")
        ) {
          tooltipData = d3.select(this)?.data()[0].value;
        }
        if (d3.select(this).attr("class")?.includes("dot")) {
          tooltipData = d3.select(this)?.attr("text");
        }
        if (d3.select(this).attr("class")?.includes("line")) {
          const mv = getMaxValue(checkData[1]);
          const gv = getGranulation(mv);
          const d = mv > 1 ? Math.ceil((mv * 1.2) / gv) : mv;
          const scaleFactor = ((height - margin.bottom - margin.top)/d) - 0.295;
          tooltipData = -(d3.pointer(event)[1]+margin.bottom)/scaleFactor;
        }
        d3.select(`.barblock-${id} .${props.id} .tooltip`)
          .html(formatTooltipValue(parseFloat(tooltipData).toFixed(2)))
          .style("left", event.pageX - 24 + "px")
          .style("top", event.pageY - 28 + "px")
          .style("font-size", "16px");
      }
    }
    
    function mouseout() {

      		//Create groups n
		var groups = svg.selectAll("g")
    .data(checkData)
    .enter()
    .append("g")
    .attr("class", "bar")
    .attr("transform", function(d, i) {
        return "translate(" + x(i) + ",0)";
    })
    .on("mouseover", function() {
      d3.select(this)
        .classed("highlight", true);
    })
    .on("mouseout", function() {
      d3.select(this)
        .classed("highlight", false);
    });
    
  //alert(checkData);//testing
      // alert( d3.select(`.barblock-${id} .${props.id} .tooltip`).value); //Testing

      d3.select(`.barblock-${id} .${props.id} .tooltip`).style(
        "display",
        "none"
      );
     
    }
  }

  return (
    <>
      <h3 className="chart-title">{props.title}</h3>
      <div className={props.id}>
        <svg />
      </div>
    </>
  );
};
export default BarChart;
