import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { months } from "../../data/dataOptions";
import * as systemActions from "../../actions/systemActions";
import DataFilter from "../../components/DataFilter";
import TableBlock from "../../components/TableBlock";
import { ReactComponent as ArrowDown } from "./../../assets/icons/arrow-down.svg";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../../constants/colors";

const Competitors1 = () => {
  // State vars populated over redux.
  const selectCompany = (state) => state.company;
  const [animate, setAnimate] = useState(-1);
  const { data } = useSelector(selectCompany);

  const dispatch = useDispatch();

  useEffect(() => {
    setAnimate(animate => animate + 1);
  }, [data]);

  const page = "competitors1";

  const selectSystem = (state) => state.system;
  const { spinner } = useSelector(selectSystem);

  const charts = [
    {
      title: `Competitors KPIs Yearly`,
      id: "asd",
      sheet: 1,
      chartType: "table",
      //dataToPlot: "contacts_per_channel",
      endpoint: "c1",
    },
    {
      title: `Competitors KPIs Monthly`,
      id: "once",
      sheet: 2,
      chartType: "table",
      //dataToPlot: "contacts_per_channel",
      endpoint: "c1",
    },
  ];

  const numberOfDecimals = 2;

  const tableHeadings = [
    { name: "Year", format: 'year' },
    { name: "Competitor", key: 'competitors_id:name' },
    { name: "B-Kost (T€)", bar: "rgba(0, 208, 59, 1)", key: 'b_kost', numberOfDecimals },
    { name: "CPG (€)", bar: "rgba(153, 236, 177, 1)", key: 'cpg', numberOfDecimals },
    { name: "GRP (%)", bar: "rgba(153, 236, 177, 0.65)", key: 'grp', numberOfDecimals },
    { name: "KTS (Mio.)", bar: "rgba(129, 202, 224, 1)", key: 'kts_mio', numberOfDecimals },
    { name: "NRW (Mio.)", bar: "rgba(168, 218, 234, 1)", key: 'nrw_mio', numberOfDecimals },
    { name: "TKP (€)", bar: "rgba(240, 140, 107, 1)", key: 'tkp', numberOfDecimals },
    { name: "OTS", bar: "rgba(210, 190, 220, 1)", key: 'ots', numberOfDecimals }
  ];

  const tableHeadings2 = [
    { name: "Month", format: 'year' },
    { name: "Competitor", key: 'competitors_id:name' },
    { name: "B-Kost (T€)", bar: "rgba(0, 208, 59, 1)", key: 'b_kost', numberOfDecimals },
    { name: "CPG (€)", bar: "rgba(153, 236, 177, 1)", key: 'cpg', numberOfDecimals },
    { name: "GRP (%)", bar: "rgba(153, 236, 177, 0.65)", key: 'grp', numberOfDecimals },
    { name: "KTS (Mio.)", bar: "rgba(129, 202, 224, 1)", key: 'kts_mio', numberOfDecimals },
    { name: "NRW (Mio.)", bar: "rgba(168, 218, 234, 1)", key: 'nrw_mio', numberOfDecimals },
    { name: "TKP (€)", bar: "rgba(240, 140, 107, 1)", key: 'tkp', numberOfDecimals },
    { name: "OTS", bar: "rgba(210, 190, 220, 1)", key: 'ots', numberOfDecimals }
  ];

  let options = [];
  if (data[page]) {
    if (data[page][0]) {
      let index = 1;
      data[page][0].map((item) => {
        if (options.filter(option => option.label === item.competitors_id.name).length === 0) {
          return options.push({ value: index++, label: item.competitors_id.name });
        }
        return undefined;
      });
      options.unshift({ value: 0, label: 'Select...' });
    }
  }

  const initialStateOfFilters = [];
  initialStateOfFilters["0"] = "undefined";
  initialStateOfFilters["1"] = "undefined";

  const [selectedDates, setSelectedDates] = useState(initialStateOfFilters);
  const [selectedFilters, setSelectedFilters] = useState(initialStateOfFilters);


  const printToPdf = async () => {
    function getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
    function getHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      );
    }
    const params = {
      pathname: "atl.tv.competitors-1",
      selectedDates: selectedDates,
      selectedFilters: selectedFilters,
      width: getWidth(),
      height: getHeight(),
    };
    dispatch(systemActions.printToPdf(params));
  };


  const filterByMonth = (data, date) => {
    // let filterYear = new Date(date).getFullYear();
    // let filterMonth = new Date(date).getMonth();

    let dataArr = [];
    if (data[page]) {
      if (data[page][1].length > 0) {
        let temp = data[page][1];
        // dataArr = temp.filter(item => {
        //   return new Date(item.timestamp * 1000).getFullYear() === filterYear &&
        //     new Date(item.timestamp * 1000).getMonth() - 1 === filterMonth;
        // });
        dataArr = temp;
      }
    }
    return { [page]: [dataArr] };
  };

  return (
    <div className="App atl competitors-1">
      <div className='page-content-container'>
        <div className="title-container">
          <h2 className="title overview-title">Competitors 1</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={printToPdf}
              className="btn btn-white download-as-pdf"
            >
              <>
                PDF &nbsp;
                {spinner ? (
                  <Spinner
                    radius={12}
                    color={TABLE_TEXT_COLOR}
                    stroke={2}
                    visible={true}
                  />
                ) : (
                    <ArrowDown />
                  )}
              </>
            </button>
          </div>
        </div>

        <DataFilter charts={charts} page={page} classSuffix="1" onChange={data => {
          const updatedDates = [...selectedDates];
          updatedDates["0"] = data;
          setSelectedDates(updatedDates);
        }}
          className="competitors-date-picker">
          {(filteredData, date) => (
            <DataFilter data={filteredData} page={page} options={options} onChange={data => {
              const updatedFilters = [...selectedFilters];
              updatedFilters["0"] = data.value;
              setSelectedFilters(updatedFilters);
            }}
              className="data-filter data-filter-competitors">
              {(data) => (
                <div className='charts-wrapper'>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="top-charts-container"
                  >


                    <div className="chart-container table" style={{ borderRadius: '10px' }}>
                      <TableBlock
                        headings={tableHeadings}
                        title={charts[0].title}
                        key={0}
                        id={charts[0].id}
                        index={1}
                        chartType={charts[0].chartType}
                        //height={(containerWidth / 2) * 0.39}
                        width={"100%"}
                        sheet={charts[0].sheet}
                        //dataToPlot={"contacts_per_channel"}
                        data={filteredData}
                        date={new Date(date).getFullYear().toString()}
                        animate={animate}
                        colors={charts[0].color}
                        page={page}
                        dataToPlot={data && data.value !== 0 ? { element: 'competitors_id:name', value: data } : null}
                        sortBy={'b_kost'}
                      />
                    </div>


                    <div className="chart-container table" style={{ borderRadius: '10px' }}>
                      <TableBlock
                        headings={tableHeadings2}
                        title={charts[1].title}
                        key={1}
                        id={charts[1].id}
                        index={1}
                        chartType={charts[1].chartType}
                        //height={(containerWidth / 2) * 0.39}
                        width={"100%"}
                        sheet={charts[1].sheet}
                        //dataToPlot={"contacts_per_channel"}
                        data={filterByMonth(filteredData, date)}
                        date={months[new Date(date).getMonth()]}
                        animate={animate}
                        color={charts[1].color}
                        page={page}
                        dataToPlot={data && data.value !== 0 ? { element: 'competitors_id:name', value: data } : null}
                        sortBy={'b_kost'}
                      />
                    </div>
                  </div>
                </div>
              )}
            </DataFilter>
          )}
        </DataFilter>
      </div>
    </div >
  );
};

export default Competitors1;
