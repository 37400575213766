import React, { useEffect } from "react";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as userActions from "../actions/userActions";
import { IPPProps } from "../interfaces";
import Form from "../components/Form";
// https://github.com/react-d3-library/react-d3-library - should we end up choosing the direction of importing existing charts
import { ReactComponent as LeftArrow } from "./../assets/icons/left-arrow.svg";

const OVERVIEW_URL = "/overview";

/**
 * A company profile page.
 *
 * The page displays all the thins of interest to the clients.
 * TODO: Name which things.
 *
 * @param props - For now just the user object
 */
const ProfilePage = (props: IPPProps) => {
  const history = useHistory();
  const { user } = props;
  const selectCompany = (state: any) => state.company;
  const { company } = useSelector(selectCompany);

  // State vars populated over redux.
  const dispatch = useDispatch();

  /**
   * An "Are you sure" prompt.
   *
   * @returns True or false.
   */
  const promptToChangePass = () => {
    if (
      !window.confirm(
        "You are logging in for the first time. It would be a good " +
          "idea to change your password. Proceed to change password page?"
      )
    ) {
      return false;
    }
    return true;
  };

  const redirectToOverviewPage = () => {
    history.push(OVERVIEW_URL);
  };

  const formTitle = {
    fieldName: "Email name",
    fieldValue: company?.name,
  };

  const formElements = [
    {
      label: "Contact information",
      type: "email",
      name: "contact",
      options: {
        placeholder: "Type contact information",
        value: user.email,
      },
    },
    /*
    {
      label: "Password",
      type: "password",
      name: "password",
      options: { placeholder: "Type password", value: "11111111111" },
    },
    */
    {
      label: "Company address",
      type: "text",
      name: "company-address",
      options: {
        placeholder: "Type company address",
        value: company?.address,
      },
    },
  ];
  /**
   * Check if this is the user's first time being logged in, and gets a list of companies.
   */
  useEffect(() => {
    if (user.firstLogin == null || user.firstLogin) {
      if (promptToChangePass()) {
        history.push("/password");
      }
      user.firstLogin = false;
      dispatch(userActions.firstLoginSucceeded(user));
    }
  }, [dispatch, user, company, history]);

  return (
    <div className="App">
      <div className="ProfilePage">
        {/*
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>ProfilePage</h1>
        </div>
        */}

        {/*<h2>Hello, {user.username}</h2>*/}
        {/*<div className="logo">
          <img
            src={`${STRAPI_URL}${company.logo?.formats.small.url}`}
            alt="Company logo"
          />
      </div>*/}
        {/*<div className="body">dads</div>*/}
        <h3 className="back-to-overview-link" onClick={redirectToOverviewPage}>
          <LeftArrow /> Back to overview
        </h3>
        <Form elements={formElements} title={formTitle} />
      </div>
    </div>
  );
};

export default ProfilePage;
