import React from "react";
import Article from "../components/Article";
import { Link } from "react-router-dom";
import Form from "../components/Form";

/**
 * Page Component.
 */
const Page = () => {
  const teaserText = (
    <article>
      Vielen Dank fur Ihr Interesse an unserer Website. Der Schutz Ihrer
      personenbezogenen Daten ist uns ein sehr wichtiges Anliegen. Im Folgenden
      finden Sie Informationen zum Umgang mit Ihren{" "}
      <Link to="/profile">Text Link</Link>, die durch Ihre Nutzgung unserer
      Website erfasst werden. Die Verrabeitung Ihrer Daten erfolgt entsprechend
      den gesetzlichen Regelungen zum Datenshutz.;
    </article>
  );

  const title = {
    fieldName: "Email name",
    fieldValue: "Example Company Technologies LLC.",
  };
  const elements = [
    {
      label: "Email",
      type: "email",
      name: "email",
      options: {
        placeholder: "Type email address",
        value: "example@example.com",
      },
    },
    {
      label: "Password",
      type: "password",
      name: "password",
      options: { placeholder: "Type password", value: "11111111111" },
    },
    {
      label: "Confirm password",
      type: "password",
      name: "confirm-password",
      options: {
        placeholder: "Type password once again",
        value: "11111111111",
      },
    },
    {
      label: "Company address",
      type: "text",
      name: "company-address",
      options: {
        placeholder: "Type company address",
        value: "14 James St, Covent Garden, London WC23 8BU, United Kingdom",
      },
    },
  ];

  return (
    <>
      <Form elements={elements} title={title} />
      <div className="page">
        <Article teaser={teaserText}>
          <h4>H4 - Body text header</h4>
          {teaserText}
          <h4>H4 - Body text header</h4>
          {teaserText}
          <h4>H4 - Body text header</h4>
          {teaserText}
        </Article>
      </div>
    </>
  );
};

export default Page;
