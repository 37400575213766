import React, { useEffect, useState } from "react";
import "./../App.css";
import { useSelector, useDispatch } from "react-redux";
import * as systemActions from "../actions/systemActions";
import { ReactComponent as ArrowDown } from "./../assets/icons/arrow-down.svg";
import DataFilter from "../components/DataFilter";
import BarBlock from "../components/BarBlock";
import DataBlock from "../components/DataBlock";
import { IPDFParams } from "../interfaces";
import { TABLE_TEXT_COLOR } from "../constants/colors";
import Spinner from "react-spinner-material";

/**
 * A company profile page.
 *
 * The page displays all the thins of interest to the clients.
 * TODO: Name which things.
 *
 * @param props - For now just the user object
 */
const Overview = () => {
  // State vars populated over redux.
  const selectCompany = (state: any) => state.company;
  const { data } = useSelector(selectCompany);
  const dispatch = useDispatch();
  const [init, setInit] = useState(true);
  const [animate, setAnimate] = useState(-2);
  const [noData, setNoData] = useState(false);
  const page = "overview";

  const selectSystem = (state: any) => state.system;
  const { spinner } = useSelector(selectSystem);

  useEffect(() => {
    if (!init) {
      if (
        Object.keys(data).length > 0 &&
        data[page] &&
        data[page].filter((item: any) => item.length > 0).length > 0
      ) {
        if (noData) {
          setNoData(false);
        } else {
          setAnimate((animate) => animate + 1);
        }
      } else {
        if ((animate: number) => animate !== -2) {
          setAnimate(-2);
          setNoData(true);
        }
      }
    } else {
      if (Object.keys(data).length > 0 && data[page]) {
        setAnimate((animate) => animate + 1);
        setInit(false);
      }
    }
  }, [data, init, noData]);

  const charts = [
    {
      title: "Net Budget per Channel in EURO",
      id: "nb",
      sheet: 1,
      chartType: "bar",
      dataToPlot: "net_budget",
      color: "#E84E1B",
      endpoint: "bo",
    },
    {
      title: "Contacts per Channel",
      id: "cpc",
      sheet: 2,
      chartType: "bar",
      dataToPlot: "contacts_per_channel",
      color: "#3DADD0",
      endpoint: "bo",
    },
    {
      title: "Visits Total",
      id: "vt",
      sheet: 3,
      chartType: "data",
      dataToPlot: null,
      color: "#3DADD0",
      endpoint: "bo",
    },
    {
      title: "NDCs Total",
      id: "nt",
      sheet: 3,
      chartType: "data",
      dataToPlot: null,
      color: "#3DADD0",
      endpoint: "bo",
    },
    {
      title: "VISITS per Channel",
      id: "vpc",
      sheet: 4,
      chartType: "bar",
      dataToPlot: "visits_medium",
      color: "#3DADD0",
      endpoint: "bo",
    },
    {
      title: "NDCs per Channel",
      id: "npc",
      sheet: 4,
      chartType: "bar",
      dataToPlot: "ndcs_medium",
      color: "#3DADD0",
      endpoint: "bo",
    },
  ];

  const initialStateOfFilters = [] as string[];
  initialStateOfFilters["0" as any] = "undefined";
  initialStateOfFilters["1" as any] = "undefined";

  const [selectedDates, setSelectedDates] = useState(initialStateOfFilters);

  const containerWidth = document.getElementsByClassName("container-content")[0]
    ? (document.getElementsByClassName("container-content")[0] as any)
        .offsetWidth
    : 0;

  const printToPdf = async () => {
    function getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
    function getHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      );
    }
    const params: IPDFParams = {
      pathname: "overview",
      selectedDates: selectedDates,
      selectedFilters: [],
      width: getWidth(),
      height: getHeight(),
    };
    dispatch(systemActions.printToPdf(params));
  };

  useEffect(() => {
    const datepicker = document.getElementsByClassName(
      "react-datepicker__input-container"
    )[0];
    document.getElementById("filter-icons")?.append(datepicker);
  }, []);

  const calculateTopChartsWidth = () => {
    if (containerWidth < 425) {
      return containerWidth * 2;
    } else if (containerWidth < 604) {
      return containerWidth * 1.63;
    } else if (containerWidth < 953) {
      return containerWidth / 0.8;
    } else if (containerWidth < 1065) {
      return containerWidth / 1.55;
    } else if (containerWidth < 1150) {
    } else {
      return containerWidth / 1.7;
    }
  };

  const calculateTopChartsHeight = () => {
    if (containerWidth < 953) {
      return containerWidth / 2.3;
    } else if (containerWidth < 1000) {
      return containerWidth / 0.5;
    } else if (containerWidth < 1065) {
      return containerWidth / 4.3;
    } else {
      return containerWidth / 4.76;
    }
  };

  const calculateBottomChartsWidth = () => {
    if (containerWidth < 1000) {
      return containerWidth * 1.2;
    } else if (containerWidth < 920) {
      return containerWidth / 0.8;
    } else if (containerWidth < 1024) {
      return containerWidth / 1.5;
    } else if (containerWidth < 1240) {
      return containerWidth / 1.7;
    } else {
      return containerWidth / 2.46;
    }
  };

  const calculateBottomChartsHeight = () => {
    if (containerWidth < 1000) {
      return containerWidth / 3.4;
    } else if (containerWidth < 953) {
      return containerWidth / 3.3;
    } else if (containerWidth < 1024) {
      return containerWidth / 4.16;
    } else if (containerWidth < 1150) {
      return containerWidth / 4.26;
    } else {
      return containerWidth / 5.16;
    }
  };

  return (
    <div className="App overview">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="title-container">
          <h2 className="title overview-title">ATL Overview</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={printToPdf}
              className="btn btn-white download-as-pdf"
            >
              <>
                PDF &nbsp;
                {spinner ? (
                  <Spinner
                    radius={12}
                    color={TABLE_TEXT_COLOR}
                    stroke={2}
                    visible={true}
                  />
                ) : (
                  <ArrowDown />
                )}
              </>
            </button>
          </div>
        </div>
        <DataFilter
          charts={charts}
          page={page}
          classSuffix="1"
          onChange={(date: any) => {
            setAnimate((animate) => animate + 1);

            const updatedDates = [...selectedDates];
            updatedDates["0" as any] = date;
            setSelectedDates(updatedDates);
          }}
        >
          {(
            filteredData: any,
            date: any,
            isEveryChartContainsData: boolean
          ) => {
            return (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "11px",
                  }}
                  className="top-charts-container"
                >
                  <div className="chart-container top-container">
                    <BarBlock
                      containerId={`BarChart${charts[0].id}`}
                      title={charts[0].title}
                      data={filteredData}
                      key={0}
                      id={charts[0].id}
                      index={0}
                      chartType={charts[0].chartType}
                      height={calculateTopChartsHeight()}
                      width={calculateTopChartsWidth()}
                      dataType={"bo"}
                      sheet={charts[0].sheet}
                      dataToPlot={charts[0].dataToPlot}
                      animate={isEveryChartContainsData ? animate : 0}
                      color={charts[0].color}
                      page={page}
                    />
                  </div>
                  <div className="chart-container top-container">
                    <BarBlock
                      containerId={`BarChart${charts[1].id}`}
                      title={charts[1].title}
                      data={filteredData}
                      key={1}
                      id={charts[1].id}
                      index={2}
                      chartType={charts[1].chartType}
                      height={calculateTopChartsHeight()}
                      width={calculateTopChartsWidth()}
                      dataType={"bo"}
                      sheet={charts[1].sheet}
                      dataToPlot={charts[1].dataToPlot}
                      animate={isEveryChartContainsData ? animate : 0}
                      color={charts[1].color}
                      page={page}
                    />
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  className="bottom-charts-container"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                    className="bottom-charts-total-blocks"
                  >
                    <div className="chart-container small-container">
                      <DataBlock
                        key={`DataChart${charts[2].id}`}
                        id={charts[2].id}
                        data={filteredData}
                        sheet={charts[2].sheet}
                        title={charts[2].title}
                        page={page}
                      />
                    </div>
                    <div className="chart-container small-container">
                      <DataBlock
                        key={`DataChart${charts[3].id}`}
                        id={charts[3].id}
                        data={filteredData}
                        sheet={charts[3].sheet}
                        title={charts[3].title}
                        page={page}
                      />
                    </div>
                  </div>
                  <div className="chart-container bottom-container">
                    <BarBlock
                      containerId={`BarChart${charts[4].id}`}
                      title={charts[4].title}
                      data={filteredData}
                      key={4}
                      id={charts[4].id}
                      index={5}
                      chartType={charts[4].chartType}
                      height={calculateBottomChartsHeight()}
                      width={calculateBottomChartsWidth()}
                      dataType={"bo"}
                      sheet={charts[4].sheet}
                      dataToPlot={charts[4].dataToPlot}
                      animate={isEveryChartContainsData ? animate : 0}
                      color={charts[4].color}
                      page={page}
                    />
                  </div>
                  <div className="chart-container bottom-container">
                    <BarBlock
                      containerId={`BarChart${charts[5].id}`}
                      title={charts[5].title}
                      data={filteredData}
                      key={5}
                      id={charts[5].id}
                      index={6}
                      chartType={charts[5].chartType}
                      height={calculateBottomChartsHeight()}
                      width={calculateBottomChartsWidth()}
                      dataType={"bo"}
                      sheet={charts[5].sheet}
                      dataToPlot={charts[5].dataToPlot}
                      animate={isEveryChartContainsData ? animate : 0}
                      color={charts[5].color}
                      page={page}
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </DataFilter>
      </div>
    </div>
  );
};
export default Overview;
