import { call, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import * as types from "../actions/types";
import * as systemActions from "../actions/systemActions";
import { IError, SystemActionTypes, IPDFParams } from "../interfaces";

const API_URL = process.env.REACT_APP_API_URL;

// const headerDefaults = {
//   Accept: "application/json",
//   'Content-Type': 'application/json'
// };

// Log in system
const printToPdfApi = (params: IPDFParams) => {
  const selectedDatesParam =
    params.selectedDates.length > 0
      ? params.selectedDates.join(";")
      : "undefined";

  const selectedFiltersParam =
    params.selectedFilters.length > 0
      ? params.selectedFilters.join(";")
      : "undefined";

  const url =
    API_URL +
    "/pdf/" +
    params.pathname +
    "/" +
    selectedDatesParam +
    "/" +
    selectedFiltersParam +
    "/" +
    params.width +
    "/" +
    params.height;

  const headers = {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
    },
  };
  return fetch(url, headers).then((response) => {
    if (response.status === 500) {
      throw response.statusText;
    }
    return response.blob();
  });
};

/* Test with
// const gen = fetchsystems();
// test('should hit api', () => {
//   const param = 1;
//   expect(gen.next(param).value).toEqual(call(api, 'http...'))
// }) */
function* printToPdf(action: SystemActionTypes): SagaIterator {
  let response;
  try {
    response = yield call(printToPdfApi, action.payload as IPDFParams);
    if (typeof response.error !== "undefined") {
      throw response;
    }
    //const blob = new Blob([response], {type: 'application/pdf'})
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(response);
    link.download = `cnqr-report.pdf`;
    link.click();

    yield put(systemActions.printToPdfSucceeded());
  } catch (e) {
    const err: IError = e;
    yield put(systemActions.printToPdfFailed(err));
  }
}

export function* printToPdfWatcher(): SagaIterator {
  yield takeLatest(types.system.PRINT_TO_PDF, printToPdf);
}
